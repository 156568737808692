import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "../../App.css"


const PlayerTableRow = props => (
  <tr onClick={() => props.checkPlayerBox()} style={{
    backgroundColor: props.player.currentPosition.includes("BE") ? "lightgrey" : "white"
  }}>
    <td style={{width: "32px"}}>
      <input style={{position: "relative", opacity: "100%"}} type="checkbox" id={props.player.Stub} className={props.sender === "trade" ? "tradeCheckbox" : "userCheckbox"} />
    </td>
    <td>{props.player.Position}</td>
    <td><b>{props.player.Name}</b></td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
  </tr>
)

const MobilePlayerTableRow = props => (
  <tr onClick={() => props.checkPlayerBox()} style={{
    backgroundColor: props.player.currentPosition.includes("BE") ? "lightgrey" : "white"
  }}>
    <td style={{width: "32px"}}>
      <input style={{position: "relative", opacity: "100%"}} type="checkbox" id={props.player.Stub} className={props.sender === "trade" ? "tradeCheckbox" : "userCheckbox"} />
    </td>
    <td><b>{props.player.Name.split(' ')[0][0] + '. ' + props.player.Name.split(' ')[1]} ({props.player.Position})</b></td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
  </tr>
)

const BlankRow = props => (
  <tr style={{
    backgroundColor: "lightgrey"
  }}>
    <td></td>
    <td>{props.currentPosition}</td>
    <td><b>EMPTY</b></td>
    <td></td>
  </tr>
)

const MobileBlankRow = props => (
  <tr style={{
    backgroundColor: "lightgrey"
  }}>
    <td></td>
    <td><b>EMPTY ({props.currentPosition})</b></td>
    <td></td>
  </tr>
)


export default class TradePlayers extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      leagueID: "",
      tradeTeamID: "",
      tradeUserID: "",
      tradeTeamName: "",
      tradePlayerList: [],
      userTeamID: "",
      userTeamName: "",
      userPlayerList: [],
      expectedPositions: []
    };
  }

  componentDidMount() {
    const obj = {
      teamID: window.location.href.split('/').slice(-1)[0]
    };
    this.fetchTeam(obj)
  }

  async fetchTeam(obj) {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${obj.teamID}`)
      .then((response) => this.setState({
        leagueID: response.data.leagueID,
        tradeTeamID: response.data._id,
        tradeUserID: response.data.userID,
        tradeTeamName: response.data.teamName,
        tradePlayerList: response.data.playerList,
        expectedPositions: response.data.expectedPositions
      }))
      .then(() => this.fetchUserTeam(localStorage.userID, this.state.leagueID))
      .catch(err => console.log(err))
  }

  async fetchUserTeam(userId, leagueId) {
    const userObj = {
      userID: userId,
      leagueID: leagueId
    }
    console.log(userObj)
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchUserTeam`, userObj)
      .then((response) => this.setState({
        userTeamID: response.data[0]._id,
        userTeamName: response.data[0].teamName,
        userPlayerList: response.data[0].playerList,
      }))
      .catch(err => console.log(err))
  }

  displayPlayerTableRows(playerList, sender) {
    if (playerList) {
      var newPlayerList = [...playerList]

      if (playerList) {
        var occupiedPositions = []
        var roster = playerList
        roster.map(function(player, k) {
          occupiedPositions.push(player.currentPosition)
        })
        const bench = occupiedPositions.filter(ele => ele.includes('BE'))
        var fullPositionArray = [...this.state.expectedPositions]
        fullPositionArray = fullPositionArray.filter(ele => !ele.includes('BE'))
        if (bench.length !== 0) {
          for (var z = 0; z < bench.length; z++) {
            fullPositionArray.push('BE' + String(z + 1))
          }
        }
        if (!this.state.mobile) {
          return fullPositionArray.map(function(currPos, i) {
            if (occupiedPositions.includes(currPos)) {
              var idx = occupiedPositions.indexOf(currPos)
              return <PlayerTableRow player={roster[idx]} key={i} sender={sender} checkPlayerBox={() => document.getElementById(roster[idx].Stub).checked ? document.getElementById(roster[idx].Stub).checked = false : document.getElementById(roster[idx].Stub).checked = true} />
              return <BlankRow currentPosition={currPos.slice(0, -1)} key={i} />;
            }
          })
        } else {
          return fullPositionArray.map(function(currPos, i) {
            if (occupiedPositions.includes(currPos)) {
              var idx = occupiedPositions.indexOf(currPos)
              return <MobilePlayerTableRow player={roster[idx]} key={i} sender={sender} checkPlayerBox={() => document.getElementById(roster[idx].Stub).checked ? document.getElementById(roster[idx].Stub).checked = false : document.getElementById(roster[idx].Stub).checked = true} />;
            } else {
              return <MobileBlankRow currentPosition={currPos.slice(0, -1)} key={i} />;
            }
          })
        }
      } else {
        if (!this.state.mobile) {
          return this.state.expectedPositions.map(function(currPos, i) {
            return <BlankRow currentPosition={currPos.slice(0, -1)} key={i} />;
          })
        } else {
          return this.state.expectedPositions.map(function(currPos, i) {
            return <MobileBlankRow currentPosition={currPos.slice(0, -1)} key={i} />;
          })
        }
      }
    }
  }

  confirmTrade() {
    var tradeStubs = []
    var tradePlayers = []
    var tradeBoxes = document.getElementsByClassName("tradeCheckbox")
    for (var i = 0; i < tradeBoxes.length; i++) {
       if (tradeBoxes.item(i).checked) {
         tradeStubs.push(tradeBoxes.item(i).id)
       }
    }
    for (var j = 0; j < this.state.tradePlayerList.length; j++) {
      var player = this.state.tradePlayerList[j]
      if (tradeStubs.includes(player.Stub)) {
        tradePlayers.push(player)
      }
    }
    var userStubs = []
    var userPlayers = []
    var userBoxes = document.getElementsByClassName("userCheckbox")
    for (var i = 0; i < userBoxes.length; i++) {
       if (userBoxes.item(i).checked) {
         userStubs.push(userBoxes.item(i).id)
       }
    }
    for (var j = 0; j < this.state.userPlayerList.length; j++) {
      var player = this.state.userPlayerList[j]
      if (userStubs.includes(player.Stub)) {
        userPlayers.push(player)
      }
    }

    if (userStubs.length === tradeStubs.length) {
      const tradeId = Math.random().toString(36).substr(2, 12);
      var userProposal = {
        tradeID: tradeId,
        proposedBy: this.state.userTeamID,
        teamID: this.state.userTeamID,
        partnerID: this.state.tradeTeamID,
        partnerName: this.state.tradeTeamName,
        proposal: {
          give: userPlayers,
          get: tradePlayers
        }
      }
      var recipientProposal = {
        tradeID: tradeId,
        proposedBy: this.state.userTeamID,
        teamID: this.state.tradeTeamID,
        partnerID: this.state.userTeamID,
        partnerName: this.state.userTeamName,
        proposal: {
          give: tradePlayers,
          get: userPlayers
        }
      }
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/addTradeProposal`, userProposal)
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/addTradeProposal`, recipientProposal)
      setTimeout(() => {window.open(`/viewTeam/${this.state.userTeamID}`, '_self')}, 1500)
    } else {
      window.confirm("Only balanced trades (e.g., 1 for 1 or 2 for 2) are currently supported")
    }
  }

  render() {
    if (!this.state.mobile) {
      return (
        <div>
          <div style={{
            marginLeft: "10%",
            marginRight: "10%"
          }}>
            <Button onClick={() => window.open(`/viewTeam/${this.state.teamID}`, '_self')} style={{
              marginLeft: "12px",
              marginTop: "12px",
              marginBottom: "12px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "3px",
              fontSize: "16px"
            }}>
              <i><FontAwesomeIcon icon={faChevronLeft}/></i> BACK
            </Button>
            <div className="playerContainer" style={{
              display: "table",
              width: "100%",
              align: "center"
            }}>
              <div className="playerRow" style={{
                display: "table-row"
              }}>
                <div style={{
                  display: "table-cell",
                  textAlign: "center",
                  padding: "4px",
                }}>
                  <div className="card">
                    <div className="card-content" style={{
                      width: "100%"
                    }}>
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}>
                        <div style={{
                          width: "45%"
                        }}>
                          <div className="card">
                            <div className="card-content">
                              <h5>{this.state.tradeTeamName}</h5>
                            </div>
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Pos</th>
                                <th>Name</th>
                                <th>Avg Pts</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.displayPlayerTableRows(this.state.tradePlayerList, "trade")}
                            </tbody>
                          </table>
                        </div>
                        <div style={{
                          align: "right",
                          width: "45%"
                        }}>
                          <div className="card">
                            <div className="card-content">
                              <h5>{this.state.userTeamName}</h5>
                            </div>
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Pos</th>
                                <th>Name</th>
                                <th>Avg Pts</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.displayPlayerTableRows(this.state.userPlayerList, "user")}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    style={{
                      width: "300px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      backgroundColor: "#0E1C36",
                    }}
                    className="btn btn-large waves-effect hoverable accent-3"
                    onClick={() => this.confirmTrade()}
                  >
                    SUBMIT PROPOSAL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{width: "100%"}}>
          <div>
            <Button onClick={() => window.open(`/viewTeam/${this.state.teamID}`, '_self')} style={{
              marginLeft: "12px",
              marginTop: "12px",
              marginBottom: "12px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "3px",
              fontSize: "16px"
            }}>
              <i><FontAwesomeIcon icon={faChevronLeft}/></i> BACK
            </Button>
            <div>
              <div className="card">
                <div style={{
                  marginLeft: "6px",
                  marginRight: "6px"
                }}>
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}>
                    <div style={{
                      width: "45%"
                    }}>
                      <div className="card">
                        <div style={{
                          margin: "6px",
                          textAlign: "center"
                        }}>
                          <p><b>{this.state.tradeTeamName}</b></p>
                        </div>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name (Pos)</th>
                            <th>Avg Pts</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayPlayerTableRows(this.state.tradePlayerList, "trade")}
                        </tbody>
                      </table>
                    </div>
                    <div style={{
                      align: "right",
                      width: "45%"
                    }}>
                      <div className="card">
                      <div style={{
                        margin: "6px",
                        textAlign: "center"
                      }}>
                        <p><b>{this.state.userTeamName}</b></p>
                      </div>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name (Pos)</th>
                            <th>Avg Pts</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayPlayerTableRows(this.state.userPlayerList, "user")}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="center-align">
                <button
                  style={{
                    width: "300px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    backgroundColor: "#0E1C36",
                  }}
                  className="btn btn-large waves-effect hoverable accent-3"
                  onClick={() => this.confirmTrade()}
                >
                  SUBMIT PROPOSAL
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }



}
