import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

const PlayerRow = props => (
  <tr>
    <td><b>{props.player.Name}</b> ({props.player.Position})</td>
    <td>{props.player.Num_Games}</td>
    <td>{props.player.Total_Pts.toFixed(2)}</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>{props.player.Under_10}</td>
    <td>{props.player.Over_20}</td>
  </tr>
)

const MobilePlayerRow = props => (
  <tr>
    <td><b>{props.player.Name.split(' ')[0][0] + '. ' + props.player.Name.split(' ')[1]}</b> ({props.player.Position})</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
  </tr>
)


export default class TradeReview extends Component {

  constructor() {
    super();
    this.state = {
      mobile: window.innerWidth < 700,
      tradeID: "",
      teamID: "",
      proposedBy: "",
      partnerID: "",
      partnerName: "",
      give: [],
      get: []
    }
  }

  componentDidMount() {
    this.loadTradeDetails()
  }

  async loadTradeDetails() {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${window.location.href.split('/').slice(-1)[0].split(':')[0]}`)
      .then((response) => this.processProposals(response))
      .catch(err => console.log(err))
  }

  processProposals(response) {
    var proposals = response.data.tradeProposals
    for (var i=0; i < proposals.length; i++) {
      var proposal = proposals[i]
      if (proposal.tradeID === window.location.href.split('/').slice(-1)[0].split(':')[1]) {
        this.setState({
          tradeID: proposal.tradeID,
          teamID: proposal.teamID,
          proposedBy: proposal.proposedBy,
          partnerID: proposal.partnerID,
          partnerName: proposal.partnerName,
          give: proposal.proposal.give,
          get: proposal.proposal.get
        })
      }
    }
  }

  displayPlayerStats(playerList) {
    var playerArray = []
    for (var i = 0; i < playerList.length; i++) {
      var player = playerList[i]
      if (!this.state.mobile) {
        playerArray.push(<PlayerRow player={player} key={i} />)
      } else {
        playerArray.push(<MobilePlayerRow player={player} key={i} />)
      }
    }
    return playerArray
  }

  async acceptTrade() {
    var newTeam1 = []
    var tradeProposals1 = []
    var newTeam2 = []
    var tradeProposals2 = []
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${this.state.partnerID}`)
      .then((response) => {
        newTeam1 = [...response.data.playerList]
        var maxBench = 0
        for (var q=0; q < response.data.expectedPositions.length; q++) {
          if (response.data.expectedPositions[q].includes('BE')) {
            maxBench += 1
          }
        }
        tradeProposals1 = [...response.data.tradeProposals]
        for (var j=0; j < this.state.get.length; j++) {
          for (var i=0; i < newTeam1.length; i++) {
            if (newTeam1[i].Stub === this.state.get[j].Stub) {
              newTeam1[i] = this.state.give[j]
              newTeam1[i].currentPosition = "BE" + String(maxBench + j + 1)
            }
          }
        }
        for (var k=0; k < tradeProposals1.length; k++) {
          if (tradeProposals1[k].tradeID === this.state.tradeID) {
            tradeProposals1.splice(k, 1)
          }
        }
      })
    console.log(newTeam1)

    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${this.state.teamID}`)
      .then((response) => {
        newTeam2 = [...response.data.playerList]
        var maxBench = 0
        for (var q=0; q < response.data.expectedPositions.length; q++) {
          if (response.data.expectedPositions[q].includes('BE')) {
            maxBench += 1
          }
        }
        tradeProposals2 = [...response.data.tradeProposals]
        for (var j=0; j < this.state.give.length; j++) {
          for (var i=0; i < newTeam2.length; i++) {
            if (newTeam2[i].Stub === this.state.give[j].Stub) {
              newTeam2[i] = this.state.get[j]
              newTeam2[i].currentPosition = "BE" + String(maxBench + j + 1)
            }
          }
        }
        for (var k=0; k < tradeProposals2.length; k++) {
          if (tradeProposals2[k].tradeID === this.state.tradeID) {
            tradeProposals2.splice(k, 1)
          }
        }
      })
    console.log(newTeam2)

    const team1Obj = {
      playerList: newTeam1,
      tradeProposals: tradeProposals1
    }
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/acceptTrade/${this.state.partnerID}`, team1Obj)
    const team2Obj = {
      playerList: newTeam2,
      tradeProposals: tradeProposals2
    }
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/acceptTrade/${this.state.teamID}`, team2Obj)
    setTimeout(() => {window.open(`/viewTeam/${this.state.teamID}`, '_self')}, 1500)
  }

  async rejectTrade(counter) {
    var tradeProposals1 = []
    var tradeProposals2 = []
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${this.state.partnerID}`)
      .then((response) => {
        tradeProposals1 = [...response.data.tradeProposals]
        for (var k=0; k < tradeProposals1.length; k++) {
          if (tradeProposals1[k].tradeID === this.state.tradeID) {
            tradeProposals1.splice(k, 1)
          }
        }
      })

    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${this.state.teamID}`)
      .then((response) => {
        tradeProposals2 = [...response.data.tradeProposals]
        for (var k=0; k < tradeProposals2.length; k++) {
          if (tradeProposals2[k].tradeID === this.state.tradeID) {
            tradeProposals2.splice(k, 1)
          }
        }
      })

    const team1Obj = {
      tradeProposals: tradeProposals1
    }
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/rejectTrade/${this.state.partnerID}`, team1Obj)
    const team2Obj = {
      tradeProposals: tradeProposals2
    }
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/rejectTrade/${this.state.teamID}`, team2Obj)

    if (counter) {
      setTimeout(() => {window.open(`/tradePlayers/${this.state.partnerID}`, '_self')}, 1500)
    } else {
      setTimeout(() => {window.open(`/viewTeam/${this.state.teamID}`, '_self')}, 1500)
    }
  }

  render() {
    if (!this.state.mobile) {
      return (
        <div>
          <div className="container col s10">
            <Button onClick={() => window.open(`/viewTeam/${this.state.teamID}`, '_self')} style={{
              marginTop: "12px",
              marginBottom: "12px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "3px",
              fontSize: "16px"
            }}>
              <i><FontAwesomeIcon icon={faChevronLeft}/></i> BACK
            </Button>
          </div>
          <div className="container col s10">
            <div className="card">
              <div className="card-content">
                <div style={{
                  width: "100%"
                }}>
                  <h5><b>Players You Give:</b></h5>
                </div>
                <table className="table highlight" style={{ marginTop: 20 }}>
                  <thead>
                    <tr>
                      <th>Name (Position)</th>
                      <th>Num Games</th>
                      <th>Total Pts</th>
                      <th>Avg PPG</th>
                      <th>StDev PPG</th>
                      <th>Under 10 Pts</th>
                      <th>Over 20 Pts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.displayPlayerStats(this.state.give)}
                  </tbody>
                </table>
                <div style={{
                  width: "100%",
                  paddingTop: "12px"
                }}>
                  <h5><b>Players You Get:</b></h5>
                </div>
                <table className="table highlight" style={{ marginTop: 20 }}>
                  <thead>
                    <tr>
                      <th>Name (Position)</th>
                      <th>Num Games</th>
                      <th>Total Pts</th>
                      <th>Avg PPG</th>
                      <th>StDev PPG</th>
                      <th>Under 10 Pts</th>
                      <th>Over 20 Pts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.displayPlayerStats(this.state.get)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "space-evenly"
          }}>
            <button
              id="rejectButton"
              onClick={() => this.rejectTrade()}
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1px",
                backgroundColor: "#0E1C36",
                display: this.state.proposedBy !== this.state.teamID ? "block" : "none"
              }}
              className="btn btn-large waves-effect hoverable accent-3"
            >
              REJECT
            </button>
            <button
              id="counterButton"
              onClick={ () => {this.state.proposedBy !== this.state.teamID ? this.rejectTrade(true) : this.rejectTrade(false)} }
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1px",
                backgroundColor: "#0E1C36",
              }}
              className="btn btn-large waves-effect hoverable accent-3"
            >
              {this.state.proposedBy !== this.state.teamID ? "COUNTER" : "CANCEL"}
            </button>
            <button
              id="acceptButton"
              onClick={() => this.acceptTrade()}
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                backgroundColor: "#0E1C36",
                display: this.state.proposedBy !== this.state.teamID ? "block" : "none"
              }}
              className="btn btn-large waves-effect hoverable accent-3"
            >
            ACCEPT
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="draftBoardCell" style={{
          marginLeft: "5%",
          marginRight: "5%",
          padding: "4px"
        }}>
          <Button onClick={() => window.open(`/viewTeam/${this.state.teamID}`, '_self')} style={{
            marginTop: "12px",
            marginBottom: "12px",
            backgroundColor: "white",
            color: "black",
            borderRadius: "3px",
            fontSize: "16px"
          }}>
            <i><FontAwesomeIcon icon={faChevronLeft}/></i> Back
          </Button>
          <div className="card">
            <div className="card-content" style={{
              padding: "8px"
            }}>
              <div style={{
                width: "100%"
              }}>
                <h5><b>Players You Give:</b></h5>
              </div>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Name (Position)</th>
                    <th>Avg PPG</th>
                    <th>StDev PPG</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayPlayerStats(this.state.give)}
                </tbody>
              </table>
              <br />
              <div style={{
                width: "100%"
              }}>
                <h5><b>Players You Get:</b></h5>
              </div>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Name (Position)</th>
                    <th>Avg PPG</th>
                    <th>StDev PPG</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayPlayerStats(this.state.get)}
                </tbody>
              </table>
            </div>
          </div>
          <div className="center-align">
            <div>
              <button
                id="rejectButton"
                onClick={() => this.rejectTrade()}
                style={{
                  width: "150px",
                  borderRadius: "3px",
                  letterSpacing: "1px",
                  backgroundColor: "#0E1C36",
                  marginTop: "1rem",
                  display: this.state.proposedBy !== this.state.teamID ? "inline-block" : "none"
                }}
                className="btn btn-large waves-effect hoverable accent-3"
              >
                REJECT
              </button>
            </div>
            <div>
              <button
                id="counterButton"
                onClick={ () => {this.state.proposedBy !== this.state.teamID ? this.rejectTrade(true) : this.rejectTrade(false)} }
                style={{
                  width: "150px",
                  borderRadius: "3px",
                  letterSpacing: "1px",
                  backgroundColor: "#0E1C36",
                  marginTop: "1rem"
                }}
                className="btn btn-large waves-effect hoverable accent-3"
              >
                {this.state.proposedBy !== this.state.teamID ? "COUNTER" : "CANCEL"}
              </button>
            </div>
            <div>
              <button
                id="acceptButton"
                onClick={() => this.acceptTrade()}
                style={{
                  width: "150px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  backgroundColor: "#0E1C36",
                  marginTop: "1rem",
                  display: this.state.proposedBy !== this.state.teamID ? "inline-block" : "none"
                }}
                className="btn btn-large waves-effect hoverable accent-3"
              >
              ACCEPT
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

}
