import React, { Component } from "react";
import axios from 'axios';


class PremiumUpdate extends Component {

  constructor() {
    super();

    this.state = {};
  };

  async componentDidMount() {
    console.log("Updating user's premium status")
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/users/updatePremium/${localStorage.userID}`)
    localStorage.setItem("premium", true)
    window.open('/userProfile/', '_self')
  }


  render() {
    return (
      <div>
        <p>Successfully updated premium status</p>
      </div>
    );
  }

}

export default PremiumUpdate
