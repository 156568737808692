import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const CurrentTeamRow = props => (
  <tr>
    <td onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name}</b> ({props.player.Position})</td>
    <td>{props.player.Num_Games}</td>
    <td>{props.player.Total_Pts.toFixed(2)}</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>{props.player.Under_10}</td>
    <td>{props.player.Over_20}</td>
    <td>
      <Button
        className="add-player-button" style={{
          marginRight: 10,
          backgroundColor: "#0E1C36"
        }}
        onClick={() => props.dropFunc(props)}
      >
        {props.added ? "CANCEL" : "DROP"}
      </Button>
    </td>
  </tr>
)

const MobileCurrentTeamRow = props => (
  <tr>
    <td onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name.split(' ')[0][0] + '. ' + props.player.Name.split(' ')[1]}</b> ({props.player.Position})</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>
      <Button
        className="add-player-button" style={{
          marginRight: 10,
          backgroundColor: "#0E1C36"
        }}
        onClick={() => props.dropFunc(props)}
      >
        {props.added ? "CANCEL" : "DROP"}
      </Button>
    </td>
  </tr>
)

const BlankRow = props => (
  <tr style={{
    backgroundColor: "lightgrey"
  }}>
    <td>{props.currentPosition}</td>
    <td><b>EMPTY</b></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
)

const MobileBlankRow = props => (
  <tr style={{
    backgroundColor: "lightgrey"
  }}>
    <td>{props.currentPosition}</td>
    <td><b>EMPTY</b></td>
    <td></td>
  </tr>
)


export default class DropPlayer extends Component {

  constructor() {
    super();
    this.state = {
      mobile: window.innerWidth < 700,
      userID: localStorage.userID,
      leagueID: window.location.href.split('/').slice(-1)[0].split(':')[0],
      startYear: 1970,
      endYear: 2020,
      teamID: window.location.href.split('/').slice(-1)[0].split(':')[1],
      playerIndex: window.location.href.split('/').slice(-1)[0].split(':')[2],
      playerList: [],
      draftBoard: [],
      playerToAdd: "",
      stubToAdd: "",
      expectedPositions: []
    }
  }

  componentDidMount() {
    this.loadUserTeam()
  }

  async loadUserTeam() {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${this.state.teamID}`)
      .then((response) => this.setState({
        playerList: response.data.playerList
      }))
      .then((response) => this.loadDraftBoard())
      .catch(err => console.log(err))
  }

  async loadDraftBoard() {
    console.log("Loading Draft Board")
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/fetchDraftBoard/${this.state.leagueID}`)
      .then((response) => this.setState({
        startYear: response.data.startYear,
        endYear: response.data.endYear,
        draftBoard: response.data.draftBoard,
        playerToAdd: response.data.draftBoard[this.state.playerIndex].Name,
        stubToAdd: response.data.draftBoard[this.state.playerIndex].Stub,
        expectedPositions: response.data.roster
      }))
      .catch((err) => console.log(err))
  }

  displayPlayer() {
    const addedPlayer = this.state.draftBoard[this.state.playerIndex]
    const strtYr = this.state.startYear
    const endYr = this.state.endYear
    if (!this.state.mobile) {
      return <CurrentTeamRow player={addedPlayer}
        dropFunc={() => window.open(`/addPlayer/${this.state.leagueID}:${this.state.teamID}`, '_self')}
        startYear={strtYr}
        endYear={endYr}
        added={true}
        key={0} />
    } else {
      return <MobileCurrentTeamRow player={addedPlayer}
        dropFunc={() => window.open(`/addPlayer/${this.state.leagueID}:${this.state.teamID}`, '_self')}
        startYear={strtYr}
        endYear={endYr}
        added={true}
        key={0} />
    }
  }

  displayTeamRows() {
    if (this.state.playerList) {
      var newPlayerList = [...this.state.playerList]
      var labelOrder = [...this.state.expectedPositions]
      const bench = labelOrder.filter(ele => ele.includes('BE'))
      const nonBench = labelOrder.length - bench.length
      for (var q = 0; q < nonBench; q++) {
        labelOrder.push('BE' + String(bench.length + q + 1))
      }
      if (!this.state.mobile) {
        const strtYr = this.state.startYear
        const endYr = this.state.endYear
        var outputArray = []
        newPlayerList.sort(function(a, b) {
          return labelOrder.indexOf(a.currentPosition) - labelOrder.indexOf(b.currentPosition)
        })
        for (var i = 0; i < newPlayerList.length; i++) {
          var currentPlayer = newPlayerList[i]
          outputArray.push(<CurrentTeamRow player={currentPlayer}
            dropFunc={(val) => this.dropPlayer(val)}
            startYear={strtYr}
            endYear={endYr}
            added={false}
            key={i} />)
        };
        return outputArray
      } else {
        const strtYr = this.state.startYear
        const endYr = this.state.endYear
        var outputArray = []
        newPlayerList.sort(function(a, b) {
          return labelOrder.indexOf(a.currentPosition) - labelOrder.indexOf(b.currentPosition)
        })
        for (var i = 0; i < newPlayerList.length; i++) {
          var currentPlayer = newPlayerList[i]
          outputArray.push(<MobileCurrentTeamRow player={currentPlayer}
            dropFunc={(val) => this.dropPlayer(val)}
            startYear={strtYr}
            endYear={endYr}
            added={false}
            key={i} />)
        };
        return outputArray
      }
    } else {
      var labelOrder = [...this.state.expectedPositions]
      if (!this.state.mobile) {
        return labelOrder.map(function(currPos, i) {
          return <BlankRow currentPosition={currPos} key={i} />;
        })
      } else {
        return labelOrder.map(function(currPos, i) {
          return <MobileBlankRow currentPosition={currPos} key={i} />;
        })
      }
    }
  }

  dropPlayer(props) {
    var resp = window.confirm(
      `Are you sure you want to:

      DROP ${props.player.Name}
        and
      ADD ${this.state.playerToAdd}`)
    if (resp) {
      const player = this.state.draftBoard[this.state.playerIndex]
      var updatedPlayerList = [...this.state.playerList]
      var occupiedPositions = []
      for (var z = 0; z < updatedPlayerList.length; z++) {
        occupiedPositions.push(updatedPlayerList[z].currentPosition)
      }
      const bench = occupiedPositions.filter(ele => ele.includes('BE'))
      updatedPlayerList = updatedPlayerList.filter(ele => ele.Stub !== props.player.Stub)
      if (props.player.currentPosition.includes("BE")) {
        player.currentPosition = props.player.currentPosition
      } else if (player.Position === props.player.Position) {
        player.currentPosition = props.player.currentPosition
      } else {
        player.currentPosition = "BE" + String(bench.length + 1)
      }
      updatedPlayerList.push(player)
      console.log(updatedPlayerList)
      const teamObj = {
        playerList: updatedPlayerList
      }
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/addDropPlayer/${this.state.teamID}`, teamObj)

      var updatedDraftBoard = [...this.state.draftBoard]
      console.log(updatedDraftBoard.length)
      console.log(this.state.stubToAdd)
      updatedDraftBoard = updatedDraftBoard.filter(ele => ele.Stub !== this.state.stubToAdd)
      updatedDraftBoard.splice(this.state.playerIndex, 0, props.player)
      console.log(updatedDraftBoard.length)
      const draftObj = {
        draftBoard: updatedDraftBoard
      }
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/modifyDraftBoard/${this.state.leagueID}`, draftObj)

      setTimeout(() => {window.open(`/viewTeam/${this.state.teamID}`, "_self")}, 1000)
    }
  }

  render() {
    if (!this.state.mobile) {
      if (this.state.draftBoard.length - 1 < this.state.playerIndex) {
        return <div />
      } else {
        return (
          <div>
            <div className="draftBoardCell" style={{
              marginLeft: "10%",
              marginRight: "10%",
              padding: "4px"
            }}>
              <div className="card">
                <div className="card-content">
                  <div style={{
                    width: "100%"
                  }}>
                    <h5><b>Player to Add:</b></h5>
                  </div>
                  <table className="table highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Name (Position)</th>
                        <th>Num Games</th>
                        <th>Total Pts</th>
                        <th>Avg PPG</th>
                        <th>StDev PPG</th>
                        <th>Under 10 Pts</th>
                        <th>Over 20 Pts</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.displayPlayer()}
                    </tbody>
                  </table>
                  <div style={{
                    width: "100%"
                  }}>
                    <h5><b>Player to Drop:</b></h5>
                  </div>
                  <table className="table highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Name (Position)</th>
                        <th>Num Games</th>
                        <th>Total Pts</th>
                        <th>Avg PPG</th>
                        <th>StDev PPG</th>
                        <th>Under 10 Pts</th>
                        <th>Over 20 Pts</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.displayTeamRows()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (this.state.draftBoard.length - 1 < this.state.playerIndex) {
        return <div />
      } else {
        return (
          <div className="draftBoardCell" style={{
            marginLeft: "5%",
            marginRight: "5%",
            padding: "4px"
          }}>
            <div className="card">
              <div className="card-content" style={{
                padding: "8px"
              }}>
                <div style={{
                  width: "100%"
                }}>
                  <h5><b>Player to Add:</b></h5>
                </div>
                <table className="table highlight" style={{ marginTop: 20 }}>
                  <thead>
                    <tr>
                      <th>Name (Position)</th>
                      <th>Avg PPG</th>
                      <th>StDev PPG</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.displayPlayer()}
                  </tbody>
                </table>
                <br />
                <div style={{
                  width: "100%"
                }}>
                  <h5><b>Player to Drop:</b></h5>
                </div>
                <table className="table highlight" style={{ marginTop: 20 }}>
                  <thead>
                    <tr>
                      <th>Name (Position)</th>
                      <th>Avg PPG</th>
                      <th>StDev PPG</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.displayTeamRows()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      }
    }
  }

}
