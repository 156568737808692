import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import HomePage from "./components/layout/HomePage";
import CreateLeague from "./components/layout/CreateLeague";
import UserProfile from "./components/layout/UserProfile";
import InviteTeams from "./components/layout/InviteTeams";
import EnterLeagueCreds from "./components/layout/EnterLeagueCreds";
import LeaguePage from "./components/layout/LeaguePage";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer"
import Dashboard from "./components/layout/Dashboard";
import PlayerPage from "./components/layout/PlayerPage";
import EditTeamPage from "./components/layout/EditTeamPage";
import ViewTeamPage from "./components/layout/ViewTeamPage";
import AddPlayer from "./components/layout/AddPlayer";
import DropPlayer from "./components/layout/DropPlayer";
import TradePlayers from "./components/layout/TradePlayers"
import TradeReview from "./components/layout/TradeReview"
import EditLeagueSettings from "./components/layout/EditLeagueSettings";
import Matchup from "./components/layout/Matchup";
import Draft from "./components/layout/Draft";
import PremiumUpdate from "./components/layout/PremiumUpdate";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import TermsConditions from "./components/legal/TermsConditions";
import LeaguePassCheckout from "./components/stripe/LeaguePassCheckout";
import UpgradeCheckout from "./components/stripe/UpgradeCheckout";
import SubscriptionCheckout from "./components/stripe/SubscriptionCheckout";


require('dotenv').config()

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <Route exact path="/" component={HomePage} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route path="/player/:id" component={PlayerPage} />
            <Route path="/editTeam/:id" component={EditTeamPage} />
            <Route path="/viewTeam/:id" component={ViewTeamPage} />
            <Route path="/addPlayer/:id" component={AddPlayer} />
            <Route path="/dropPlayer/:id" component={DropPlayer} />
            <Route path="/tradePlayers/:id" component={TradePlayers} />
            <Route path="/tradeReview/:id" component={TradeReview} />
            <Route path="/inviteTeams/:id" component={InviteTeams} />
            <Route path="/viewLeague/:id" component={LeaguePage} />
            <Route path="/editLeagueSettings/:id" component={EditLeagueSettings} />
            <Route path="/matchup/:id" component={Matchup} />
            <Route path="/draft/:id" component={Draft} />
            <Route path="/premiumUpdate/:id" component={PremiumUpdate} />
            <Switch>
              <PrivateRoute exact path="/enterLeagueCreds" component={EnterLeagueCreds} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/createLeague" component={CreateLeague} />
              <PrivateRoute exact path="/userProfile" component={UserProfile} />
            </Switch>
            <Route path="/privacyPolicy" component={PrivacyPolicy} />
            <Route path="/termsConditions" component={TermsConditions} />
            <Route path="/leaguePassCheckout/:id" component={LeaguePassCheckout} />
            <Route path="/upgradeCheckout/:id" component={UpgradeCheckout} />
            <Route path="/subscriptionCheckout/:id" component={SubscriptionCheckout} />
            {
              window.location.href.includes('draft') ? <div /> : <Footer />
            }
          </div>
        </Router>
      </Provider>
    );

  }
}

export default App;
