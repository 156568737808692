import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const Invite = props => (
  <tr>
    <td>{props.invite.leagueName}</td>
    <td>{props.invite.creatorName}</td>
    <td>
      <Button
        className="draft-player-button" style={{
          marginRight: 10,
          backgroundColor: "#0E1C36"
        }}
        onClick={() => props.joinFunc(props)}
      >
        JOIN
      </Button>
    </td>
  </tr>
)

const BlankInvite = props => (
  <tr>
    <td></td>
    <td><b>No active invitations</b></td>
    <td></td>
  </tr>
)

export default class EnterLeagueCreds extends Component {

  constructor() {
    super();

    this.state = {
      userID: localStorage.userID,
      userName: localStorage.userName,
      invites: [],
      leagues: [],
      teams: []
    };
  }

  componentDidMount() {
    this.fetchInvites()
  }

  async fetchInvites() {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/invites/fetchInvites/${this.state.userID}`)
      .then((response) => {this.setState({ invites: response.data })})
      .then((response) => this.fetchInviteLeagues(this.state.invites))
  }

  async fetchInviteLeagues(invites) {
    var leagueArray = [];
    var i = 0;
    for (i=0; i < invites.length; i++) {
      console.log(invites[i].leagueID)
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagues/${invites[i].leagueID}`)
        .then((res) => leagueArray.push(res.data))
        .then(() => this.setState({ leagues: leagueArray }))
    }
  }

  joinLeagueById(props) {
    console.log(props)
    const id = props.invite._id
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/joinLeague/${id}`)
      .then((response) => {this.setState({ teams: response.data })})
      .then((response) => this.assignEmptyTeam({
        userID: this.state.userID,
        userName: this.state.userName
      }))
      .then((response) => this.deleteInvite(id, this.state.userID))
    setTimeout(() => {
      window.open('/dashboard', '_self')
    }, 1500)
  }

  displayInvites() {
    var inviteArray = []
    if (this.state.leagues.length === 0) {
      inviteArray.push(<BlankInvite key={0} />)
    } else {
      for (var i=0; i < this.state.leagues.length; i++) {
        var currentInvite = this.state.leagues[i]
        inviteArray.push(<Invite invite={currentInvite} joinFunc={(val) => this.joinLeagueById(val)} key={i} />)
      }
    }
    return inviteArray
  }

  assignEmptyTeam(obj) {
    this.state.teams.every(function(team, index) {
      if (team.userID === "") {
        team.userID = obj.userID
        team.userName = obj.userName
        axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/updateTeam/${team._id}`, team)
          .then((res) => console.log(res.lastErrorObject.updatedExisting))
        return false
      } else {
        return true
      }
    })
  }

  async deleteInvite(leagueID, userID) {
    const obj = {
      leagueID: leagueID,
      userID: userID
    }
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/invites/deleteInvite`, obj)
  }


  render() {
    return (
      <div className="container">
        <div style={{ height: "30vh" }} className="container valign-wrapper">
          <div className="row">
            <div className="col s12 center-align">
              <h4>
                <b>Welcome, </b> {localStorage.userName.split(" ")[0]}
                <p className="flow-text grey-text text-darken-1">
                  Explore your open invites below
                </p>
              </h4>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <h3>Invite List</h3>
            <table className="table highlight" style={{ marginTop: 20 }}>
              <thead>
                <tr>
                  <th>League Name</th>
                  <th>Creator</th>
                  <th>Join</th>
                </tr>
              </thead>
              <tbody>
                {this.displayInvites()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )

    // return (
    //   <div className="container col s10">
    //     <div className="card" style={{align: "center"}}>
    //       <div className="card-content">
    //         <div className="input-field">
    //           <input type="text" id="leagueIDField" style={{fontSize: "150%"}} />
    //         </div>
    //         <div className="center-align">
    //           <button className="btn btn-large waves-effect hoverable accent-3"
    //             onClick={() => {this.joinLeagueById(document.getElementById("leagueIDField").value)}}
    //             style={{
    //               width: "140px",
    //               borderRadius: "3px",
    //               letterSpacing: "1.5px",
    //               backgroundColor: "#0E1C36",
    //               align: "center"
    //             }}
    //           >
    //           JOIN
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );

  }

}
