import React, { Component } from 'react';
import axios from 'axios';
import "../../styles/PlayerPage.css"

const Game = props => rowFormattting(props)

function rowFormattting(props) {
  if (Number(props.game.Year) % 2 === 0) {
    return (
      <tr className="evenYear">
        <td>{props.game.Year}</td>
        <td>{props.game.Week}</td>
        <td>{props.game.Tm}</td>
        <td>{props.game.Opp}</td>
        <td>{props.game.Passing_Yds}</td>
        <td>{props.game.Passing_TD}</td>
        <td>{props.game.Passing_Int}</td>
        <td>{props.game.Rushing_Yds}</td>
        <td>{props.game.Rushing_TD}</td>
        <td>{props.game.Receiving_Rec}</td>
        <td>{props.game.Receiving_Yds}</td>
        <td>{props.game.Receiving_TD}</td>
        <td>{props.game.Fumbles_FL}</td>
      </tr>
    );
  } else {
    return (
      <tr className="oddYear">
        <td>{props.game.Year}</td>
        <td>{props.game.Week}</td>
        <td>{props.game.Tm}</td>
        <td>{props.game.Opp}</td>
        <td>{props.game.Passing_Yds}</td>
        <td>{props.game.Passing_TD}</td>
        <td>{props.game.Passing_Int}</td>
        <td>{props.game.Rushing_Yds}</td>
        <td>{props.game.Rushing_TD}</td>
        <td>{props.game.Receiving_Rec}</td>
        <td>{props.game.Receiving_Yds}</td>
        <td>{props.game.Receiving_TD}</td>
        <td>{props.game.Fumbles_FL}</td>
      </tr>
    );
  }
};

export default class PlayerPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      stub: window.location.href.split('/').slice(-1)[0].split(':')[0],
      startYear: window.location.href.split('/').slice(-1)[0].split(':')[1],
      endYear: window.location.href.split('/').slice(-1)[0].split(':')[2],
      playerName: "",
      games: []
    }
  }

  componentDidMount() {
    const obj = {
      stub: this.state.stub
    };
    this.fetchGames(obj)
  }

  async fetchGames(obj) {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/player/fetchGames/${obj.stub}`)
      .then((response) => {this.setState({ games: response.data })})
      .then((response) => console.log(this.state))
      .catch((error) => {console.log(error)})
    this.setState({playerName: this.state.games[0].Name})
  }

  displayGames() {
    var strtYr = this.state.startYear
    var endYr = this.state.endYear
    return this.state.games.map(function(currentGame, i) {
      if (currentGame.Year >= strtYr && currentGame.Year <= endYr) {
        return <Game game={currentGame} key={i} />;
      }
    })
  }

  render() {
    return (
      <div className="container">
        <div style={{ height: "10vh" }} className="container valign-wrapper">
          <div className="row">
            <div className="col s12 center-align">
              <h4>
                <b>{this.state.playerName}</b>
              </h4>
            </div>
          </div>
        </div>

        <div className="card col s12">
          <div className="card-content">
            <table className="table fixed_header">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Week</th>
                  <th>Team</th>
                  <th>Opp</th>
                  <th>Passing Yds</th>
                  <th>Passing TD</th>
                  <th>Passing Int</th>
                  <th>Rushing Yds</th>
                  <th>Rushing TD</th>
                  <th>Receiving Rec</th>
                  <th>Receiving Yds</th>
                  <th>Receiving TD</th>
                  <th>Fumbles Lost</th>
                </tr>
              </thead>
              <tbody>
                {this.displayGames()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

}
