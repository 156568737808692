import React, { Component } from "react";
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';


class SubscriptionCheckout extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      stripeCustomerId: window.location.href.split('/').slice(-1)[0].split(':')[0],
      userID: window.location.href.split('/').slice(-1)[0].split(':')[1],
      email: window.location.href.split('/').slice(-1)[0].split(':')[2],
    };

    this.redirectToStripe = this.redirectToStripe.bind(this)
  };

  componentDidMount() {
    console.log(window.location.href.split('/').slice(-1)[0])
    this.redirectToStripe()
  }

  async redirectToStripe() {
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB}`);

    const stripe = await stripePromise;
    const obj = {
      userID: this.state.userID,
      email: this.state.email,
      customerId: this.state.stripeCustomerId
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/stripe/subscriptionSession`, obj)

    const result = await stripe.redirectToCheckout({
      sessionId: response.data.sessionId,
    });
  }

  render() {
    return (
      <div className="container">
        <h3>Redirecting to Subscription Checkout</h3>
      </div>
    )
  }

}

export default SubscriptionCheckout;
