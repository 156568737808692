import React, { Component } from 'react';
import M from "materialize-css";
import axios from "axios";
import "materialize-css/js/collapsible.js"
import "../../App.css"
import "../../styles/PlayerTable.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog, faCalculator, faPeopleArrows, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { loadStripe } from '@stripe/stripe-js';


const Season = props => (
  <tr onClick={() => window.open(`/player/${props.season.Stub}:${props.startYear}:${props.endYear}`, '_blank')}>
    <td>{props.season.Name}</td>
    <td>{props.season.Position}</td>
    <td>{props.season.Games_Started}</td>
    <td>{props.season.Standard_Pts.toFixed(2)}</td>
  </tr>
)

const MobileSeason = props => (
  <tr onClick={() => window.open(`/player/${props.season.Stub}:${props.startYear}:${props.endYear}`, '_blank')}>
    <td>{props.season.Name.split(' ')[0][0] + '. ' + props.season.Name.split(' ')[1]}</td>
    <td>{props.season.Position}</td>
    <td>{props.season.Games_Started}</td>
    <td>{props.season.Standard_Pts.toFixed(2)}</td>
  </tr>
)

class CreateLeague extends Component {

	constructor(props) {
    super(props);

    this.state = {
      mobile: window.innerWidth < 700,
      premium: false,
      stripeCustomerId: "",
      leagueName: "",
      scoringFormat: "STD",
      startYear: 2000,
      endYear: 2005,
      numberOfTeams: "6",
      playoffs: false,
      playoffSize: "0",
      seasonSpeed: "weekly",
      seasons: [],
      QBs: 1,
      RBs: 2,
      WRs: 2,
      TEs: 1,
      FLs: 1,
      BEs: 2,
      PassYds: 0.04,
      PassTDs: 4,
      PassInt: -2,
      RushYds: 0.1,
      RushTDs: 6,
      Rec: 0,
      RecYds: 0.1,
      RecTDs: 6,
      FumLost: -2,
      TwoPM: 2,
      PassBonus: 0,
      RushBonus: 0,
      RecBonus: 0
    };

    this.onNameChange = this.onNameChange.bind(this)
    this.onScoringChange = this.onScoringChange.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.onCustomScoring = this.onCustomScoring.bind(this)
    this.onPlayerUpdate = this.onPlayerUpdate.bind(this)
    this.onPlayoffChange = this.onPlayoffChange.bind(this)
    this.onPlayoffSizeChange = this.onPlayoffSizeChange.bind(this)
    this.onSeasonSpeedChange = this.onSeasonSpeedChange.bind(this)
    this.onTeamNumberChange = this.onTeamNumberChange.bind(this)
    this.buildRoster = this.buildRoster.bind(this)
    this.buildScoringDict = this.buildScoringDict.bind(this)
    this.revealAdvancedOptions = this.revealAdvancedOptions.bind(this)
    this.finalizeLeague = this.finalizeLeague.bind(this)
  }

  async componentDidMount() {
  	const obj = {
        startYear: Number(this.state.startYear),
        endYear: Number(this.state.endYear)
    };
  	this.reloadSeasonData(obj)
    this.fetchUserDetails(localStorage.userID)

    await import('materialize-css/js/collapsible.js')
    const elems = window.document.querySelectorAll('.collapsible');
    const options = {};
    window.M.Collapsible.init(elems, options)
    window.M.updateTextFields()
    // document.addEventListener('DOMContentLoaded', function() {
    //   var elems = document.querySelectorAll('.collapsible');
    //   var instances = M.Collapsible.init(elems, {accordion: false});
    // });
  }

  onNameChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onScoringChange = e => {
    this.setState({ scoringFormat: e.target.value });
    if (e.target.value === "STD") {
      this.setState({
        PassYds: 0.04,
        PassTDs: 4,
        PassInt: -2,
        RushYds: 0.1,
        RushTDs: 6,
        Rec: 0,
        RecYds: 0.1,
        RecTDs: 6,
        FumLost: -2,
        TwoPM: 2,
        PassBonus: 0,
        RushBonus: 0,
        RecBonus: 0
      })
    } else if (e.target.value === "PPR") {
      this.setState({
        PassYds: 0.04,
        PassTDs: 4,
        PassInt: -2,
        RushYds: 0.1,
        RushTDs: 6,
        Rec: 1,
        RecYds: 0.1,
        RecTDs: 6,
        FumLost: -2,
        TwoPM: 2,
        PassBonus: 0,
        RushBonus: 0,
        RecBonus: 0
      })
    } else if (e.target.value === "DK") {
      this.setState({
        PassYds: 0.04,
        PassTDs: 4,
        PassInt: -1,
        RushYds: 0.1,
        RushTDs: 6,
        Rec: 1,
        RecYds: 0.1,
        RecTDs: 6,
        FumLost: -1,
        TwoPM: 2,
        PassBonus: 3,
        RushBonus: 3,
        RecBonus: 3
      })
    } else if (e.target.value === "FD") {
      this.setState({
        PassYds: 0.04,
        PassTDs: 4,
        PassInt: -1,
        RushYds: 0.1,
        RushTDs: 6,
        Rec: 0.5,
        RecYds: 0.1,
        RecTDs: 6,
        FumLost: -2,
        TwoPM: 2,
        PassBonus: 0,
        RushBonus: 0,
        RecBonus: 0
      })
    }
  }

  onFieldChange = e => {
    this.setState({ [e.target.id]: Number(e.target.value) });
  };

  onCustomScoring = e => {
    this.setState({
      [e.target.id]: Number(e.target.value),
      scoringFormat: "Custom"
    });
  }

  onPlayoffChange = e => {
    if (e.target.checked) {
      this.setState({ playoffs: true });
    } else {
      this.setState({ playoffs: false });
    }
    console.log(this.state.playoffs)
  }

  onTeamNumberChange = e => {
    this.setState({ numberOfTeams: e.target.value });
  }

  onPlayoffSizeChange = e => {
    if (Number(e.target.value) <= Number(this.state.numberOfTeams)) {
      this.setState({ playoffSize: e.target.value });
    } else {
      alert("Cannot have more playoff berths than total teams")
    }
  }

  onSeasonSpeedChange = e => {
    this.setState({seasonSpeed: e.target.value });
  }

  async reloadSeasonData(obj) {
  	const { data } = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/seasons/fetchSeasons`, obj)
  	this.setState({ seasons: data })
  	console.log(this.state.seasons.length)
  }

  async fetchUserDetails(userID) {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/users/fetchUser/${userID}`)
    this.setState({
      premium: response.data.premium,
      stripeCustomerId: response.data.stripeCustomerId
    })
    console.log(this.state.premium)
  }

  revealAdvancedOptions(event) {
    event.preventDefault();
    document.getElementById('advancedDiv').style.display = "block"
  }

  onLeagueSubmit(event) {
    event.preventDefault()
    if (this.state.startYear > this.state.endYear) {
      alert("The Start Year must be less than or equal to the End Year")
    } else if (this.state.endYear - this.state.startYear > 10) {
      var resp = window.confirm(
        `Warning: Leagues that include more than 10 years are sometimes suscpetible to bias as scoring has tended to increase through time.
        This may make winning with players exclusively from the beginning of the range very difficult.

        Are you sure you want to create a league with this range?`)
      if (resp) {
        this.finalizeLeague()
      }
    } else {
      this.finalizeLeague()
    }
  }


  finalizeLeague() {
    var leagueID = ""
    var advancedChanged = this.advancedSettingsChanged()
    const rosterArray = this.buildRoster()
    const scoringDict = this.buildScoringDict()
    const obj = {
      leagueName: this.state.leagueName,
      scoringFormat: this.state.scoringFormat,
      startYear: this.state.startYear,
      endYear: this.state.endYear,
      playoffs: this.state.playoffs,
      playoffSize: this.state.playoffSize,
      seasonSpeed: this.state.seasonSpeed,
      numberOfTeams: this.state.numberOfTeams,
      currentWeek: 0,
      creatorID: localStorage.userID,
      creatorName: localStorage.userName,
      premium: advancedChanged || this.state.premium ? true : false,
      winner: "",
      draftDate: 0,
      draftTime: "",
      memberEmails: [],
      roster: rosterArray,
      scoringDict: scoringDict
    };
    console.log(obj)
    var teamArray = [];
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/createLeague`, obj)
      .then(function(league) {
        leagueID = league.data._id
        league.data.userName = localStorage.userName
        league.data.roster = rosterArray
        var i=0;
        for (i=0; i < Number(league.data.numberOfTeams); i++) {
          if (i === 0) {
            axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/createUserTeam`, league)
              .then((team) => teamArray.push(team.data._id))
              .catch(err => console.log(err))
          } else {
            axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/createTeam`, league)
              .then((team) => teamArray.push(team.data._id))
              .catch(err => console.log(err))
          }
        };
      })
      .then(function() {
        if (teamArray.length > 0) {
          console.log(teamArray)
        }
      })
      .catch(err => console.log(err))
      setTimeout(() => { this.props.history.push('/inviteTeams/'+leagueID) }, 1000)
  }

  advancedSettingsChanged() {
    var changed = false
    if (this.state.scoringFormat === "Custom") {
      changed = true
    } else if (this.state.playoffs === true) {
      changed = true
    } else if (this.state.seasonSpeed !== "weekly") {
      changed = true
    } else if (this.state.QBs !== 1) {
      changed = true
    } else if (this.state.RBs !== 2) {
      changed = true
    } else if (this.state.WRs !== 2) {
      changed = true
    } else if (this.state.TEs !== 1) {
      changed = true
    } else if (this.state.FLs !== 1) {
      changed = true
    } else if (this.state.BEs !== 2) {
      changed = true
    }
    return changed
  }

  async redirectToStripe(leagueID, customerId) {
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB}`);

    const stripe = await stripePromise;
    const obj = {
      leagueID: leagueID,
      customerId: customerId
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/stripe/checkoutSession`, obj)

    const result = await stripe.redirectToCheckout({
      sessionId: response.data.id,
    });
  }

  buildRoster() {
    var rosterArray = []
    for (var i = 0; i < this.state.QBs; i++) {
      rosterArray.push("QB" + String(i+1))
    }
    for (var i = 0; i < this.state.RBs; i++) {
      rosterArray.push("RB" + String(i+1))
    }
    for (var i = 0; i < this.state.WRs; i++) {
      rosterArray.push("WR" + String(i+1))
    }
    for (var i = 0; i < this.state.TEs; i++) {
      rosterArray.push("TE" + String(i+1))
    }
    for (var i = 0; i < this.state.FLs; i++) {
      rosterArray.push("FL" + String(i+1))
    }
    for (var i = 0; i < this.state.BEs; i++) {
      rosterArray.push("BE" + String(i+1))
    }
    return rosterArray
  }

  buildScoringDict() {
    var scoringDict = {}
    scoringDict["PassYds"] = this.state.PassYds
    scoringDict["PassTDs"] = this.state.PassTDs
    scoringDict["PassInt"] = this.state.PassInt
    scoringDict["RushYds"] = this.state.RushYds
    scoringDict["RushTDs"] = this.state.RushTDs
    scoringDict["Rec"] = this.state.Rec
    scoringDict["RecYds"] = this.state.RecYds
    scoringDict["RecTDs"] = this.state.RecTDs
    scoringDict["PassBonus"] = this.state.PassBonus
    scoringDict["RushBonus"] = this.state.RushBonus
    scoringDict["RecBonus"] = this.state.RecBonus
    scoringDict["FumLost"] = this.state.FumLost
    scoringDict["TwoPM"] = this.state.TwoPM
    return scoringDict
  }

  filterByPosition(pos) {
    var strtYr = this.state.startYear
    var endYr = this.state.endYear
  	if (pos === '') {
      if (!this.state.mobile) {
    		return this.state.seasons.map(function(currentSeason, i) {
          if (currentSeason.Standard_Pts > 0 && currentSeason.Games_Started >= 16) {
  	         return <Season season={currentSeason} startYear={strtYr} endYear={endYr} key={i} />;
          }
      	});
      } else {
        return this.state.seasons.map(function(currentSeason, i) {
          if (currentSeason.Standard_Pts > 0 && currentSeason.Games_Started >= 16) {
  	         return <MobileSeason season={currentSeason} startYear={strtYr} endYear={endYr} key={i} />;
          }
      	});
      }
  	} else {
      if (!this.state.mobile) {
    		return this.state.seasons.map(function(currentSeason, i) {
  	  		if (currentSeason.Position === pos) {
            if (currentSeason.Standard_Pts > 0  && currentSeason.Games_Started >= 16) {
  	           return <Season season={currentSeason} startYear={strtYr} endYear={endYr} key={i} />;
            }
  	  		}
  	    });
      } else {
        return this.state.seasons.map(function(currentSeason, i) {
  	  		if (currentSeason.Position === pos) {
            if (currentSeason.Standard_Pts > 0  && currentSeason.Games_Started >= 16) {
  	           return <MobileSeason season={currentSeason} startYear={strtYr} endYear={endYr} key={i} />;
            }
  	  		}
  	    });
      }
  	}
  };

  onPlayerUpdate(event) {
  	event.preventDefault();
  	console.log("Updating...")
  	if (this.state.startYear <= this.state.endYear) {
  		const obj = {
        startYear: Number(this.state.startYear),
        endYear: Number(this.state.endYear)
    	};
    	console.log(obj)
  		this.reloadSeasonData(obj);
    }
  };

  submitLeagueParams(event) {
    event.preventDefault();
    console.log('Saving new league to DB!')
  };

	openTab(evt, tabName) {
		// Declare all variables
		var i, tabcontent, tablinks;

		// Get all elements with className="tabcontent" and hide them
		tabcontent = document.getElementsByClassName("tabcontent");
		for (i = 0; i < tabcontent.length; i++) {
			tabcontent[i].style.display = "none";
		}

		// Get all elements with className="tablinks" and remove the class "active"
		tablinks = document.getElementsByClassName("tablinks");
		for (i = 0; i < tablinks.length; i++) {
			tablinks[i].className = tablinks[i].className.replace(" active", "");
		}

		// Show the current tab, and add an "active" class to the button that opened the tab
		document.getElementById(tabName).style.display = "block";
		evt.currentTarget.className += " active";
	}

  render() {
    if (!this.state.mobile) {
      return (
        <div className="container">
          <br />
          <div className="card">
            <div className="card-content">
              <div className="col s8 offset s2">
                <form name="createLeagueForm" noValidate>
                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        value={ this.state.leagueName }
                        id="leagueName"
                        type="text"
                        onChange={ this.onNameChange }
                      />
                      <label>League Name</label>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div>
                      <div className="col s6">
                        <select
                          className="browser-default"
                          value={ this.state.scoringFormat }
                          onChange= { this.onScoringChange }
                        >
                          <option value="STD">Standard</option>
                          <option value="PPR">PPR</option>
                          <option value="DK">Draftkings</option>
                          <option value="FD">FanDuel</option>
                          <option value="Custom">Custom</option>
                        </select>
                        <label>Scoring Format</label>
                      </div>
                    </div>
                    <div className="col s6">
                      <div>
                        <div className="col s12">
                          <select
                            className="browser-default"
                            value={ this.state.numberOfTeams }
                            onChange={ this.onTeamNumberChange }
                          >
                            <option value="6">6</option>
                            <option value="8">8</option>
                            <option value="10">10</option>
                            <option value="12">12</option>
                          </select>
                          <label>Number of Teams</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{height: "24px"}} />

                  <div className="row">
                    <div className="col s12">
                      <label className="col s2" style={{fontSize: "150%"}}>
                        <b>Start Year</b>
                      </label>
                      <p className="range-field col s8">
                        <input
                          type="range"
                          id="startYear"
                          min="1970"
                          max="2020"
                          value={this.state.startYear}
                          step="1"
                          onChange={this.onFieldChange}
                        />
                      </p>
                      <p className="col s2">
                        <output name="startYearOutput" id="startYearOutputId">
                          { this.state.startYear }
                        </output>
                      </p>
                    </div>
                  </div>

                  <div style={{height: "24px"}} />

                  <div className="row">
                    <div className="col s12">
                      <label className="col s2" style={{fontSize: "150%"}}>
                        <b>End Year</b>
                      </label>
                      <p className="range-field col s8">
                        <input
                          type="range"
                          id="endYear"
                          min="1970"
                          max="2020"
                          value={this.state.endYear}
                          step="1"
                          onChange={this.onFieldChange}
                        />
                      </p>
                      <p className="col s2">
                        <output name="endYearOutput" id="endYearOutputId">
                          { this.state.endYear }
                        </output>
                      </p>
                    </div>
                  </div>

                  <div style={{height: "24px"}} />

                  <div className="row valign-wrapper" style={{
                    justifyContent: "center"
                  }}>
                    <div id="advancedDiv" style={{display: "none", width: "80%"}}>
                      <ul className="collapsible">
                        <li>
                          <div className="collapsible-header"><i><FontAwesomeIcon icon={faUserCog}/></i><b>Roster Composition</b></div>
                          <div className="collapsible-body">
                            <div className="row">
                              <form className="col s12">
                                <div className="input-field col s2">
                                  <input id="QBs" type="number" min="0" max="5"
                                    value={ this.state.QBs }
                                    onChange= { this.onFieldChange }
                                  />
                                  <label for="QBs">QB</label>
                                </div>
                                <div className="input-field col s2">
                                  <input id="RBs" type="number" min="0" max="5"
                                    value={ this.state.RBs }
                                    onChange= { this.onFieldChange }
                                  />
                                  <label for="RBs">RB</label>
                                </div>
                                <div className="input-field col s2">
                                  <input id="WRs" type="number" min="0" max="5"
                                    value={ this.state.WRs }
                                    onChange= { this.onFieldChange }
                                  />
                                  <label for="WRs">WR</label>
                                </div>
                                <div className="input-field col s2">
                                  <input id="TEs" type="number" min="0" max="5"
                                    value={ this.state.TEs }
                                    onChange= { this.onFieldChange }
                                  />
                                  <label for="TEs">TE</label>
                                </div>
                                <div className="input-field col s2">
                                  <input id="FLs" type="number" min="0" max="5"
                                    value={ this.state.FLs }
                                    onChange= { this.onFieldChange }
                                  />
                                  <label for="FLs">FL</label>
                                </div>
                                <div className="input-field col s2">
                                  <input id="BEs" type="number" min="0" max="5"
                                    value={ this.state.BEs }
                                    onChange= { this.onFieldChange }
                                  />
                                  <label for="BEs">BE</label>
                                </div>
                              </form>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="collapsible-header"><i><FontAwesomeIcon icon={faCalculator}/></i><b>Scoring System</b></div>
                          <div className="collapsible-body">
                            <div className="row">
                              <form className="col s12">
                                <div className="row">
                                  <p><b>Passing Stats</b></p>
                                  <div className="input-field col s3">
                                    <input id="PassYds" type="number" step="0.01" min="0" max="1"
                                      value={ this.state.PassYds }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="PassYds">Passing Yds</label>
                                  </div>
                                  <div className="input-field col s3">
                                    <input id="PassTDs" type="number" min="0" max="10"
                                      value={ this.state.PassTDs }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="PassTDs">Passing TD</label>
                                  </div>
                                  <div className="input-field col s3">
                                    <input id="PassInt" type="number" min="-6" max="0"
                                      value={ this.state.PassInt }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="PassInt">Passing Int</label>
                                  </div>
                                </div>
                                <div className="row">
                                  <p><b>Rushing Stats</b></p>
                                  <div className="input-field col s3">
                                    <input id="RushYds" type="number" step="0.02" min="0" max="1"
                                      value={ this.state.RushYds }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="RushYds">Rushing Yds</label>
                                  </div>
                                  <div className="input-field col s3">
                                    <input id="RushTDs" type="number" min="0" max="10"
                                      value={ this.state.RushTDs }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="RushTDs">Rushing TD</label>
                                  </div>
                                </div>
                                <div className="row">
                                  <p><b>Receiving Stats</b></p>
                                  <div className="input-field col s3">
                                    <input id="Rec" type="number" step="0.25" min="0" max="2"
                                      value={ this.state.Rec }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="Rec">Reception</label>
                                  </div>
                                  <div className="input-field col s3">
                                    <input id="RecYds" type="number" step="0.02" min="0" max="1"
                                      value={ this.state.RecYds }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="RecYds">Receiving Yds</label>
                                  </div>
                                  <div className="input-field col s3">
                                    <input id="RecTDs" type="number" min="0" max="10"
                                      value={ this.state.RecTDs }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="RecTDs">Receiving TD</label>
                                  </div>
                                </div>
                                <div className="row">
                                  <p><b>Miscellaneous (All Positions)</b></p>
                                  <div className="input-field col s3">
                                    <input id="FumLost" type="number" min="-6" max="0"
                                      value={ this.state.FumLost }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="FumLost">Fumble Lost</label>
                                  </div>
                                  <div className="input-field col s3">
                                    <input id="2PM" type="number" min="0" max="2"
                                      value={ this.state.TwoPM }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="2PM">2-Pt Conversion Made</label>
                                  </div>
                                </div>
                                <div className="row">
                                  <p><b>Bonuses</b></p>
                                  <div className="input-field col s3">
                                    <input id="PassBonus" type="number" step="0.5" min="0" max="5"
                                      value={ this.state.PassBonus }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="PassBonus">300+ Pass Yds</label>
                                  </div>
                                  <div className="input-field col s3">
                                    <input id="RushBonus" type="number" step="0.5" min="0" max="5"
                                      value={ this.state.RushBonus }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="RushBonus">100+ Rush Yds</label>
                                  </div>
                                  <div className="input-field col s3">
                                    <input id="RecBonus" type="number" step="0.5" min="0" max="5"
                                      value={ this.state.RecBonus }
                                      onChange= { this.onCustomScoring }
                                    />
                                    <label for="RecBonus">100+ Rec Yds</label>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="collapsible-header"><i><FontAwesomeIcon icon={faPeopleArrows}/></i><b>Playoff Option</b></div>
                          <div className="collapsible-body">
                            <div className="row">
                              <div className="col s6">
                                <div
                                  className="switch"
                                >
                                  <label className="col s6" style={{fontSize: "150%"}}><b>Playoffs?</b></label>
                                  <label style={{fontSize: "150%"}}>
                                    Off
                                    <input
                                      type="checkbox"
                                      name="playoffSwitch"
                                      onChange={ this.onPlayoffChange }
                                    />
                                    <span className="lever"></span>
                                    On
                                  </label>
                                </div>
                              </div>
                              <div className="col s6">
                                <select
                                  className="browser-default"
                                  value={ this.state.playoffSize }
                                  onChange={ this.onPlayoffSizeChange }
                                >
                                  <option value="2">2</option>
                                  <option value="4">4</option>
                                  <option value="8">8</option>
                                </select>
                                <label>Number of Playoff Spots</label>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="collapsible-header"><i><FontAwesomeIcon icon={faTachometerAlt}/></i><b>Season Speed</b></div>
                          <div className="collapsible-body">
                            <div className="row">
                              <div className="col s12">
                                <select
                                  className="browser-default"
                                  value={ this.state.seasonSpeed }
                                  onChange={ this.onSeasonSpeedChange }
                                >
                                  <option value="weekly">Weekly (16 week season)</option>
                                  <option value="bidaily">Every 2 days (4 week season)</option>
                                  <option value="daily">Every day (2 week season)</option>
                                </select>
                                <label>Rate of Play</label>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col s12 center-align" style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly"
                  }}>
                    <button
                      style={{
                        width: "150px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem",
                        backgroundColor: "#0E1C36"
                      }}
                      onClick={ (event) => {this.onPlayerUpdate(event)} }
                      className="btn btn-large waves-effect hoverable accent-3"
                    >
                      UPDATE
                    </button>
                    <button
                      style={{
                        width: "150px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem",
                        backgroundColor: "#A20021"
                      }}
                      onClick={(event) => this.onLeagueSubmit(event)}
                      className="btn btn-large waves-effect hoverable accent-3"
                    >
                      CREATE
                    </button>
                    <button
                      style={{
                        width: "150px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem",
                        backgroundColor: "#0E1C36"
                      }}
                      onClick={(event) => this.revealAdvancedOptions(event)}
                      className="btn btn-large waves-effect hoverable accent-3"
                    >
                      ADVANCED
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <br />

          <div className="card">
            <div className="card-content">
              <h3>Available Player List</h3>
              <div className="tab col-12">
                <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'All')}>All</button>
                <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'QB')}>QB</button>
                <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'RB')}>RB</button>
                <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'WR')}>WR</button>
                <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'TE')}>TE</button>
              </div>

              <div id="All" className="tabcontent">
                <table className="playerTable highlight" style={{ marginTop: 20 }}>
                  <thead>
                    <tr>
                      <th>Player</th>
                      <th>Position</th>
                      <th>Games Started</th>
                      <th>Total Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.filterByPosition('') }
                  </tbody>
                </table>
              </div>
              <div id="QB" className="tabcontent" style={{display: "none"}}>
                  <table className="playerTable highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Player</th>
                        <th>Position</th>
                        <th>Games Started</th>
                        <th>Total Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.filterByPosition("QB") }
                    </tbody>
                  </table>
              </div>
              <div id="RB" className="tabcontent" style={{display: "none"}}>
                  <table className="playerTable highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Player</th>
                        <th>Position</th>
                        <th>Games Started</th>
                        <th>Total Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.filterByPosition("RB") }
                    </tbody>
                  </table>
              </div>
              <div id="WR" className="tabcontent" style={{display: "none"}}>
                  <table className="playerTable highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Player</th>
                        <th>Position</th>
                        <th>Games Started</th>
                        <th>Total Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.filterByPosition("WR") }
                    </tbody>
                  </table>
              </div>
              <div id="TE" className="tabcontent" style={{display: "none"}}>
                  <table className="playerTable highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Player</th>
                        <th>Position</th>
                        <th>Games Started</th>
                        <th>Total Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.filterByPosition("TE") }
                    </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="container">
            <br />
            <div className="card">
              <div style={{
                marginLeft: "8px",
                marginRight: "8px"
              }}>
                <div className="col s8 offset s2">
                  <form name="createLeagueForm" noValidate>
                    <div className="row">
                      <div className="input-field col s12">
                        <input
                          value={ this.state.leagueName }
                          id="leagueName"
                          type="text"
                          onChange={ this.onNameChange }
                        />
                        <label>League Name</label>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div>
                        <div className="col s6">
                          <select
                            className="browser-default"
                            value={ this.state.scoringFormat }
                            onChange= { this.onScoringChange }
                          >
                            <option value="STD">Standard</option>
                            <option value="PPR">PPR</option>
                            <option value="DK">Draftkings</option>
                            <option value="FD">FanDuel</option>
                            <option value="Custom">Custom</option>
                          </select>
                          <label>Scoring Format</label>
                        </div>
                      </div>
                      <div className="col s6">
                        <div>
                          <div className="col s12">
                            <select
                              className="browser-default"
                              value={ this.state.numberOfTeams }
                              onChange={ this.onTeamNumberChange }
                            >
                              <option value="6">6</option>
                              <option value="8">8</option>
                              <option value="10">10</option>
                              <option value="12">12</option>
                            </select>
                            <label>Number of Teams</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col s12 valign-wrapper">
                        <label className="col s3" style={{fontSize: "80%"}}>
                          <b>Start Year</b>
                        </label>
                        <p className="range-field col s7">
                          <input
                            type="range"
                            id="startYear"
                            min="1970"
                            max="2020"
                            value={this.state.startYear}
                            step="1"
                            onChange={this.onStartYearChange}
                          />
                        </p>
                        <p className="col s2">
                          <output name="startYearOutput" id="startYearOutputId">
                            { this.state.startYear }
                          </output>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col s12 valign-wrapper">
                        <label className="col s3" style={{fontSize: "90%"}}>
                          <b>End Year</b>
                        </label>
                        <p className="range-field col s7">
                          <input
                            type="range"
                            id="endYear"
                            min="1970"
                            max="2020"
                            value={this.state.endYear}
                            step="1"
                            onChange={this.onEndYearChange}
                          />
                        </p>
                        <p className="col s2">
                          <output name="endYearOutput" id="endYearOutputId">
                            { this.state.endYear }
                          </output>
                        </p>
                      </div>
                    </div>

                    <div className="row valign-wrapper" style={{
                      justifyContent: "center",
                      marginLeft: "6px",
                      marginRight: "6px"
                    }}>
                      <div id="advancedDiv" style={{display: "none"}}>
                        <div className="card" style={{backgroundColor: "lightgrey"}}>
                          <div className="card-content">
                            <p><b>Note:</b> If you elect to change any of the advanced settings below, you will be redirected to a payment page after hitting CREATE to purchase a one-time League Pass or a Premium subscription.</p>
                          </div>
                        </div>
                        <ul className="collapsible">
                          <li>
                            <div className="collapsible-header"><i><FontAwesomeIcon icon={faUserCog}/></i><b>Roster Composition</b></div>
                            <div className="collapsible-body">
                              <div className="row">
                                <form className="col s12">
                                  <div className="input-field col s2">
                                    <input id="QBs" type="number" min="0" max="5"
                                      value={ this.state.QBs }
                                      onChange= { this.onFieldChange }
                                    />
                                    <label for="QBs">QB</label>
                                  </div>
                                  <div className="input-field col s2">
                                    <input id="RBs" type="number" min="0" max="5"
                                      value={ this.state.RBs }
                                      onChange= { this.onFieldChange }
                                    />
                                    <label for="RBs">RB</label>
                                  </div>
                                  <div className="input-field col s2">
                                    <input id="WRs" type="number" min="0" max="5"
                                      value={ this.state.WRs }
                                      onChange= { this.onFieldChange }
                                    />
                                    <label for="WRs">WR</label>
                                  </div>
                                  <div className="input-field col s2">
                                    <input id="TEs" type="number" min="0" max="5"
                                      value={ this.state.TEs }
                                      onChange= { this.onFieldChange }
                                    />
                                    <label for="TEs">TE</label>
                                  </div>
                                  <div className="input-field col s2">
                                    <input id="FLs" type="number" min="0" max="5"
                                      value={ this.state.FLs }
                                      onChange= { this.onFieldChange }
                                    />
                                    <label for="FLs">FL</label>
                                  </div>
                                  <div className="input-field col s2">
                                    <input id="BEs" type="number" min="0" max="5"
                                      value={ this.state.BEs }
                                      onChange= { this.onFieldChange }
                                    />
                                    <label for="BEs">BE</label>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="collapsible-header"><i><FontAwesomeIcon icon={faCalculator}/></i><b>Scoring System</b></div>
                            <div className="collapsible-body" style={{
                              margin: "6px",
                              padding: "0px"
                            }}>
                              <div className="row">
                                <form className="col s12">
                                  <div className="row">
                                    <p style={{
                                      paddingLeft: "12px"
                                    }}><b>Passing Stats</b></p>
                                    <div className="input-field col s4">
                                      <input id="PassYds" type="number" step="0.01" min="0" max="1"
                                        value={ this.state.PassYds }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="PassYds">Passing Yds</label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input id="PassTDs" type="number" min="0" max="10"
                                        value={ this.state.PassTDs }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="PassTDs">Passing TD</label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input id="PassInt" type="number" min="-6" max="0"
                                        value={ this.state.PassInt }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="PassInt">Passing Int</label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <p style={{
                                      paddingLeft: "12px"
                                    }}><b>Rushing Stats</b></p>
                                    <div className="input-field col s4">
                                      <input id="RushYds" type="number" step="0.02" min="0" max="1"
                                        value={ this.state.RushYds }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="RushYds">Rushing Yds</label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input id="RushTDs" type="number" min="0" max="10"
                                        value={ this.state.RushTDs }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="RushTDs">Rushing TD</label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <p style={{
                                      paddingLeft: "12px"
                                    }}><b>Receiving Stats</b></p>
                                    <div className="input-field col s4">
                                      <input id="Rec" type="number" step="0.25" min="0" max="2"
                                        value={ this.state.Rec }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="Rec">Reception</label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input id="RecYds" type="number" step="0.02" min="0" max="1"
                                        value={ this.state.RecYds }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="RecYds">Receiving Yds</label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input id="RecTDs" type="number" min="0" max="10"
                                        value={ this.state.RecTDs }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="RecTDs">Receiving TD</label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <p style={{
                                      paddingLeft: "12px"
                                    }}><b>Miscellaneous (All Positions)</b></p>
                                    <div className="input-field col s4">
                                      <input id="FumLost" type="number" min="-6" max="0"
                                        value={ this.state.FumLost }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="FumLost">Fumble Lost</label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input id="2PM" type="number" min="0" max="2"
                                        value={ this.state.TwoPM }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="2PM">2-Pt Conv</label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <p style={{
                                      paddingLeft: "12px"
                                    }}><b>Bonuses</b></p>
                                    <div className="input-field col s4">
                                      <input id="PassBonus" type="number" step="0.5" min="0" max="5"
                                        value={ this.state.PassBonus }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="PassBonus">300 Pass Yds</label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input id="RushBonus" type="number" step="0.5" min="0" max="5"
                                        value={ this.state.RushBonus }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="RushBonus">100 Rush Yds</label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input id="RecBonus" type="number" step="0.5" min="0" max="5"
                                        value={ this.state.RecBonus }
                                        onChange= { this.onCustomScoring }
                                      />
                                      <label for="RecBonus">100 Rec Yds</label>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="collapsible-header"><i><FontAwesomeIcon icon={faPeopleArrows}/></i><b>Playoff Option</b></div>
                            <div className="collapsible-body" style={{
                              margin: "6px",
                              padding: "0px"
                            }}>
                              <div className="row">
                                <div className="col s6">
                                  <div
                                    className="switch"
                                  >
                                    <div className="row" style={{
                                      margin: "1rem"
                                    }}>
                                      <label className="col s6" style={{fontSize: "120%"}}><b>Playoffs?</b></label>
                                    </div>
                                    <div className= "row" style={{
                                      margin: "1rem"
                                    }}>
                                      <label style={{fontSize: "100%"}}>
                                        Off
                                        <input
                                          type="checkbox"
                                          name="playoffSwitch"
                                          onChange={ this.onPlayoffChange }
                                        />
                                        <span className="lever" style={{
                                          marginLeft: "8px",
                                          marginRight: "8px"
                                        }}></span>
                                        On
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col s5">
                                  <select
                                    className="browser-default"
                                    value={ this.state.playoffSize }
                                    onChange={ this.onPlayoffSizeChange }
                                  >
                                    <option value="2">2</option>
                                    <option value="4">4</option>
                                    <option value="8">8</option>
                                  </select>
                                  <label>Number of Playoff Spots</label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="collapsible-header"><i><FontAwesomeIcon icon={faTachometerAlt}/></i><b>Season Speed</b></div>
                            <div className="collapsible-body">
                              <div className="row">
                                <div className="col s12">
                                  <select
                                    className="browser-default"
                                    value={ this.state.seasonSpeed }
                                    onChange={ this.onSeasonSpeedChange }
                                  >
                                    <option value="weekly">Weekly (16 week season)</option>
                                    <option value="bidaily">Every 2 days (4 week season)</option>
                                    <option value="daily">Every day (2 week season)</option>
                                  </select>
                                  <label>Rate of Play</label>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col s12 center-align">
                      <div>
                        <button
                          style={{
                            width: "150px",
                            borderRadius: "3px",
                            letterSpacing: "1.5px",
                            backgroundColor: "#0E1C36"
                          }}
                          onClick={(event) => this.revealAdvancedOptions(event)}
                          className="btn btn-large waves-effect hoverable accent-3"
                        >
                          ADVANCED
                        </button>
                      </div>
                      <div>
                        <button
                          style={{
                            width: "150px",
                            borderRadius: "3px",
                            letterSpacing: "1.5px",
                            marginTop: "1rem",
                            backgroundColor: "#0E1C36"
                          }}
                          onClick={ (event) => {this.onPlayerUpdate(event)} }
                          className="btn btn-large waves-effect hoverable accent-3"
                        >
                          UPDATE
                        </button>
                      </div>
                      <div>
                        <button
                          style={{
                            width: "150px",
                            borderRadius: "3px",
                            letterSpacing: "1.5px",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            backgroundColor: "#A20021"
                          }}
                          onClick={(event) => this.onLeagueSubmit(event)}
                          className="btn btn-large waves-effect hoverable accent-3"
                        >
                          CREATE
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <br />

            <div className="card">
              <div className="card-content">
                <h5><b>Available Player List</b></h5>
                <div className="tab col-12">
                  <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'All')}>All</button>
                  <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'QB')}>QB</button>
                  <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'RB')}>RB</button>
                  <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'WR')}>WR</button>
                  <button className="tablinks col s2" onClick={(event) => this.openTab(event, 'TE')}>TE</button>
                </div>

                <div id="All" className="tabcontent">
                  <table className="playerTableMobile highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Pos</th>
                        <th>Gms</th>
                        <th>Pts</th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.filterByPosition('') }
                    </tbody>
                  </table>
                </div>
                <div id="QB" className="tabcontent" style={{display: "none"}}>
                    <table className="playerTableMobile highlight" style={{ marginTop: 20 }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Pos</th>
                          <th>Gms</th>
                          <th>Pts</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.filterByPosition("QB") }
                      </tbody>
                    </table>
                </div>
                <div id="RB" className="tabcontent" style={{display: "none"}}>
                    <table className="playerTableMobile highlight" style={{ marginTop: 20 }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Pos</th>
                          <th>Gms</th>
                          <th>Pts</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.filterByPosition("RB") }
                      </tbody>
                    </table>
                </div>
                <div id="WR" className="tabcontent" style={{display: "none"}}>
                    <table className="playerTableMobile highlight" style={{ marginTop: 20 }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Pos</th>
                          <th>Gms</th>
                          <th>Pts</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.filterByPosition("WR") }
                      </tbody>
                    </table>
                </div>
                <div id="TE" className="tabcontent" style={{display: "none"}}>
                    <table className="playerTableMobile highlight" style={{ marginTop: 20 }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Pos</th>
                          <th>Gms</th>
                          <th>Pts</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.filterByPosition("TE") }
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

}

export default CreateLeague
