import React, { Component } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import * as io from 'socket.io-client';
import "../../App.css"
const socket = io(process.env.NODE_ENV.development ? "" : "https://fantaseasons-backend.herokuapp.com");
var intervalHandle = null

const PlayerRow = props => (
  <tr>
    <td onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name}</b> ({props.player.Position})</td>
    <td>{props.player.Num_Games}</td>
    <td>{props.player.Total_Pts.toFixed(2)}</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>{props.player.Under_10}</td>
    <td>{props.player.Over_20}</td>
    <td>
      <Button
        disabled={!props.onTheClock}
        className="draft-player-button" style={{
          marginRight: 10,
          backgroundColor: "#0E1C36"
        }}
        onClick={() => props.draftFunc(props)}
      >
        DRAFT
      </Button>
    </td>
  </tr>
)

const MobilePlayerRow = props => (
  <tr>
    <td onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name.split(' ')[0][0] + '. ' + props.player.Name.split(' ')[1]}</b> ({props.player.Position})</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>
      <Button
        disabled={!props.onTheClock}
        className="draft-player-button" style={{
          marginRight: 10,
          backgroundColor: "#0E1C36"
        }}
        onClick={() => props.draftFunc(props)}
      >
        DRAFT
      </Button>
    </td>
  </tr>
)

const PickHistoryCard = props => (
  <div className="container" style={{
    width: "100px",
    height: "100px",
    padding: "4px",
    flexGrow: "0",
    flexShrink: "0"
  }}>
    <div className="card" style={{
      width: "100%",
      height: "100%",
      backgroundColor: props.highlight ? "rgba(14, 28, 54, 0.3)" : ""
    }}>
      <div className="card-content" style={{
        padding: "4px"
      }}>
        <p style={{fontSize: "80%"}}>Round: {props.player.round}</p>
        <p style={{fontSize: "80%"}}>Pick: {props.player.pick}</p>
        <p onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}
          style={{
          fontSize: "80%",
          verticalAlign: "bottom",
          float: "right"
        }}><b>{props.player.Name.split(' ')[0][0]}. {props.player.Name.split(' ')[1]}</b> ({props.player.Position})</p>
      </div>
    </div>
  </div>
)

const MobilePickHistoryCard = props => (
  <div className="container" style={{
    padding: "4px",
    flexGrow: "0",
    flexShrink: "0"
  }}>
    <div className="card" style={{
      padding: "6px",
      margin: "0rem 0 1rem 0",
      verticalAlign: "middle",
      display: "inline-flex",
      width: "100%",
      backgroundColor: props.highlight ? "rgba(14, 28, 54, 0.3)" : ""
    }}>
      <div style={{
        width: "60%",
        justifyContent: "space-between"
      }}>
        <span style={{fontSize: "80%", float: "left"}}>Round: {props.player.round}</span>
        <span style={{fontSize: "80%", float: "right"}}>Pick: {props.player.pick}</span>
        <p onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}
          style={{
          verticalAlign: "bottom",
          float: "left"
        }}><b>{props.player.Name}</b> ({props.player.Position})</p>
      </div>
    </div>
  </div>
)

const BlankHistoryCard = props => (
  <div className="container" style={{
    width: "100px",
    height: "100px",
    padding: "4px",
    flexGrow: "0",
    flexShrink: "0"
  }}>
    <div className="card" style={{
      width: "100%",
      height: "100%",
      backgroundColor: props.highlight ? "rgba(14, 28, 54, 0.3)" : ""
    }}>
      <div className="card-content" style={{
        padding: "4px"
      }}>
        <p style={{fontSize: "80%"}}>Round: {props.round}</p>
        <p style={{fontSize: "80%"}}>Pick: {props.pick}</p>
      </div>
    </div>
  </div>
)

const MobileBlankHistoryCard = props => (
  <div className="container" style={{
    padding: "4px",
    flexGrow: "0",
    flexShrink: "0"
  }}>
    <div className="card" style={{
      padding: "6px",
      margin: "0rem 0 1rem 0",
      verticalAlign: "middle",
      display: "inline-flex",
      width: "100%",
      backgroundColor: props.highlight ? "rgba(14, 28, 54, 0.3)" : ""
    }}>
      <div style={{
        width: "60%",
        justifyContent: "space-between"
      }}>
        <span style={{fontSize: "80%", float: "left"}}>Round: {props.round}</span>
        <span style={{fontSize: "80%", float: "right"}}>Pick: {props.pick}</span>
        <br  />
      </div>
    </div>
  </div>
)

const UserTeamCard = props => (
  <div className="topContainer" style={{
    width: "100%",
    display: "table",
    align: "center",
    verticalAlign: "middle"
  }}>
    <div className="topTable" style={{
      width: "100%",
      display: "table-row",
    }}>
      <div className="teamNameCell" style={{
        width: "20%",
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "center",
        backgroundColor: "#0E1C36",
        color: "white",
        borderRadius: "3px",
        padding: "0px"
      }}>
        <b>{props.pos}</b>
      </div>
      <div className="teamNameCell" style={{
        width: "80%",
        display: "table-cell",
      }}>
        <div className="card" style={{
          margin: "0px"
        }}>
          <div className="card-content" style={{
            margin: "4px",
            padding: "12px"
          }}>
            <span onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}
              style={{
              verticalAlign: "middle"
            }}>
              {props.player.Name}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const PostDraftCard = props => (
  <div className="topContainer" style={{
    width: "100%",
    display: "table",
    align: "center",
    verticalAlign: "middle"
  }}>
    <div className="topTable" style={{
      width: "100%",
      display: "table-row",
    }}>
      <div className="teamNameCell" style={{
        width: "20%",
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "center",
        backgroundColor: "#0E1C36",
        color: "white",
        borderRadius: "3px",
        padding: "0px"
      }}>
        <b>{props.counter}</b>
      </div>
      <div className="teamNameCell" style={{
        width: "80%",
        display: "table-cell",
      }}>
        <div className="card" style={{
          margin: "0px"
        }}>
          <div className="card-content" style={{
            display: "flex",
            margin: "4px",
            padding: "12px"
          }}>
            <div>
              <p onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name}</b> ({props.player.Position})</p>
              <p>Overall Pick: {props.player.overallPick} (Round: {props.player.round}; Pick: {props.player.pick})</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const EmptyTeamCard = props => (
  <div className="topContainer" style={{
    width: "100%",
    display: "table",
    align: "center",
    verticalAlign: "middle"
  }}>
    <div className="topTable" style={{
      width: "100%",
      display: "table-row",
    }}>
      <div className="teamNameCell" style={{
        width: "20%",
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "center",
        backgroundColor: "#0E1C36",
        color: "white",
        borderRadius: "3px",
        padding: "0px"
      }}>
        <b>{props.pos}</b>
      </div>
      <div className="teamNameCell" style={{
        width: "80%",
        display: "table-cell",
      }}>
        <div className="card" style={{
          margin: "0px"
        }}>
          <div className="card-content" style={{
            margin: "4px",
            padding: "12px"
          }}>
            <span style={{
              verticalAlign: "middle"
            }}>
              <b className="rosterText">EMPTY</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default class Draft extends Component {

  constructor() {
    super();
    this.state = {
      mobile: window.innerWidth < 700,
      userID: localStorage.userID,
      leagueID: window.location.href.split('/').slice(-1)[0],
      startYear: 1970,
      endYear: 2020,
      userTeamID: "",
      trackedTeam: "",
      teamsPresent: {},
      boardID: "",
      draftOrder: [],
      pickHistory: [],
      draftBoard: [],
      selectedTeamID: "",
      selectedTeamRoster: [],
      nameMapping: {},
      inverseMapping: {},
      currentPick: 0,
      nameFilter: '',
      positionsToShow: ['?'],
      expectedPositions: [],
      preferenceOrder: [],
      lastPickTime: Date.now(),
      seconds: '30',
      onTheClock: false
    }
    this.sendDraftUpdate = this.sendDraftUpdate.bind(this);
    this.checkForMatchOnLoad = this.checkForMatchOnLoad.bind(this)
    this.formatTime = this.formatTime.bind(this)
    this.startCountDown = this.startCountDown.bind(this);
    this.tick = this.tick.bind(this);
    this.checkDraftStart = this.checkDraftStart.bind(this)
    this.startDraftHandle = setInterval(this.checkDraftStart, 1000)
  }


  updateBoard = data => {
    console.log("updateBoard Function")
    if (this.state.draftOrder[data.upcomingPick] === this.state.trackedTeam) {
      console.log("This team is on the clock!")
      this.setState({
        onTheClock: true
      })
    } else {
      this.setState({
        onTheClock: false
      })
    }
    if (this.state.selectedTeamID !== data.state.draftOrder[data.state.currentPick]) {
      setTimeout(() => {
        axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/fetchDraftBoard/${data.state.leagueID}`)
          .then((response) => this.setState({
            draftBoard: response.data.draftBoard,
            pickHistory: response.data.pickHistory,
            currentPick: response.data.currentPick,
            lastPickTime: response.data.lastPickTime
          }))
          .catch((err) => console.log(err))
      }, 1000)
    } else {
      setTimeout(() => {
        axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/fetchDraftBoard/${data.state.leagueID}`)
          .then((response) => this.setState({
            draftBoard: response.data.draftBoard,
            pickHistory: response.data.pickHistory,
            currentPick: response.data.currentPick,
            lastPickTime: response.data.currentPick
          }))
          .then(() => this.fetchSelectedTeamRoster())
          .catch((err) => console.log(err))
      }, 1000)
    }
    clearInterval(intervalHandle)
    intervalHandle = null
    this.setState({
      seconds: '30'
    })
    this.startCountDown()
  };

  newTeamEntered = data => {
    var newTeamDict = this.state.teamsPresent
    newTeamDict[data.joinedTeamID] = data.socketID
    const obj = {
      teamsPresent: newTeamDict
    }
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/newTeamPresent/${this.state.leagueID}`, obj)
      .catch((err) => console.log(err))
    console.log(`${data.joinedTeamID} had joined the Room`)
    console.log(this.state.teamsPresent)
    this.setState({
      teamsPresent: newTeamDict
    })
  }

  teamExited = data => {
    this.setState({
      teamsPresent: data.teamsPresent
    })
    console.log(data.teamsPresent)
  }

  autodrafterSelected = currentState => {
    console.log('Drafting team ' + Object.keys(currentState.teamsPresent)[0])
    console.log('Tracked team '+ currentState.trackedTeam)
    if (currentState.trackedTeam === Object.keys(currentState.teamsPresent)[0]) {
      console.log('Drafting for ' + currentState.draftOrder[currentState.currentPick])
      this.autoDraft(currentState)
      this.sendDraftUpdate(currentState.currentPick + 1)
      this.setState({currentPick: currentState.currentPick + 1})
      if (this.state.currentPick === currentState.draftOrder.length) {
        alert("Draft Completed!!")
        clearInterval(intervalHandle)
        intervalHandle = null
        axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/draftCompleted/${currentState.leagueID}`)
          .catch((err) => console.log(err))
      }
    }
  }

  componentDidMount() {
    intervalHandle = null
    this.loadDraftBoard()
    socket.on("joinedRoom", this.newTeamEntered)
    socket.on("exitedRoom", this.teamExited)
    socket.on("update", this.updateBoard)
    socket.on("autodrafterSelected", this.autodrafterSelected)
  }

  componentWillUnmount() {
    var newTeamDict = this.state.teamsPresent
    delete newTeamDict[this.state.trackedTeam]
    console.log(newTeamDict)
    const obj = {
      leagueID: this.state.leagueID,
      teamsPresent: newTeamDict
    }
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/newTeamPresent/${this.state.leagueID}`, obj)
      .catch((err) => console.log(err))
    socket.emit("exitedDraftRoom", obj)
    socket.off("update");
    socket.off("joinedRoom")
    socket.off("exitedRoom")
    socket.off("autodrafterSelected")

  }

  async loadDraftBoard() {
    console.log("Loading Draft Board")
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/fetchDraftBoard/${this.state.leagueID}`)
      .then((response) => this.setState({
        boardID: response.data._id,
        startYear: response.data.startYear,
        endYear: response.data.endYear,
        draftOrder: response.data.draftOrder,
        draftBoard: response.data.draftBoard,
        expectedPositions: response.data.roster,
        pickHistory: response.data.pickHistory,
        currentPick: response.data.currentPick,
        lastPickTime: response.data.lastPickTime,
        teamsPresent: response.data.teamsPresent ? response.data.teamsPresent : {}
      }))
      .then(() => this.defineTeamMapping())
      .then(() => this.fetchSelectedTeamRoster())
      .then(() => this.checkForMatchOnLoad())
      .then(() => this.formatTime())
      .then(() => this.derivePreferenceOrder())
      .catch((err) => console.log(err))
  }

  async defineTeamMapping() {
    console.log("Defining Team Mapping")
    const obj = {
      draftOrder: this.state.draftOrder,
      userID: this.state.userID
    }
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/fetchTeamMapping/${this.state.leagueID}`, obj)
      .then((response) => this.setState({
        nameMapping: response.data.nameMapping,
        inverseMapping: response.data.inverseMapping,
        trackedTeam: response.data.userTeam
      }))
      .then(() => socket.emit("joinDraftRoom", this.state.leagueID, this.state.trackedTeam))
      .catch((err) => console.log(err))
  }

  async fetchSelectedTeamRoster() {
    console.log("Defining Team Mapping")
    var select = document.getElementById("teamSelector")
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${select.value}`)
      .then((response) => this.setState({
        selectedTeamID: select.value,
        selectedTeamRoster: response.data.playerList
      }))
      .catch((err) => console.log(err))
  }

  checkForMatchOnLoad() {
    if (this.state.draftOrder[this.state.currentPick] === this.state.trackedTeam) {
      console.log("This team is on the clock!")
      this.setState({
        onTheClock: true
      })
    }
  }

  displayDraftBoardRows() {
    if (!this.state.mobile) {
      const filteredPlayers = this.filterRemainingPlayers(this.state.positionsToShow)
      var rowArray = []
      for (const [k, val] of Object.entries(filteredPlayers)) {
        rowArray.push(<PlayerRow player={val}
          onTheClock={this.state.onTheClock}
          draftFunc={(val) => this.draftPlayer(val)}
          startYear={this.state.startYear}
          endYear={this.state.endYear}
          key={k} delKey={k}/>);
      }
      return rowArray
    } else {
      const filteredPlayers = this.filterRemainingPlayers(this.state.positionsToShow)
      var rowArray = []
      for (const [k, val] of Object.entries(filteredPlayers)) {
        rowArray.push(<MobilePlayerRow player={val}
          onTheClock={this.state.onTheClock}
          draftFunc={(val) => this.draftPlayer(val)}
          startYear={this.state.startYear}
          endYear={this.state.endYear}
          key={k} delKey={k}/>);
      }
      return rowArray
    }
  }

  displayHistoryRows() {
    if (!this.state.mobile) {
      var rowArray = []
      const uniqueTeams = new Set(Object.values(this.state.draftOrder))
      const numTeams = uniqueTeams.size
      const strtYr = this.state.startYear
      const endYr = this.state.endYear
      for (var i=0; i < (numTeams * this.state.expectedPositions.length); i++) {
        if (this.state.pickHistory) {
          if (this.state.pickHistory.length > i) {
            rowArray.push(<PickHistoryCard player={this.state.pickHistory[i]}
              startYear={strtYr}
              endYear={endYr}
              highlight={this.state.draftOrder[i] === this.state.trackedTeam}
              key={i} />);
          } else {
            const currRound = Math.floor(i / numTeams) + 1
            const currPick = (i % numTeams) + 1
            rowArray.push(<BlankHistoryCard
              round={currRound}
              pick={currPick}
              highlight={this.state.draftOrder[i] === this.state.trackedTeam}
              key={i}/>)
          }
        } else {
          const currRound = Math.floor(i / numTeams) + 1
          const currPick = (i % numTeams) + 1
          rowArray.push(<BlankHistoryCard
            round={currRound}
            pick={currPick}
            highlight={this.state.draftOrder[i] === this.state.trackedTeam}
            key={i}/>)
        }
      }
    } else {
      var rowArray = []
      const uniqueTeams = new Set(Object.values(this.state.draftOrder))
      const numTeams = uniqueTeams.size
      const strtYr = this.state.startYear
      const endYr = this.state.endYear
      for (var i=0; i < (numTeams * this.state.expectedPositions.length); i++) {
        if (this.state.pickHistory) {
          if (this.state.pickHistory.length > i) {
            rowArray.push(<MobilePickHistoryCard player={this.state.pickHistory[i]}
              startYear={strtYr}
              endYear={endYr}
              highlight={this.state.draftOrder[i] === this.state.trackedTeam}
              key={i} />);
          } else {
            const currRound = Math.floor(i / numTeams) + 1
            const currPick = (i % numTeams) + 1
            rowArray.push(<MobileBlankHistoryCard
              round={currRound}
              pick={currPick}
              highlight={this.state.draftOrder[i] === this.state.trackedTeam}
              key={i}/>)
          }
        } else {
          const currRound = Math.floor(i / numTeams) + 1
          const currPick = (i % numTeams) + 1
          rowArray.push(<MobileBlankHistoryCard
            round={currRound}
            pick={currPick}
            highlight={this.state.draftOrder[i] === this.state.trackedTeam}
            key={i}/>)
        }
      }
    }
    return rowArray
  }

  displayTeamCards() {
    if (this.state.selectedTeamRoster) {
      const strtYr = this.state.startYear
      const endYr = this.state.endYear
      var occupiedPositions = []
      var roster = this.state.selectedTeamRoster
      this.state.selectedTeamRoster.map(function(player, k) {
        occupiedPositions.push(player.currentPosition)
      })
      const bench = occupiedPositions.filter(ele => ele.includes('BE'))
      if (bench.length <= 5) {
        return this.state.expectedPositions.map(function(currPos, i) {
          if (occupiedPositions.includes(currPos)) {
            var idx = occupiedPositions.indexOf(currPos)
            return <UserTeamCard pos={currPos}
              player={roster[idx]}
              startYear={strtYr}
              endYear={endYr}
              key={i} />;
          } else {
            return <EmptyTeamCard pos={currPos} key={i} />;
          }
        })
      } else {
        const strtYr = this.state.startYear
        const endYr = this.state.endYear
        var fullPositionArray = [...this.state.expectedPositions]
        for (var z=1; z <= (bench.length - 5); z++) {
          fullPositionArray.push("BE" + String(5 + z))
        }
        return fullPositionArray.map(function(currPos, i) {
          if (occupiedPositions.includes(currPos)) {
            var idx = occupiedPositions.indexOf(currPos)
            return <UserTeamCard pos={currPos}
              player={roster[idx]}
              startYear={strtYr}
              endYear={endYr}
              key={i} />;
          } else {
            return <EmptyTeamCard pos={currPos} key={i} />;
          }
        })
      }
    } else {
      return this.state.expectedPositions.map(function(currPos, i) {
        return <EmptyTeamCard pos={currPos} key={i} />;
      })
    }
  }

  displayPostDraftCards(selectedTeam) {
    if (this.state.pickHistory.length >= this.state.draftOrder.length) {
      var counterVal = 0
      var draftCards = []
      this.state.pickHistory.map(function(currPick, i) {
        if (currPick.teamID === selectedTeam) {
          counterVal += 1
          draftCards.push(<PostDraftCard counter={counterVal} player={currPick} key={i} />)
        }
      })
      return draftCards
    }
  }

  checkDraftStart() {
    console.log(Date.now() - this.state.lastPickTime)
    if (Date.now() > this.state.lastPickTime) {
      clearInterval(this.startDraftHandle)
      this.formatTime()
      this.checkForMatchOnLoad()
    } else {
      this.setState({
        onTheClock: false
      })
      console.log('Draft has not yet started')
    }
  }

  formatTime() {
    var currTime = Date.now()
    if (this.state.lastPickTime > currTime) {
      this.setState({
        seconds: '30'
      })
    } else {
      var secSincePick = 30 - Math.round(((currTime - this.state.lastPickTime) / 1000), 0)
      if (secSincePick > 0) {
        if (secSincePick < 10) {
          this.setState({
            seconds: "0" + String(secSincePick),
          })
        } else {
          this.setState({
            seconds: String(secSincePick)
          })
        }
        this.startCountDown()
      } else if (secSincePick < 0) {
        const drafter = Object.entries(this.state.teamsPresent)[0]
        const obj = {
          draftingTeam: drafter[1],
          state: this.state
        }
        if (this.state.trackedTeam === drafter[0]) {
          socket.emit('autodraft', obj)
        }
      }
    }
  }

  startCountDown() {
    if (intervalHandle === null) {
      intervalHandle = setInterval(this.tick, 1000)
    } else {
      clearInterval(intervalHandle)
      intervalHandle = null
      this.formatTime()
    }
  }

  tick() {
    var sec = Number(this.state.seconds);
    sec = sec - 1

    this.setState({
      seconds: String(sec),
    })

    if (sec < 10) {
      this.setState({
        seconds: "0" + String(sec),
      })
    }

    if (sec <= 0) {
      console.log("Clock hit 00:00")
      clearInterval(intervalHandle);
      intervalHandle = null
      const drafterSocket = Object.values(this.state.teamsPresent)[0]
      const obj = {
        draftingTeam: drafterSocket,
        state: this.state
      }
      socket.emit('autodraft', obj)
    }
  }

  derivePreferenceOrder() {
    var expectedCopy = [...this.state.expectedPositions]
    var flexSpots = expectedCopy.filter(ele => ele.includes('FL'))
    expectedCopy = expectedCopy.filter(ele => !ele.includes('BE'))
    expectedCopy = expectedCopy.filter(ele => !ele.includes('FL'))
    var posToFill = this.randomArrayShuffle(expectedCopy)
    posToFill = posToFill.concat(flexSpots)
    this.setState({
      preferenceOrder: posToFill
    })
    console.log(posToFill)
  }

  randomArrayShuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  render() {
    const { positionsToShow } = this.state;
    const filterPositions = ['?', 'QB', 'RB', 'WR', 'TE']

    if (!this.state.mobile) {
      if (!this.state.draftBoard) {
        return <div />
      } else if (this.state.currentPick >= this.state.draftOrder.length) {
        return (
          <div>
            <div className="userTeamPicksCell" style={{
              width: "60%",
              marginLeft: "20%",
              padding: "4px"
            }}>
              <div className="card">
                <div className="card-content">
                  <h5><b>Roster</b></h5>
                  <select className="browser-default" id="teamSelector" onChange={() => this.fetchSelectedTeamRoster()}>
                    {this.generateTeamOptions()}
                  </select>
                  {this.displayPostDraftCards(this.state.selectedTeamID)}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="topContainer" style={{
              width: "100%",
              display: "table",
              align: "center",
              verticalAlign: "middle"
            }}>
              <div className="topTable" style={{
                width: "100%",
                display: "table-row",
              }}>
                <div className="userTeamPicksCell" style={{
                  width: "25%",
                  display: "table-cell",
                  padding: "4px"
                }}>
                  <div className="card">
                    <div className="card-content">
                      <h5><b>Roster</b></h5>
                      <select className="browser-default" id="teamSelector" onChange={() => this.fetchSelectedTeamRoster()}>
                        {this.generateTeamOptions()}
                      </select>
                      {this.displayTeamCards()}
                    </div>
                  </div>
                </div>
                <div className="draftContentsCell" style={{
                  width: "75%",
                  display: "table-cell"
                }}>
                  <div style={{
                    display: "inline-flex",
                    width: "70%"
                  }}>
                    <div className="card" style={{
                      width: "100%",
                      height: "200px",
                    }}>
                      <div className="card-content" style={{
                        height: "100%",
                      }}>
                        <h5><b>Pick History</b></h5>
                        <div style={{
                            width: (window.innerWidth * 0.7) * 0.7,
                            height: "100%",
                            display: "inline-flex",
                            flexWrap: "nowrap",
                            overflowX: "auto"
                        }}>
                          {this.displayHistoryRows()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{
                    display: "inline-flex",
                    width: "30%",
                  }}>
                    <div className="card" style={{
                      width: "100%",
                      height: "200px",
                      position: "fixed",
                      top: "68px",
                      zIndex: "1",
                      backgroundColor: this.state.onTheClock ? "rgba(162, 0, 33, 0.3)" : ""
                    }}>
                      <div className="card-content">
                        <h5><b>On the Clock:</b></h5>
                        <p><b>{this.state.nameMapping[this.state.draftOrder[this.state.currentPick]]}</b></p>
                        <div style={{
                          width: (window.innerWidth * 0.7) * 0.2,
                        }}>
                          <div>
                            <h3 id="timerDiv">{`0:${this.state.seconds}`}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="card">
                      <div className="card-content">
                        <header>
                          <div style={{
                            width: "60%",
                            display: "inline-flex",
                            justifyContent: "space-between",
                          }}>
                            <h5><b>Draft Board</b></h5>
                            <InputGroup>
                              <FormControl
                                placeholder="Search by Player Name"
                                id="nameSearch"
                                value={this.state.nameFilter}
                                onChange={(event) => this.handleTextChange(event)}
                              />
                            </InputGroup>

                            <div style={{
                              display: "inline-flex",
                              padding: "16px"
                            }}>
                              {filterPositions.map(p => (
                                <button
                                  key={p}
                                  className={positionsToShow.indexOf(p) > -1 ? 'Active' : ''}
                                  onClick={() => this.setPositionFilter(p)}>
                                  {p === '?' ? 'All' : p}
                                </button>
                              ))}
                            </div>
                          </div>
                        </header>

                        <table className="table highlight" style={{ marginTop: 20 }}>
                          <thead>
                            <tr>
                              <th>Name (Position)</th>
                              <th>Num Games</th>
                              <th>Total Pts</th>
                              <th>Average PPG</th>
                              <th>StDev PPG</th>
                              <th>Under 10 Pts</th>
                              <th>Over 20 Pts</th>
                              <th> </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.displayDraftBoardRows()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (!this.state.draftBoard) {
        return <div />
      } else if (this.state.currentPick >= this.state.draftOrder.length) {
        return (
          <div>
            <div className="userTeamPicksCell" style={{
              marginLeft: "10%",
              marginRight: "10%",
              padding: "4px"
            }}>
              <div className="card">
                <div className="card-content">
                  <h5><b>Roster</b></h5>
                  <select className="browser-default" id="teamSelector" onChange={() => this.fetchSelectedTeamRoster()}>
                    {this.generateTeamOptions()}
                  </select>
                  {this.displayPostDraftCards(this.state.selectedTeamID)}
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="topContainer" style={{
              width: "100%",
              display: "table",
            }}>
              <div className="card" style={{
                width: "100%",
                height: "230px",
                position: "fixed",
                top: "48px",
                zIndex: "1"
              }}>
                <div className="card-content">
                  <h5><b>On the Clock:</b></h5>
                  <p><b>{this.state.nameMapping[this.state.draftOrder[this.state.currentPick]]}</b></p>
                  <div style={{
                    width: (window.innerWidth * 0.7) * 0.2,
                  }}>
                    <div>
                      <h3 id="timerDiv">{`0:${this.state.seconds}`}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="tab" style={{
                  width: "100%",
                  position: "fixed",
                  top: "250px",
                  zIndex: "1",
                  display: "flex"
                }}>
                  <div style={{
                    width: "100%",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    justifyContent: "space-between"
                  }}>
                		<button className="tablinks" onClick={(event) => this.openTab(event, 'Board')}>Draft Board</button>
                		<button className="tablinks" onClick={(event) => this.openTab(event, 'History')}>Pick History</button>
                		<button className="tablinks" onClick={(event) => this.openTab(event, 'Roster')}>Team Rosters</button>
                  </div>
                </div>
              </div>
              <div style={{
                width: "100%",
                position: "absolute",
                top: "298px",
                zIndex: "0"
              }}>
    		        <div id="Board" className="tabcontent">
                  <div className="card">
                    <div className="card-content">
                      <header>
                        <div style={{
                          width: "60%",
                          display: "inline-flex",
                          justifyContent: "space-between",
                        }}>
                          <h5><b>Draft Board</b></h5>
                          <InputGroup>
                            <FormControl
                              placeholder="Search by Player Name"
                              id="nameSearch"
                              value={this.state.nameFilter}
                              onChange={(event) => this.handleTextChange(event)}
                            />
                          </InputGroup>

                          <div style={{
                            display: "inline-flex",
                            padding: "16px"
                          }}>
                            {filterPositions.map(p => (
                              <button
                                key={p}
                                className={positionsToShow.indexOf(p) > -1 ? 'Active' : ''}
                                onClick={() => this.setPositionFilter(p)}>
                                {p === '?' ? 'All' : p}
                              </button>
                            ))}
                          </div>
                        </div>
                      </header>

                      <table className="table highlight" style={{ marginTop: 20 }}>
                        <thead>
                          <tr>
                            <th>Name (Pos)</th>
                            <th>Avg PPG</th>
                            <th>StDev PPG</th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayDraftBoardRows()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div id="History" className="tabcontent">
                  <div className="card" style={{
                    width: "100%",
                  }}>
                    <div className="card-content">
                      <h5><b>Pick History</b></h5>
                      <div>
                        {this.displayHistoryRows()}
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Roster" className="tabcontent">
                  <div className="card">
                    <div className="card-content">
                      <h5><b>Roster</b></h5>
                      <select className="browser-default" id="teamSelector" onChange={() => this.fetchSelectedTeamRoster()}>
                        {this.generateTeamOptions()}
                      </select>
                      {this.displayTeamCards()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  draftPlayer(props) {
    this.state.draftBoard.splice(props.delKey, 1)
    const currTime = Date.now()
    const obj = {
      leagueID: this.state.leagueID,
      teamID: this.state.draftOrder[this.state.currentPick],
      draftedPlayer: props.player,
      currentPick: this.state.currentPick,
      numTeams: Object.keys(this.state.nameMapping).length,
      lastPickTime: currTime
    }
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/draftPlayer/`, obj)
      .catch((err) => console.log(err))
    this.sendDraftUpdate(obj.currentPick + 1)
    this.setState({currentPick: obj.currentPick + 1})
    if (this.state.currentPick === this.state.draftOrder.length - 1) {
      alert("Draft Completed!!")
      clearInterval(intervalHandle)
      intervalHandle = null
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/draftCompleted/${this.state.leagueID}`)
        .catch((err) => console.log(err))
    }
  }

  autoDraft(currentState) {
    const autodraftingID = this.state.draftOrder[this.state.currentPick]
    console.log('Autodrafting for ' + autodraftingID)
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${autodraftingID}`)
      .then(function(response) {
        var occupiedPositions = []
        response.data.playerList.map(function(player, k) {
          occupiedPositions.push(player.currentPosition)
        })
        const nonBenchCount = currentState.expectedPositions.filter(ele => !ele.includes('BE')).length
        const bench = occupiedPositions.filter(ele => ele.includes('BE'))
        if (occupiedPositions.length - bench.length === nonBenchCount) {
          const posList = ['QB', 'RB', 'WR', 'TE']
          const posToDraft = posList[Math.floor(Math.random() * posList.length)];
          for (const [key, val] of Object.entries(currentState.draftBoard)) {
            if (val.Position === posToDraft) {
              console.log('Drafting ' + val.Name)
              currentState.draftBoard.splice(key, 1)
              const currTime = Date.now()
              const obj = {
                leagueID: currentState.leagueID,
                teamID: currentState.draftOrder[currentState.currentPick],
                draftedPlayer: val,
                currentPick: currentState.currentPick,
                numTeams: Object.keys(currentState.nameMapping).length,
                lastPickTime: currTime
              }
              axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/draftPlayer/`, obj)
                .catch((err) => console.log(err))
              break;
            }
          }
        } else {
          for (var j = 0; j < currentState.preferenceOrder.length; j++) {
            var pos = currentState.preferenceOrder[j]
            if (occupiedPositions.includes(pos)) {
              console.log("Already drafted " + pos)
            } else {
              var posToDraft = pos.slice(0, -1)
              if (posToDraft === 'FL') {
                const posList = ['RB', 'WR', 'TE']
                posToDraft = posList[Math.floor(Math.random() * posList.length)];
              }
              for (const [key, val] of Object.entries(currentState.draftBoard)) {
                if (val.Position === posToDraft) {
                  console.log('Drafting ' + val.Name)
                  currentState.draftBoard.splice(key, 1)
                  const currTime = Date.now()
                  const obj = {
                    leagueID: currentState.leagueID,
                    teamID: currentState.draftOrder[currentState.currentPick],
                    draftedPlayer: val,
                    currentPick: currentState.currentPick,
                    numTeams: Object.keys(currentState.nameMapping).length,
                    lastPickTime: currTime
                  }
                  axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/draftPlayer/`, obj)
                    .catch((err) => console.log(err))
                  break;
                }
              }
              break;
            }
          }
        }
      })
      .catch((err) => console.log(err))
  }

  sendDraftUpdate(nextPick) {
    socket.emit('drafted', {
      state: this.state,
      upcomingPick: nextPick
    })
  }

  setPositionFilter(position) {
    if (position === '?') {
      this.setState({ positionsToShow: ['?'] });
    } else {
      this.setState({
        positionsToShow: [String(position)]
      });
    }
  };

  handleTextChange(event) {
    this.setState({
      nameFilter: event.target.value
    })
    console.log(this.state.nameFilter)
  }

  filterRemainingPlayers(positionsToShow, nameFilter) {
    var filteredPlayers = {}
    var finalFilteredPlayers = {}
    const nameFilterLower = this.state.nameFilter.toLowerCase();

    if (positionsToShow.length === 1 && positionsToShow[0] === '?') {
      filteredPlayers = this.state.draftBoard
    } else {
      for (const [key, val] of Object.entries(this.state.draftBoard)) {
        if (val.Position === positionsToShow[0]) {
          filteredPlayers[key] = val
        }
      }
    };

    if (nameFilterLower) {
      for (const [key, val] of Object.entries(filteredPlayers)) {
        const firstName = val.Name.split(' ')[0].toLowerCase()
        const lastName = val.Name.split(' ')[1].toLowerCase()
        if (firstName.startsWith(nameFilterLower)) {
          finalFilteredPlayers[key] = val
        } else if (lastName.startsWith(nameFilterLower)) {
          finalFilteredPlayers[key] = val
        }
      }
    } else {
      finalFilteredPlayers = filteredPlayers
    }

    return finalFilteredPlayers
  }

  generateTeamOptions() {
    var select = document.getElementById("teamSelector");
    var index;
    for (index in Object.values(this.state.nameMapping)) {
      if (select.options.length < Object.keys(this.state.nameMapping).length) {
        if (this.state.trackedTeam === Object.keys(this.state.nameMapping)[index]) {
          select.options[select.options.length] = new Option(Object.values(this.state.nameMapping)[index], Object.keys(this.state.nameMapping)[index], true, true);
          this.setState({
            selectedTeamID: Object.keys(this.state.nameMapping)[index],
            userTeamID: Object.keys(this.state.nameMapping)[index]
          })
        } else {
          select.options[select.options.length] = new Option(Object.values(this.state.nameMapping)[index], Object.keys(this.state.nameMapping)[index]);
        }
      }
    }
  }

  openTab(evt, tabName) {
		// Declare all variables
		var i, tabcontent, tablinks;

		// Get all elements with className="tabcontent" and hide them
		tabcontent = document.getElementsByClassName("tabcontent");
		for (i = 0; i < tabcontent.length; i++) {
			tabcontent[i].style.display = "none";
		}

		// Get all elements with className="tablinks" and remove the class "active"
		tablinks = document.getElementsByClassName("tablinks");
		for (i = 0; i < tablinks.length; i++) {
			tablinks[i].className = tablinks[i].className.replace(" active", "");
		}

		// Show the current tab, and add an "active" class to the button that opened the tab
		document.getElementById(tabName).style.display = "block";
		evt.currentTarget.className += " active";
	}

}
