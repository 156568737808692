import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "../../App.css"
import "../../styles/WaiverTable.css"

const Team = props => (
  <tr onClick = {() => window.open('/viewTeam/'+props.team._id, '_self')} style={{
    backgroundColor: props.team.userID === localStorage.userID ? "lightgrey" : "white"
  }}>
    <td>{props.team.teamName}</td>
    <td>{props.team.ownerName}</td>
    <td>{props.team.wins}-{props.team.losses}</td>
    <td>{props.team.pointsFor.toFixed(2)}</td>
    <td>{props.team.pointsAgainst.toFixed(2)}</td>
    <td>{props.team.place}</td>
  </tr>
)

const MobileTeam = props => (
  <tr onClick = {() => window.open('/viewTeam/'+props.team._id, '_self')} style={{
    backgroundColor: props.team.userID === localStorage.userID ? "lightgrey" : "white"
  }}>
    <td>{props.team.teamName}</td>
    <td>{props.team.ownerName}</td>
    <td>{props.team.wins}-{props.team.losses}</td>
    <td>{props.team.pointsFor.toFixed(2)}</td>
  </tr>
)

const DraftDatetimeCard = props => (
  <div className="card-content" style={{
    padding: "8px",
    flexGrow: "1"
  }}>
    <p>{props.element}</p>
  </div>
)

const AvailablePlayerRow = props => (
  <tr>
    <td onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name}</b> ({props.player.Position})</td>
    <td>{props.player.Num_Games}</td>
    <td>{props.player.Total_Pts.toFixed(2)}</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>{props.player.Under_10}</td>
    <td>{props.player.Over_20}</td>
  </tr>
)

const MobileAvailablePlayerRow = props => (
  <tr>
    <td onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name}</b> ({props.player.Position})</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
  </tr>
)

const WinnerCard = props => (
  <div>
    <div className="card">
      <div className="card-content" style={{
        display: "inline-flex",
        width: "100%"
      }}>
        <div style={{
          display: "inline-flex",
          justifyContent: "space-between",
          width: "100%"
        }}>
          <h4><b>Winner:</b></h4>
          <h4>{props.winner}</h4>
        </div>
      </div>
    </div>
  </div>
)

export default class LeaguePage extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      leagueID: window.location.href.split('/').slice(-1)[0],
      teams: [],
      userTeamID: "",
      matchup: [],
      schedule: [],
      currentWeek: 0,
      selectedWeek: 0,
      draftDate: "",
      draftTime: "",
      draftState: false,
      nameMapping: {},
      draftBoard: [],
      startYear: 1970,
      endYear: 2020,
      positionsToShow: '?',
      winner: ''
    };
  };

  componentDidMount() {
    this.fetchTeams()
  }

  async fetchTeams() {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagueMembers/${this.state.leagueID}`)
      .then((response) => {this.setState({ teams: response.data })})
      .then((response) => this.fetchMatchups())
      .then((response) => this.deriveNameMapping())
      .then((response) => this.fetchAvailablePlayers())
      .then((response) => this.setUserTeam())
      .catch((error) => console.log(error))
  }

  fetchMatchups() {
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagues/${this.state.leagueID}`)
      .then((response) => {this.setState({
        currentWeek: response.data.currentWeek === 17 ? 15 : response.data.currentWeek - 1,
        matchup: response.data.currentWeek === 17 ? response.data.matchups[15] : (!response.data.matchups ? ["Upcoming"] : response.data.matchups[response.data.currentWeek - 1]),
        schedule: response.data.currentWeek === 17 ? response.data.schedule[15] : (!response.data.matchups ? response.data.schedule[0] : response.data.schedule[response.data.currentWeek  - 1]),
        draftDate: response.data.draftDate,
        draftTime: response.data.draftTime,
        selectedWeek: response.data.currentWeek === 17 ? 15 : (response.data.currentWeek === 0 ? response.data.currentWeek : response.data.currentWeek - 1),
        draftState: response.data.drafted,
        winner: response.data.currentWeek === 17 ? response.data.winner : ''
       })})
      .catch((error) => console.log(error))
  }

  fetchAvailablePlayers() {
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/fetchDraftBoard/${this.state.leagueID}`)
      .then((response) => this.setState({
        draftBoard: response.data.draftBoard,
        startYear: response.data.startYear,
        endYear: response.data.endYear
      }))
      .catch((error) => console.log(error))
  }

  setUserTeam() {
    for (var i=0; i < this.state.teams.length; i++) {
      var team = this.state.teams[i]
      if (team.userID === localStorage.userID) {
        this.setState({ userTeamID: team._id })
      }
    }
  }

  updateMatchup(selectedWeek) {
    if (selectedWeek <= this.state.currentWeek) {
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagues/${this.state.leagueID}`)
        .then((response) => {this.setState({
          matchup: response.data.matchups[selectedWeek],
          schedule: response.data.schedule[selectedWeek]
         })})
        .catch((error) => console.log(error))
    } else if (selectedWeek === this.state.currentWeek + 1) {
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagues/${this.state.leagueID}`)
        .then((response) => {this.setState({
          matchup: ["Upcoming"],
          schedule: response.data.schedule[selectedWeek]
         })})
        .catch((error) => console.log(error))
    } else if (selectedWeek > this.state.currentWeek + 1) {
      alert("Week " + (selectedWeek + 1) + " has not yet been played")
      this.setState({
        selectedWeek: selectedWeek - 1
      })
    } else {
      this.setState({
        selectedWeek: 0
      })
    }
  }

  deriveNameMapping() {
    var mappingDict = {}
    this.state.teams.map(function(currTeam, i) {
      mappingDict[currTeam._id] = currTeam.teamName
    })
    this.setState({
      nameMapping: mappingDict
    })
  }

  displayWinner() {
    console.log(this.state.winner)
    if (this.state.winner !== '') {
      return <WinnerCard winner={this.state.winner} />
    }
  }

  insertDraftDetails() {
    var timeString = ""
    var hour = this.state.draftTime.split(':')[0]
    var min = this.state.draftTime.split(':')[1]
    var tempDate = new Date()
    var a = tempDate.toLocaleString("ja", {timeZone: "America/New_York"}).split(/[/\s:]/);
    a[1]--;
    const t1 = Date.UTC.apply(null, a);
    const t2 = new Date(tempDate).setMilliseconds(0);
    var nyOffset = (t2 - t1) / 60 / 1000;
    const offsetCurr = tempDate.getTimezoneOffset()
    const tzOffset = (offsetCurr - nyOffset) / 60

    var localHour = hour - tzOffset
    var switchPM = 'AM'
    if (localHour > 12) {
      localHour = localHour - 12
      switchPM = 'PM'
    }
    if (tzOffset === 3) {
      timeString = String(localHour) + ":" + min + switchPM + " PST"
    } else if (tzOffset === 2) {
      timeString = String(localHour) + ":" + min + switchPM + " MST"
    } else if (tzOffset === 1) {
      timeString = String(localHour) + ":" + min + switchPM + " CST"
    } else {
      timeString = String(localHour) + ":" + min + switchPM + " EST"
    }

    return (
      <div style={{
        paddingLeft: "24px",
        display: "flex",
        alignItems: "center",
        width: "40%"
      }}>
        <DraftDatetimeCard element={this.state.draftDate} key={0} />
        <DraftDatetimeCard element={timeString} key={1} />
      </div>
    )
  }

  displayTeams() {
    if (!this.state.mobile) {
      return this.state.teams.map(function(currentTeam, i) {
        return <Team team={currentTeam} key={i} />;
      })
    } else {
      return this.state.teams.map(function(currentTeam, i) {
        return <MobileTeam team={currentTeam} key={i} />;
      })
    }
  }

  displayMatchups(teams) {
    var results = []
    if (!this.state.draftState) {
      results.push(
        <tr key={0}>
          <td> </td>
          <td> </td>
          <td style={{textAlign: "center"}}><b>No Matchups Scheduled Until Draft Completion</b></td>
          <td> </td>
          <td> </td>
        </tr>
      )
    } else {
      if (this.state.matchup[0] === "Upcoming") {
        var teamArray = []
        for (let [team1, team2] of Object.entries(this.state.schedule)) {
          if (!teamArray.includes(team1)) {
            teamArray.push(team1)
            teamArray.push(team2)
            results.push(
              <tr key={team1}>
                <td>{this.state.nameMapping[team1]}</td>
                <td>0.0</td>
                <td> </td>
                <td style={{
                  textAlign: "right"
                }}>0.0</td>
                <td style={{
                  textAlign: "right"
                }}>{this.state.nameMapping[team2]}</td>
              </tr>
            )
          }
        }
      } else {
        var teamArray = []
        for (let [team1, team2] of Object.entries(this.state.schedule)) {
          if (!teamArray.includes(team1)) {
            teamArray.push(team1)
            teamArray.push(team2)
            results.push(
              <tr key={team1} onClick={() => window.open('/matchup/'+team1+'/'+this.state.selectedWeek, '_self')}>
                <td style={{
                  fontWeight: this.state.matchup[team1].finalScore > this.state.matchup[team2].finalScore ? "bold" : "normal"
                }}>{this.state.matchup[team1].teamName}</td>
                <td style={{
                  fontWeight: this.state.matchup[team1].finalScore > this.state.matchup[team2].finalScore ? "bold" : "normal"
                }}>{this.state.matchup[team1].finalScore.toFixed(2)}</td>
                <td> </td>
                <td style={{
                  fontWeight: this.state.matchup[team2].finalScore > this.state.matchup[team1].finalScore ? "bold" : "normal",
                  textAlign: "right"
                }}>{this.state.matchup[team2].finalScore.toFixed(2)}</td>
                <td style={{
                  fontWeight: this.state.matchup[team2].finalScore > this.state.matchup[team1].finalScore ? "bold" : "normal",
                  textAlign: "right"
                }}>{this.state.matchup[team2].teamName}</td>
              </tr>
            )
          }
        }
      }
    }
    return(results)
  }

  displayAvailablePlayers() {
    if (!this.state.mobile) {
      var filteredPlayers = this.filterRemainingPlayers(this.state.positionsToShow)
      var rowArray = []
      for (const [k, val] of Object.entries(filteredPlayers)) {
        rowArray.push(<AvailablePlayerRow player={val}
          startYear={this.state.startYear}
          endYear={this.state.endYear}
          key={k} delKey={k}/>);
      }
      return rowArray
    } else {
      var filteredPlayers = this.filterRemainingPlayers(this.state.positionsToShow)
      var rowArray = []
      for (const [k, val] of Object.entries(filteredPlayers)) {
        rowArray.push(<MobileAvailablePlayerRow player={val}
          startYear={this.state.startYear}
          endYear={this.state.endYear}
          key={k} delKey={k}/>);
      }
      return rowArray
    }
  }

  nextPage(week) {
    if (week + 1 < 16) {
      this.setState({
        selectedWeek: week + 1
      })
      this.updateMatchup(week + 1)
    }
  }

  previousPage(week) {
    if (week > 0) {
      this.setState({
        selectedWeek: week - 1
      })
      this.updateMatchup(week - 1)
    }
  }

  render() {
    const { positionsToShow } = this.state;
    const filterPositions = ['?', 'QB', 'RB', 'WR', 'TE']

    if (!this.state.matchup || !this.state.draftBoard) {
      return <div />
    }

    if (!this.state.mobile) {
      return (
        <div className="container">
          <Button onClick={() => window.open(`/dashboard`, '_self')} style={{
            marginTop: "12px",
            marginBottom: "12px",
            backgroundColor: "white",
            color: "black",
            borderRadius: "3px",
            fontSize: "16px"
          }}>
            <i><FontAwesomeIcon icon={faChevronLeft}/></i> HOME
          </Button>
          {this.displayWinner()}
          {!this.state.draftState ? (
            <div>
              <div className="card">
                <div className="card-content" style={{
                  display: "inline-flex",
                  width: "100%"
                }}>
                  <div style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}>
                    <h3>Draft Lobby</h3>
                    {this.insertDraftDetails()}
                    <button onClick={() => this.props.history.push('/draft/'+this.state.leagueID)} style={{
                      width: "150px",
                      height: "50px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem",
                      backgroundColor: "#0E1C36",
                      color: "white"}} >
                      ENTER
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )
        }

          <div className="card">
            <div className="card-content">
              <h3>Teams List</h3>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Owner</th>
                    <th>Record</th>
                    <th>Points For</th>
                    <th>Points Against</th>
                    <th>Place</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayTeams()}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <div style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%"
              }}>
                <h3>Matchups</h3>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "200px"
                }}>
                  <input type="button" id="previous" onClick={() => this.previousPage(this.state.selectedWeek)} value="<" />
                  <label style={{fontSize: "large"}}><b> Week {this.state.selectedWeek + 1} </b></label>
                  <input type="button" id="next" onClick={() => this.nextPage(this.state.selectedWeek)} value=">" />
                </div>
              </div>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Team 1</th>
                    <th>Points</th>
                    <th style={{width: "20%"}}> </th>
                    <th style={{textAlign: "right"}}>Points</th>
                    <th style={{textAlign: "right"}}>Team 2</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayMatchups(this.state.teams)}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
            <header>
              <div style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%"
              }}>
                <h3>Waiver Wire</h3>
                <button
                  id="viewButton"
                  style={{
                    width: "150px",
                    height: "50px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    backgroundColor: "#0E1C36",
                    color: "white"
                  }}
                  onClick={() => window.open(`/addPlayer/${this.state.leagueID}:${this.state.userTeamID}`, "_self")}
                >
                  VIEW
                </button>
              </div>
              <div style={{
                padding: "16px"
              }}>
                {filterPositions.map(p => (
                  <button
                    key={p}
                    className={positionsToShow.indexOf(p) > -1 ? 'Active' : ''}
                    onClick={() => this.setPositionFilter(p)}>
                    {p === '?' ? 'All' : p}
                  </button>
                ))}
              </div>
            </header>
                <table className="waiversTable highlight" style={{
                  marginTop: 20,
                }}>
                  <thead>
                    <tr>
                      <th>Name (Position)</th>
                      <th>Num Games</th>
                      <th>Total Pts</th>
                      <th>Avg PPG</th>
                      <th>StDev PPG</th>
                      <th>Under 10 Pts</th>
                      <th>Over 20 Pts</th>
                    </tr>
                  </thead>
                    <tbody>
                      {this.displayAvailablePlayers()}
                    </tbody>
                </table>
            </div>
          </div>
          {this.state.draftState ? (
            <div>
              <div className="card">
                <div className="card-content" style={{
                  display: "inline-flex",
                  width: "100%"
                }}>
                  <div style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}>
                    <h3>Draft Lobby</h3>
                    {this.insertDraftDetails()}
                    <button onClick={() => this.props.history.push('/draft/'+this.state.leagueID)} style={{
                      width: "150px",
                      height: "50px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem",
                      backgroundColor: "#0E1C36",
                      color: "white"}} >
                      ENTER
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )
        }
        </div>
      );
    } else {
      return (
        <div className="container">
          <Button onClick={() => window.open(`/dashboard`, '_self')} style={{
            marginTop: "12px",
            marginBottom: "12px",
            backgroundColor: "white",
            color: "black",
            borderRadius: "3px",
            fontSize: "16px"
          }}>
            <i><FontAwesomeIcon icon={faChevronLeft}/></i> HOME
          </Button>
          {this.displayWinner()}
          <div className="card">
            <div className="card-content">
              <h5><b>Teams List</b></h5>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Owner</th>
                    <th>Record</th>
                    <th>PF</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayTeams()}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <div style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%"
              }}>
                <h5><b>Matchups</b></h5>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "50%"
                }}>
                  <input type="button" id="previous" onClick={() => this.previousPage(this.state.selectedWeek)} value="<" />
                  <label style={{fontSize: "large"}}><b> Week {this.state.selectedWeek + 1} </b></label>
                  <input type="button" id="next" onClick={() => this.nextPage(this.state.selectedWeek)} value=">" />
                </div>
              </div>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Tm 1</th>
                    <th>Pts</th>
                    <th style={{width: "20%"}}> </th>
                    <th style={{textAlign: "right"}}>Pts</th>
                    <th style={{textAlign: "right"}}>Tm 2</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayMatchups(this.state.teams)}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <header>
                <div style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}>
                  <h5><b>Waiver Wire</b></h5>
                  <button
                    id="viewButton"
                    style={{
                      width: "120px",
                      height: "40px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem",
                      backgroundColor: "#0E1C36",
                      color: "white"
                    }}
                    onClick={() => window.open(`/addPlayer/${this.state.leagueID}:${this.state.userTeamID}`, "_self")}
                  >
                    VIEW
                  </button>
                </div>
                <div style={{
                  paddingTop: "16px"
                }}>
                  {filterPositions.map(p => (
                    <button
                      key={p}
                      className={positionsToShow.indexOf(p) > -1 ? 'Active' : ''}
                      onClick={() => this.setPositionFilter(p)}>
                      {p === '?' ? 'All' : p}
                    </button>
                  ))}
                </div>
              </header>
              <table className="waiversTableMobile highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Name (Position)</th>
                    <th>Avg PPG</th>
                    <th>StDev PPG</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayAvailablePlayers()}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div className="card">
              <div className="card-content" style={{
                display: "inline-flex",
                width: "100%"
              }}>
                <div style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}>
                  <h5><b>Draft Lobby</b></h5>
                  <button onClick={() => this.props.history.push('/draft/'+this.state.leagueID)} style={{
                    width: "120px",
                    height: "40px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    backgroundColor: "#0E1C36",
                    color: "white"}} >
                    ENTER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  setPositionFilter(position) {
    if (position === '?') {
      this.setState({ positionsToShow: ['?'] });
    } else {
      this.setState({
        positionsToShow: [String(position)]
      });
    }
  };

  filterRemainingPlayers(positionsToShow, nameFilter) {
    var filteredPlayers = {}

    if (positionsToShow.length === 1 && positionsToShow[0] === '?') {
      filteredPlayers = this.state.draftBoard
    } else {
      for (const [key, val] of Object.entries(this.state.draftBoard)) {
        if (val.Position === positionsToShow[0]) {
          filteredPlayers[key] = val
        }
      }
    };
    return filteredPlayers
  }

}
