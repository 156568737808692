import React, { Component } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";
import "../../styles/Footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faAngellist, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import coffee from "../../images/buy_me_a_coffee.png";


class Footer extends Component {

  render() {
    return (
      <div>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{display: "flex", justifyContent: "space-between"}}>
                <div className="footer-col first">
                  <h6><b>About Fantaseasons</b></h6>
                  <p className="p-small">Fantaseasons is a web, Android, and iOS ecosystem developed by Qwagga Dev LLC</p>

                  <h6><b>Other Apps</b></h6>
                  <ul className="list-unstyled li-space-lg p-small">
                    <li><a href="https://covetrealestate.app">Covet Real Estate</a></li>
                  </ul>
                </div>

                <div className="footer-col">
                  <p className="p-small" style={{textAlign: "center"}}>If you would like to support Fantaseasons:</p>
                  <button style={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    backgroundColor: "#0E1C36",
                    objectFit: "contain"
                  }}><img style={{width: "180px", height: "36px"}} src={coffee} onClick={() => window.open('https://www.buymeacoffee.com/qwaggadev', '_blank')} /></button>
                </div>

                <div className="footer-col" style={{textAlign: "right"}}>
                  <h6><b>Contact Info</b></h6>
                  <ul className="list-unstyled li-space-lg p-small">
                    <li className="media">
                      <div className="media-body">
                        <i><FontAwesomeIcon icon={faMapMarkerAlt}/></i>
                        <a href="https://qwaggadev.com"> qwaggadev.com</a>
                      </div>
                    </li>
                    <li className="media">
                      <div className="media-body">
                        <i><FontAwesomeIcon icon={faEnvelope}/></i>
                        <a href="mailto:qwaggadev@gmail.com"> qwaggadev@gmail.com</a>
                      </div>
                    </li>
                  </ul>
                  <span className="fa-stack">
                    <a href="https://angel.co/company/qwagga-dev">
                      <i><FontAwesomeIcon icon={faAngellist}/></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href="https://linkedin.com/company/qwagga-dev-llc">
                    <i><FontAwesomeIcon icon={faLinkedin}/></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href="#your-link">
                    <i><FontAwesomeIcon icon={faTwitter}/></i>
                    </a>
                  </span>
                  <div>
                    <a href="https://fantaseasons.com/privacyPolicy">Privacy Policy</a>
                  </div>
                  <div>
                    <a href="https://fantaseasons.com/termsConditions">Terms & Conditions</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="p-small">Copyright © 2021 <a href="https://qwaggadev.com">Qwagga Dev LLC</a> - All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
