import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog, faCalculator, faPeopleArrows, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import M from "materialize-css";
import { loadStripe } from '@stripe/stripe-js';

export default class EditLeagueSettings extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      userID: localStorage.userID,
      stripeCustomerId: "",
      leagueID: window.location.href.split('/').slice(-1)[0],
      league: [],
      selectedDate: "",
      selectedTime: "",
      playoffs: false,
      playoffSize: "0",
      seasonSpeed: "weekly",
      seasons: [],
      QBs: 1,
      RBs: 2,
      WRs: 2,
      TEs: 1,
      FLs: 1,
      BEs: 2,
      PassYds: 0.04,
      PassTDs: 4,
      PassInt: -2,
      RushYds: 0.1,
      RushTDs: 6,
      Rec: 0,
      RecYds: 0.1,
      RecTDs: 6,
      FumLost: -2,
      TwoPM: 2,
      PassBonus: 0,
      RushBonus: 0,
      RecBonus: 0
    };

    this.onNameChange = this.onNameChange.bind(this)
    this.onScoringChange = this.onScoringChange.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.onCustomScoring = this.onCustomScoring.bind(this)
    this.onPlayoffChange = this.onPlayoffChange.bind(this)
    this.onPlayoffSizeChange = this.onPlayoffSizeChange.bind(this)
    this.onSeasonSpeedChange = this.onSeasonSpeedChange.bind(this)
    this.onTeamNumberChange = this.onTeamNumberChange.bind(this)
    this.fetchLeagueData = this.fetchLeagueData.bind(this)
    this.addEmailRows = this.addEmailRows.bind(this)
  }

  componentDidMount() {
    M.AutoInit();
    this.fetchLeagueData()
  }

  fetchLeagueData() {
    console.log("Fetching league data")
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagues/${this.state.leagueID}`)
      .then((response) => this.setState({ league: response.data }))
      .then(() => this.pullLeagueSettings())
      .then(() => this.injectLeagueName())
      .then(() => this.addEmailRows(this.state.league.numberOfTeams))
      .then(() => this.fillDraftDatetime())
      .then(() => this.fetchStripeDetails(localStorage.userID))
  }

  pullLeagueSettings() {
    var expectedPositions = this.state.league.roster
    var QBs = 0
    var RBs = 0
    var WRs = 0
    var TEs = 0
    var FLs = 0
    var BEs = 0
    for (var i = 0; i < expectedPositions.length; i++) {
      if (expectedPositions[i].includes('QB')) {
        QBs += 1
      } else if (expectedPositions[i].includes('RB')) {
        RBs += 1
      } else if (expectedPositions[i].includes('WR')) {
        WRs += 1
      } else if (expectedPositions[i].includes('TE')) {
        TEs += 1
      } else if (expectedPositions[i].includes('FL')) {
        FLs += 1
      } else if (expectedPositions[i].includes('BE')) {
        BEs += 1
      }
    }
    this.setState({
      QBs: QBs,
      RBs: RBs,
      WRs: WRs,
      TEs: TEs,
      FLs: FLs,
      BEs: BEs,
      PassYds: this.state.league.scoringDict.PassYds,
      PassTDs: this.state.league.scoringDict.PassTDs,
      PassInt: this.state.league.scoringDict.PassInt,
      RushYds: this.state.league.scoringDict.RushYds,
      RushTDs: this.state.league.scoringDict.RushTDs,
      Rec: this.state.league.scoringDict.Rec,
      RecYds: this.state.league.scoringDict.RecYds,
      RecTDs: this.state.league.scoringDict.RecTDs,
      FumLost: this.state.league.scoringDict.FumLost,
      TwoPM: this.state.league.scoringDict.TwoPM,
      PassBonus: this.state.league.scoringDict.PassBonus,
      RushBonus: this.state.league.scoringDict.RushBonus,
      RecBonus: this.state.league.scoringDict.RecBonus,
      playoffs: this.state.league.playoffs,
      playoffSize: this.state.league.playoffSize,
      seasonSpeed: this.state.league.seasonSpeed
    })
    if (this.state.league.playoffs) {
      document.getElementById('playoffSwitch').checked = true
      document.getElementById('playoffSizeSelector').value = this.state.league.playoffSize
    }
    document.getElementById('seasonSpeedSelector').value = this.state.league.seasonSpeed
  }

  injectLeagueName() {
    document.getElementById("leagueNameField").value = this.state.league.leagueName
    document.getElementById("leagueNameButton").addEventListener('click', function(event) {
      document.getElementById('leagueNameField').removeAttribute('readonly')
      document.getElementById('leagueNameButton').style.backgroundColor = "lightgrey"
    })
  }

  async fetchStripeDetails(userID) {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/users/fetchUser/${userID}`)
    this.setState({
      stripeCustomerId: response.data.stripeCustomerId
    })
  }

  formatTime() {
    var timeString = ""
    var hour = this.state.draftTime.split(':')[0]
    var min = this.state.draftTime.split(':')[1]
    var tempDate = new Date()
    var a = tempDate.toLocaleString("ja", {timeZone: "America/New_York"}).split(/[/\s:]/);
    a[1]--;
    const t1 = Date.UTC.apply(null, a);
    const t2 = new Date(tempDate).setMilliseconds(0);
    var nyOffset = (t2 - t1) / 60 / 1000;
    const offsetCurr = tempDate.getTimezoneOffset()
    const tzOffset = (offsetCurr - nyOffset) / 60

    var localHour = hour - tzOffset
    var switchPM = 'AM'
    if (localHour > 12) {
      localHour = localHour - 12
      switchPM = 'PM'
    }
    if (tzOffset === 3) {
      timeString = String(localHour) + ":" + min + switchPM + " PST"
    } else if (tzOffset === 2) {
      timeString = String(localHour) + ":" + min + switchPM + " MST"
    } else if (tzOffset === 1) {
      timeString = String(localHour) + ":" + min + switchPM + " CST"
    } else {
      timeString = String(localHour) + ":" + min + switchPM + " EST"
    }
  }

  addEmailRows() {
    var i;
    var numTeams = Number(this.state.league.numberOfTeams);
    const joinedKeys = this.state.league.joinedEmails
    console.log(joinedKeys)
    var teamEmails = Object.values(this.state.league.memberEmails)
    var teamKeys = Object.keys(this.state.league.memberEmails)
    for (var i=1; i < numTeams; i++) {
      var outerDiv = document.createElement('div')
      var inputField = document.createElement('input')
      inputField.id = "email_" + String(i)
      inputField.value = teamEmails[i-1]
      if (joinedKeys.includes(teamKeys[i-1])) {
        inputField.style.backgroundColor = "lightgrey"
      }
      outerDiv.appendChild(inputField)
      document.getElementById('emailForm').appendChild(outerDiv)
    }
    var submitButton = document.createElement('button')
    submitButton.style.width = "150px"
    submitButton.style.height = "50px"
    submitButton.style.borderRadius = "3px"
    submitButton.style.letterSpacing = "1.5px"
    submitButton.style.backgroundColor = "#0E1C36"
    submitButton.style.color = "white"
    submitButton.innerHTML = "SUBMIT"
    submitButton.addEventListener('click', function(event) {
      var rosterArray = []
      for (var i = 0; i < document.getElementById('QBs').value; i++) {
        rosterArray.push("QB" + String(i+1))
      }
      for (var i = 0; i < document.getElementById('RBs').value; i++) {
        rosterArray.push("RB" + String(i+1))
      }
      for (var i = 0; i < document.getElementById('WRs').value; i++) {
        rosterArray.push("WR" + String(i+1))
      }
      for (var i = 0; i < document.getElementById('TEs').value; i++) {
        rosterArray.push("TE" + String(i+1))
      }
      for (var i = 0; i < document.getElementById('FLs').value; i++) {
        rosterArray.push("FL" + String(i+1))
      }
      for (var i = 0; i < document.getElementById('BEs').value; i++) {
        rosterArray.push("BE" + String(i+1))
      }

      var scoringDict = {}
      scoringDict["PassYds"] = document.getElementById('PassYds').value
      scoringDict["PassTDs"] = document.getElementById('PassTDs').value
      scoringDict["PassInt"] = document.getElementById('PassInt').value
      scoringDict["RushYds"] = document.getElementById('RushYds').value
      scoringDict["RushTDs"] = document.getElementById('RushTDs').value
      scoringDict["Rec"] = document.getElementById('Rec').value
      scoringDict["RecYds"] = document.getElementById('RecYds').value
      scoringDict["RecTDs"] = document.getElementById('RecTDs').value
      scoringDict["PassBonus"] = document.getElementById('PassBonus').value
      scoringDict["RushBonus"] = document.getElementById('RushBonus').value
      scoringDict["RecBonus"] = document.getElementById('RecBonus').value
      scoringDict["FumLost"] = document.getElementById('FumLost').value
      scoringDict["TwoPM"] = document.getElementById('2PM').value

      var emailArray = []
      for (i=1; i < numTeams; i++) {
        emailArray.push(document.getElementById('email_'+String(i)).value)
      }
      const dateStr = document.getElementById('datepicker').value
      var timeStr = document.getElementById('timepicker').value

      const dateDict = {'Jan': '01',
        'Feb': '02',
        'Mar': '03',
        'Apr': '04',
        'May': '05',
        'Jun': '06',
        'Jul': '07',
        'Aug': '08',
        'Sep': '09',
        'Oct': '10',
        'Nov': '11',
        'Dec': '12'}
      var month = dateStr.split(' ')[0]
      var day = dateStr.split(' ')[1].replace(',', '')
      var year = dateStr.split(' ')[2]
      month = dateDict[month]
      const finalDate = `${year}-${month}-${day}`

      var tempHour = 0
      if (timeStr.includes('PM')) {
        timeStr = timeStr.split(' ')[0]
        var hour = Number(timeStr.split(':')[0])
        var min = Number(timeStr.split(':')[1])
        if (min < 10) {
          min = '0' + String(min)
        }
        if (hour !== 12) {
          hour = hour + 12
          tempHour = hour
        } else {
          tempHour = hour
        }
        timeStr = `${hour}:${timeStr.split(':')[1]}:00`
      } else {
        timeStr = timeStr.split(' ')[0]
        var hour = Number(timeStr.split(':')[0])
        var min = Number(timeStr.split(':')[1])
        if (min < 10) {
          min = '0' + String(min)
        }
        if (hour === 12) {
          hour = 0
          tempHour = hour
        }
        if (hour < 10) {
          tempHour = hour
          hour = '0' + String(hour)
        } else {
          tempHour = hour
        }
      }
      var tempDate = new Date()
      var a = tempDate.toLocaleString("ja", {timeZone: "America/New_York"}).split(/[/\s:]/);
      a[1]--;
      const t1 = Date.UTC.apply(null, a);
      const t2 = new Date(tempDate).setMilliseconds(0);
      var nyOffset = (t2 - t1) / 60 / 1000;
      const offsetCurr = tempDate.getTimezoneOffset()
      const tzOffset = (offsetCurr - nyOffset) / 60
      tempHour = tempHour + tzOffset
      const finalTime = `${tempHour}:${min}:00`

      if (tempHour > 24) {
        tempHour = tempHour - 24
      }
      if (tempHour > 0 && tempHour < 4) {
        alert("Hours between 12:00 AM and 4:00 AM are reserved for maintenance")
      } else {
        var obj = {
          draftDate: dateStr,
          draftTime: finalTime,
          leagueName: document.getElementById('leagueNameField').value,
          memberEmails: emailArray,
          scoringDict: scoringDict,
          roster: rosterArray,
          playoffs: document.getElementById('playoffSwitch').checked ? true : false,
          playoffSize: document.getElementById('playoffSizeSelector').value,
          seasonSpeed: document.getElementById('seasonSpeedSelector').value,
        }
        console.log(obj)
        axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/overwriteLeague/${window.location.href.split('/').slice(-1)[0]}`, obj)
          .catch((err) => console.log(err))
        if (document.getElementById('origDate').value !== dateStr || document.getElementById('origTime').value !== finalTime) {
          var dtObj = {
            draftDate: finalDate,
            draftTime: timeStr,
          }
          console.log(dtObj)
          axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/shiftDraftDatetime/${window.location.href.split('/').slice(-1)[0]}`, dtObj)
            .catch((err) => console.log(err))
        }
        setTimeout(() => {window.open('/dashboard', '_self')}, 1000)
      }
    });
    document.getElementById('cardContainer').appendChild(submitButton)
  }

  fillDraftDatetime() {
    var timeString = ""
    var hour = this.state.league.draftTime.split(':')[0]
    var min = this.state.league.draftTime.split(':')[1]
    var tempDate = new Date()
    var a = tempDate.toLocaleString("ja", {timeZone: "America/New_York"}).split(/[/\s:]/);
    a[1]--;
    const t1 = Date.UTC.apply(null, a);
    const t2 = new Date(tempDate).setMilliseconds(0);
    var nyOffset = (t2 - t1) / 60 / 1000;
    const offsetCurr = tempDate.getTimezoneOffset()
    const tzOffset = (offsetCurr - nyOffset) / 60

    var localHour = hour - tzOffset
    var switchPM = ' AM'
    if (localHour > 12) {
      localHour = localHour - 12
      switchPM = ' PM'
    }
    if (tzOffset === 3) {
      timeString = String(localHour) + ":" + min + switchPM + " PST"
    } else if (tzOffset === 2) {
      timeString = String(localHour) + ":" + min + switchPM + " MST"
    } else if (tzOffset === 1) {
      timeString = String(localHour) + ":" + min + switchPM + " CST"
    } else {
      timeString = String(localHour) + ":" + min + switchPM + " EST"
    }

    document.getElementById("datepicker").value = this.state.league.draftDate
    document.getElementById("origDate").value = this.state.league.draftDate
    document.getElementById("timepicker").value = timeString
    document.getElementById("origTime").value = timeString

  }

  onNameChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onScoringChange = e => {
    this.setState({ scoringFormat: e.target.value });
    if (e.target.value === "STD") {
      this.setState({
        PassYds: 0.04,
        PassTDs: 4,
        PassInt: -2,
        RushYds: 0.1,
        RushTDs: 6,
        Rec: 0,
        RecYds: 0.1,
        RecTDs: 6,
        FumLost: -2,
        TwoPM: 2,
        PassBonus: 0,
        RushBonus: 0,
        RecBonus: 0
      })
    } else if (e.target.value === "PPR") {
      this.setState({
        PassYds: 0.04,
        PassTDs: 4,
        PassInt: -2,
        RushYds: 0.1,
        RushTDs: 6,
        Rec: 1,
        RecYds: 0.1,
        RecTDs: 6,
        FumLost: -2,
        TwoPM: 2,
        PassBonus: 0,
        RushBonus: 0,
        RecBonus: 0
      })
    } else if (e.target.value === "DK") {
      this.setState({
        PassYds: 0.04,
        PassTDs: 4,
        PassInt: -1,
        RushYds: 0.1,
        RushTDs: 6,
        Rec: 1,
        RecYds: 0.1,
        RecTDs: 6,
        FumLost: -1,
        TwoPM: 2,
        PassBonus: 3,
        RushBonus: 3,
        RecBonus: 3
      })
    } else if (e.target.value === "FD") {
      this.setState({
        PassYds: 0.04,
        PassTDs: 4,
        PassInt: -1,
        RushYds: 0.1,
        RushTDs: 6,
        Rec: 0.5,
        RecYds: 0.1,
        RecTDs: 6,
        FumLost: -2,
        TwoPM: 2,
        PassBonus: 0,
        RushBonus: 0,
        RecBonus: 0
      })
    }
  }

  onFieldChange = e => {
    this.setState({ [e.target.id]: Number(e.target.value) });
  };

  onCustomScoring = e => {
    this.setState({
      [e.target.id]: Number(e.target.value),
      scoringFormat: "Custom"
    });
  }

  onPlayoffChange = e => {
    if (e.target.checked) {
      this.setState({ playoffs: true });
    } else {
      this.setState({ playoffs: false });
    }
    console.log(this.state.playoffs)
  }

  onTeamNumberChange = e => {
    this.setState({ numberOfTeams: e.target.value });
  }

  onPlayoffSizeChange = e => {
    if (Number(e.target.value) <= Number(this.state.numberOfTeams)) {
      this.setState({ playoffSize: e.target.value });
    } else {
      alert("Cannot have more playoff berths than total teams")
    }
  }

  onSeasonSpeedChange = e => {
    this.setState({seasonSpeed: e.target.value });
  }

  async redirectToStripe(leagueID, customerId) {
    console.log("Redirect function running")
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB}`);

    const stripe = await stripePromise;
    const obj = {
      leagueID: leagueID,
      customerId: customerId
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/stripe/upgradeLeague`, obj)

    const result = await stripe.redirectToCheckout({
      sessionId: response.data.id,
    });
  }

  render() {
    if (!this.state.mobile) {
      return (
        <div>
          <div className="container col s10">
            <div className="card">
              <div className="card-content">
                <div className="input-field" style={{
                  display: "inline-flex",
                  width: "100%"
                }}>
                  <input readOnly="readonly" type="text" id="leagueNameField" style={{fontSize: "150%"}} />
                  <button id="leagueNameButton" style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    backgroundColor: "white",
                    border: "none"
                  }}>
                    <i className="material-icons">edit</i>
                  </button>
                </div>
                <div className="topContainer" style={{
                  width: "100%",
                  display: "table",
                  align: "center",
                  verticalAlign: "middle"
                }}>
                  <div className="topTable" style={{
                    width: "100%",
                    display: "table-row",
                    verticalAlign: "middle"
                  }}>
                    <div className="emailCell" style={{
                      width: "50%",
                      display: "table-cell",
                      padding: "16px"
                    }}>
                      <p>Enter the emails of your leaguemates below</p>
                      <br />
                      <form id="emailForm">
                      </form>
                    </div>
                    <div className="datePickerCell" style={{
                      width: "50%",
                      display: "table-cell",
                      padding: "16px",
                      float: "center"
                    }}>
                      <p>Select a date and time for your league's draft</p>
                      <br />
                      <div style={{
                        width: "50%"
                      }}>
                        <input
                          type="text"
                          className="datepicker"
                          id="datepicker"
                          placeholder="Click to Select Date"
                        >
                        </input>
                        <p id="origDate" style={{display: "block"}} />
                      </div>
                      <div style={{
                        width: "50%"
                      }}>
                        <input
                          type="text"
                          className="timepicker"
                          id="timepicker"
                          placeholder="Click to Select Time"
                        >
                        </input>
                        <p id="origTime" style={{display: "block"}} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row valign-wrapper" style={{
                  justifyContent: "center"
                }}>

                  <div id="advancedDiv" style={{width: "80%", display: this.state.league.premium ? "block" : "none"}}>
                    <ul className="collapsible">
                      <li>
                        <div className="collapsible-header"><i><FontAwesomeIcon icon={faUserCog}/></i><b>Roster Composition</b></div>
                        <div className="collapsible-body">
                          <div className="row">
                            <form className="col s12">
                              <div className="input-field col s2">
                                <input id="QBs" type="number" min="0" max="5"
                                  value={ this.state.QBs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="QBs">QB</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="RBs" type="number" min="0" max="5"
                                  value={ this.state.RBs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="RBs">RB</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="WRs" type="number" min="0" max="5"
                                  value={ this.state.WRs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="WRs">WR</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="TEs" type="number" min="0" max="5"
                                  value={ this.state.TEs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="TEs">TE</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="FLs" type="number" min="0" max="5"
                                  value={ this.state.FLs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="FLs">FL</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="BEs" type="number" min="0" max="5"
                                  value={ this.state.BEs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="BEs">BE</label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header"><i><FontAwesomeIcon icon={faCalculator}/></i><b>Scoring System</b></div>
                        <div className="collapsible-body">
                          <div className="row">
                            <form className="col s12">
                              <div className="row">
                                <p><b>Passing Stats</b></p>
                                <div className="input-field col s3">
                                  <input id="PassYds" type="number" step="0.01" min="0" max="1"
                                    value={ this.state.PassYds }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="PassYds">Passing Yds</label>
                                </div>
                                <div className="input-field col s3">
                                  <input id="PassTDs" type="number" min="0" max="10"
                                    value={ this.state.PassTDs }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="PassTDs">Passing TD</label>
                                </div>
                                <div className="input-field col s3">
                                  <input id="PassInt" type="number" min="-6" max="0"
                                    value={ this.state.PassInt }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="PassInt">Passing Int</label>
                                </div>
                              </div>
                              <div className="row">
                                <p><b>Rushing Stats</b></p>
                                <div className="input-field col s3">
                                  <input id="RushYds" type="number" step="0.02" min="0" max="1"
                                    value={ this.state.RushYds }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RushYds">Rushing Yds</label>
                                </div>
                                <div className="input-field col s3">
                                  <input id="RushTDs" type="number" min="0" max="10"
                                    value={ this.state.RushTDs }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RushTDs">Rushing TD</label>
                                </div>
                              </div>
                              <div className="row">
                                <p><b>Receiving Stats</b></p>
                                <div className="input-field col s3">
                                  <input id="Rec" type="number" step="0.25" min="0" max="2"
                                    value={ this.state.Rec }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="Rec">Reception</label>
                                </div>
                                <div className="input-field col s3">
                                  <input id="RecYds" type="number" step="0.02" min="0" max="1"
                                    value={ this.state.RecYds }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RecYds">Receiving Yds</label>
                                </div>
                                <div className="input-field col s3">
                                  <input id="RecTDs" type="number" min="0" max="10"
                                    value={ this.state.RecTDs }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RecTDs">Receiving TD</label>
                                </div>
                              </div>
                              <div className="row">
                                <p><b>Miscellaneous (All Positions)</b></p>
                                <div className="input-field col s3">
                                  <input id="FumLost" type="number" min="-6" max="0"
                                    value={ this.state.FumLost }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="FumLost">Fumble Lost</label>
                                </div>
                                <div className="input-field col s3">
                                  <input id="2PM" type="number" min="0" max="2"
                                    value={ this.state.TwoPM }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="2PM">2-Pt Conversion Made</label>
                                </div>
                              </div>
                              <div className="row">
                                <p><b>Bonuses</b></p>
                                <div className="input-field col s3">
                                  <input id="PassBonus" type="number" step="0.5" min="0" max="5"
                                    value={ this.state.PassBonus }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="PassBonus">300+ Pass Yds</label>
                                </div>
                                <div className="input-field col s3">
                                  <input id="RushBonus" type="number" step="0.5" min="0" max="5"
                                    value={ this.state.RushBonus }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RushBonus">100+ Rush Yds</label>
                                </div>
                                <div className="input-field col s3">
                                  <input id="RecBonus" type="number" step="0.5" min="0" max="5"
                                    value={ this.state.RecBonus }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RecBonus">100+ Rec Yds</label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header"><i><FontAwesomeIcon icon={faPeopleArrows}/></i><b>Playoff Option</b></div>
                        <div className="collapsible-body">
                          <div className="row">
                            <div className="col s6">
                              <div
                                className="switch"
                              >
                                <label className="col s6" style={{fontSize: "150%"}}><b>Playoffs?</b></label>
                                <label style={{fontSize: "150%"}}>
                                  Off
                                  <input
                                    type="checkbox"
                                    name="playoffSwitch"
                                    id="playoffSwitch"
                                    onChange={ this.onPlayoffChange }
                                  />
                                  <span className="lever"></span>
                                  On
                                </label>
                              </div>
                            </div>
                            <div className="col s6">
                              <select
                                className="browser-default"
                                id="playoffSizeSelector"
                                value={ this.state.playoffSize }
                                onChange={ this.onPlayoffSizeChange }
                              >
                                <option value="2">2</option>
                                <option value="4">4</option>
                                <option value="8">8</option>
                              </select>
                              <label>Number of Playoff Spots</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header"><i><FontAwesomeIcon icon={faTachometerAlt}/></i><b>Season Speed</b></div>
                        <div className="collapsible-body">
                          <div className="row">
                            <div className="col s12">
                              <select
                                className="browser-default"
                                id="seasonSpeedSelector"
                                value={ this.state.seasonSpeed }
                                onChange={ this.onSeasonSpeedChange }
                              >
                                <option value="weekly">Weekly (16 week season)</option>
                                <option value="bidaily">Every 2 days (4 week season)</option>
                                <option value="daily">Every day (2 week season)</option>
                              </select>
                              <label>Rate of Play</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <br />
                <div id="cardContainer" style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      );
    } else {
      return (
        <div>
          <div className="container col s10">
            <div className="card">
              <div className="card-content">
                <div className="input-field" style={{
                  display: "inline-flex",
                  width: "100%"
                }}>
                  <input readOnly="readonly" type="text" id="leagueNameField" style={{fontSize: "150%"}} />
                  <button id="leagueNameButton" style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    backgroundColor: "white",
                    border: "none"
                  }}>
                    <i className="material-icons">edit</i>
                  </button>
                </div>

                <div>
                  <p>Enter the emails of your leaguemates below</p>
                  <br />
                  <form id="emailForm">
                  </form>
                </div>
                <br />
                <div className="datePickerCell" style={{
                  display: "table-cell",
                  padding: "16px",
                  float: "center"
                }}>
                  <p>Select a date and time for your league's draft</p>
                  <p>(Note: All times are in EST)</p>
                  <br />
                  <div style={{
                    width: "100%"
                  }}>
                    <input
                      type="text"
                      className="datepicker"
                      id="datepicker"
                      placeholder="Click to Select Date"
                    >
                    </input>
                  </div>
                  <div style={{
                    width: "100%"
                  }}>
                    <input
                      type="text"
                      className="timepicker"
                      id="timepicker"
                      placeholder="Click to Select Time"
                    >
                    </input>
                  </div>
                </div>
              </div>

              <div>
                <div className="row valign-wrapper" style={{
                  justifyContent: "center",
                  marginLeft: "6px",
                  marginRight: "6px"
                }}>

                  <div id="premiumDiv" style={{display: !this.state.league.premium ? "block" : "none"}}>
                    <button
                      style={{
                        width: "200px",
                        height: "50px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        backgroundColor: "#0E1C36"
                      }}
                      onClick={() => this.redirectToStripe(this.state.league._id, this.state.stripeCustomerId)}
                      className="btn btn-large waves-effect hoverable accent-3"
                    >
                      MAKE PREMIUM
                    </button>
                  </div>

                  <div id="advancedDiv" style={{display: this.state.league.premium ? "block" : "none"}}>
                    <div className="card" style={{backgroundColor: "lightgrey"}}>
                      <div className="card-content">
                        <p><b>Note:</b> If you elect to change any of the advanced settings below, you will be redirected to a payment page after hitting CREATE to purchase a one-time League Pass or a Premium subscription.</p>
                      </div>
                    </div>
                    <ul className="collapsible">
                      <li>
                        <div className="collapsible-header"><i><FontAwesomeIcon icon={faUserCog}/></i><b>Roster Composition</b></div>
                        <div className="collapsible-body">
                          <div className="row">
                            <form className="col s12">
                              <div className="input-field col s2">
                                <input id="QBs" type="number" min="0" max="5"
                                  value={ this.state.QBs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="QBs">QB</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="RBs" type="number" min="0" max="5"
                                  value={ this.state.RBs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="RBs">RB</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="WRs" type="number" min="0" max="5"
                                  value={ this.state.WRs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="WRs">WR</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="TEs" type="number" min="0" max="5"
                                  value={ this.state.TEs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="TEs">TE</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="FLs" type="number" min="0" max="5"
                                  value={ this.state.FLs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="FLs">FL</label>
                              </div>
                              <div className="input-field col s2">
                                <input id="BEs" type="number" min="0" max="5"
                                  value={ this.state.BEs }
                                  onChange= { this.onFieldChange }
                                />
                                <label for="BEs">BE</label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header"><i><FontAwesomeIcon icon={faCalculator}/></i><b>Scoring System</b></div>
                        <div className="collapsible-body" style={{
                          margin: "6px",
                          padding: "0px"
                        }}>
                          <div className="row">
                            <form className="col s12">
                              <div className="row">
                                <p style={{
                                  paddingLeft: "12px"
                                }}><b>Passing Stats</b></p>
                                <div className="input-field col s4">
                                  <input id="PassYds" type="number" step="0.01" min="0" max="1"
                                    value={ this.state.PassYds }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="PassYds">Passing Yds</label>
                                </div>
                                <div className="input-field col s4">
                                  <input id="PassTDs" type="number" min="0" max="10"
                                    value={ this.state.PassTDs }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="PassTDs">Passing TD</label>
                                </div>
                                <div className="input-field col s4">
                                  <input id="PassInt" type="number" min="-6" max="0"
                                    value={ this.state.PassInt }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="PassInt">Passing Int</label>
                                </div>
                              </div>
                              <div className="row">
                                <p style={{
                                  paddingLeft: "12px"
                                }}><b>Rushing Stats</b></p>
                                <div className="input-field col s4">
                                  <input id="RushYds" type="number" step="0.02" min="0" max="1"
                                    value={ this.state.RushYds }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RushYds">Rushing Yds</label>
                                </div>
                                <div className="input-field col s4">
                                  <input id="RushTDs" type="number" min="0" max="10"
                                    value={ this.state.RushTDs }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RushTDs">Rushing TD</label>
                                </div>
                              </div>
                              <div className="row">
                                <p style={{
                                  paddingLeft: "12px"
                                }}><b>Receiving Stats</b></p>
                                <div className="input-field col s4">
                                  <input id="Rec" type="number" step="0.25" min="0" max="2"
                                    value={ this.state.Rec }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="Rec">Reception</label>
                                </div>
                                <div className="input-field col s4">
                                  <input id="RecYds" type="number" step="0.02" min="0" max="1"
                                    value={ this.state.RecYds }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RecYds">Receiving Yds</label>
                                </div>
                                <div className="input-field col s4">
                                  <input id="RecTDs" type="number" min="0" max="10"
                                    value={ this.state.RecTDs }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RecTDs">Receiving TD</label>
                                </div>
                              </div>
                              <div className="row">
                                <p style={{
                                  paddingLeft: "12px"
                                }}><b>Miscellaneous (All Positions)</b></p>
                                <div className="input-field col s4">
                                  <input id="FumLost" type="number" min="-6" max="0"
                                    value={ this.state.FumLost }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="FumLost">Fumble Lost</label>
                                </div>
                                <div className="input-field col s4">
                                  <input id="2PM" type="number" min="0" max="2"
                                    value={ this.state.TwoPM }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="2PM">2-Pt Conv</label>
                                </div>
                              </div>
                              <div className="row">
                                <p style={{
                                  paddingLeft: "12px"
                                }}><b>Bonuses</b></p>
                                <div className="input-field col s4">
                                  <input id="PassBonus" type="number" step="0.5" min="0" max="5"
                                    value={ this.state.PassBonus }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="PassBonus">300 Pass Yds</label>
                                </div>
                                <div className="input-field col s4">
                                  <input id="RushBonus" type="number" step="0.5" min="0" max="5"
                                    value={ this.state.RushBonus }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RushBonus">100 Rush Yds</label>
                                </div>
                                <div className="input-field col s4">
                                  <input id="RecBonus" type="number" step="0.5" min="0" max="5"
                                    value={ this.state.RecBonus }
                                    onChange= { this.onCustomScoring }
                                  />
                                  <label for="RecBonus">100 Rec Yds</label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header"><i><FontAwesomeIcon icon={faPeopleArrows}/></i><b>Playoff Option</b></div>
                        <div className="collapsible-body" style={{
                          margin: "6px",
                          padding: "0px"
                        }}>
                          <div className="row">
                            <div className="col s6">
                              <div
                                className="switch"
                              >
                                <div className="row" style={{
                                  margin: "1rem"
                                }}>
                                  <label className="col s6" style={{fontSize: "120%"}}><b>Playoffs?</b></label>
                                </div>
                                <div className= "row" style={{
                                  margin: "1rem"
                                }}>
                                  <label style={{fontSize: "100%"}}>
                                    Off
                                    <input
                                      type="checkbox"
                                      name="playoffSwitch"
                                      onChange={ this.onPlayoffChange }
                                    />
                                    <span className="lever" style={{
                                      marginLeft: "8px",
                                      marginRight: "8px"
                                    }}></span>
                                    On
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col s5">
                              <select
                                className="browser-default"
                                value={ this.state.playoffSize }
                                onChange={ this.onPlayoffSizeChange }
                              >
                                <option value="2">2</option>
                                <option value="4">4</option>
                                <option value="8">8</option>
                              </select>
                              <label>Number of Playoff Spots</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header"><i><FontAwesomeIcon icon={faTachometerAlt}/></i><b>Season Speed</b></div>
                        <div className="collapsible-body">
                          <div className="row">
                            <div className="col s12">
                              <select
                                className="browser-default"
                                value={ this.state.seasonSpeed }
                                onChange={ this.onSeasonSpeedChange }
                              >
                                <option value="weekly">Weekly (16 week season)</option>
                                <option value="bidaily">Every 2 days (4 week season)</option>
                                <option value="daily">Every day (2 week season)</option>
                              </select>
                              <label>Rate of Play</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="cardContainer" style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "16px"
                }}>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      );
    }
  }

}
