import React, { Component } from "react";
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';


class UpgradeCheckout extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      leagueID: window.location.href.split('/').slice(-1)[0].split(':')[0],
      stripeCustomerId: window.location.href.split('/').slice(-1)[0].split(':')[1]
    };

    this.redirectToStripe = this.redirectToStripe.bind(this)
  };

  componentDidMount() {
    this.redirectToStripe()
  }

  async redirectToStripe() {
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB}`);

    const stripe = await stripePromise;
    const obj = {
      leagueID: this.state.leagueID,
      customerId: this.state.customerId
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/stripe/checkoutSession`, obj)

    const result = await stripe.redirectToCheckout({
      sessionId: response.data.id,
    });
  }

  render() {
    return (
      <div className="container">
        <h3>Redirecting to League Upgrade</h3>
      </div>
    )
  }

}

export default UpgradeCheckout;
