import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import "../../App.css"
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { loadStripe } from '@stripe/stripe-js';


const League = props => (
  <tr>
    <td>{props.league.leagueName}</td>
    <td>{props.league.numberOfTeams}</td>
    <td>
      <button
        style={{
          width: "150px",
          borderRadius: "3px",
          letterSpacing: "1.5px",
          marginTop: "1rem",
          backgroundColor: "#0E1C36"
        }}
        onClick={ () => {window.open('/editLeagueSettings/'+props.league._id, '_self')} }
        className="btn btn-large waves-effect hoverable accent-3"
      >
        EDIT
      </button>
    </td>
  </tr>
)

class UserProfile extends Component {

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      userID: localStorage.userID,
      email: localStorage.email,
      premium: false,
      stripeCustomerId: "",
      leagues: []
    };

    this.redirectToStripe = this.redirectToStripe.bind(this)
    this.redirectToBilling = this.redirectToBilling.bind(this)
    this.cancelSubscription = this.cancelSubscription.bind(this)

  };

  componentDidMount() {
    this.fetchUserDetails(this.state.userID)
    this.fetchUserLeagues()
  }

  async fetchUserDetails(userID) {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/users/fetchUser/${userID}`)
    this.setState({
      premium: response.data.premium,
      stripeCustomerId: response.data.stripeCustomerId
    })
    console.log(response.data)
  }

  fetchUserLeagues() {
    console.log("Fetching user leagues")
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchCommissionerLeagues/${localStorage.userID}`)
      .then((response) => this.setState({ leagues: response.data }))
  }

  displayLeagues() {
    if (this.state.leagues.length === 0) {
      return (
        <tr key={0}>
          <td> </td>
          <td style={{textAlign: "center"}}><b>You are not serving as commissioner of any pre-draft Fantaseasons leagues.</b></td>
          <td> </td>
        </tr>
      );
    } else {
      return this.state.leagues.map(function(currentLeague, i) {
        return <League league={currentLeague} key={i} />;
      });
    }
  }

  async redirectToStripe() {
    const obj = {
      userID: this.state.userID,
      email: this.state.email,
      customerId: this.state.stripeCustomerId
    }

    const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUB}`)
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/stripe/subscriptionSession`, obj)
    console.log(response)

    const result = await stripe.redirectToCheckout({
      sessionId: response.data.sessionId,
    });
  }

  async cancelSubscription() {
    console.log("Canceling subscription")

    const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUB}`);
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/stripe/cancelSubscription`, {stripeCustomerId: this.state.stripeCustomerId})
      .then(res => axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/users/cancelPremium/${this.state.userID}`))
      .then(res => localStorage.setItem("premium", false))
      .then(res => this.fetchUserDetails(this.state.userID))
      .then(window.confirm("Fantaseasons Premium subscription successfully canceled"))
      .catch(err => console.log(err))
  }

  async redirectToBilling() {
    const custID = this.state.stripeCustomerId
    const response = await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/stripe/billingSession/${custID}`)
    window.open(response.data.sessionURL, '_self')
  }

  render() {

    const { user } = this.props.auth;
    if (!this.state.mobile) {
      return (
        <div className="container">
          <div style={{ height: "30vh" }} className="container valign-wrapper">
            <div className="row">
              <div className="col s12 center-align">
                <h3>
                  <b>Welcome back,</b> {user.name.split(" ")[0]}
                </h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12 center-align">
              <h4>
                <p className="flow-text grey-text text-darken-1">
                  Click on a league below to modify settings.
                </p>
              </h4>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <h3>Leagues List</h3>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>League Name</th>
                    <th>Number of Teams</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayLeagues()}
                </tbody>
              </table>
            </div>
          </div>

          <div className="center-align">
            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem",
                backgroundColor: "#0E1C36"
              }}
              onClick={this.onLogoutClick}
              className="btn btn-large waves-effect hoverable accent-3"
            >
              LOGOUT
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="container valign-wrapper">
            <div className="row">
              <div className="col s12 center-align">
                <h4>
                  <b>Welcome back,</b> {user.name.split(" ")[0]}
                </h4>
                <h5>
                  Premium Status: {this.state.premium ? 'Active' : 'Inactive'}
                </h5>
              </div>
              <div className="col s12 center-align">
                <button
                  style={{
                    width: "200px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    backgroundColor: "#0E1C36"
                  }}
                  onClick={this.state.premium ? this.cancelSubscription : this.redirectToStripe}
                  className="btn btn-large waves-effect hoverable accent-3"
                >
                  {this.state.premium ? "CANCEL PREMIUM" : "GET PREMIUM"}
                </button>
                <button
                  style={{
                    width: "200px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    backgroundColor: "#0E1C36"
                  }}
                  onClick={this.redirectToBilling}
                  className="btn btn-large waves-effect hoverable accent-3"
                >
                  MANAGE ACCOUNT
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12 center-align">
              <h4>
                <p className="flow-text grey-text text-darken-1">
                  Click on a league below to modify settings.
                </p>
              </h4>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <h3>Leagues List</h3>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>League Name</th>
                    <th>Current Week</th>
                    <th>Number of Teams</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayLeagues()}
                </tbody>
              </table>
            </div>
          </div>

          <div className="center-align">
            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem",
                backgroundColor: "#0E1C36"
              }}
              onClick={this.onLogoutClick}
              className="btn btn-large waves-effect hoverable accent-3"
            >
              LOGOUT
            </button>
          </div>
        </div>
      );
    }
  }
}

UserProfile.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(UserProfile);
