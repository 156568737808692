import React, { Component } from 'react';

export default class PositionalDropdown extends Component {

  constructor(props) {
    super(props);

    if (props.position === "QB") {
      this.state = {
        allowable: {
          pos1: "QB",
          pos2: "BE"
        },
        currentPosition: props.curr.slice(0, -1)
      }
    } else if (props.position === "RB") {
      if (props.expectedPositions.includes('FL')) {
        this.state = {
          allowable: {
            pos1: "RB",
            pos2: "FL",
            pos3: "BE"
          },
          currentPosition: props.curr.slice(0, -1)
        }
      } else {
        this.state = {
          allowable: {
            pos1: "RB",
            pos2: "BE"
          },
          currentPosition: props.curr.slice(0, -1)
        }
      }
    } else if (props.position === "WR") {
      if (props.expectedPositions.includes('FL')) {
        this.state = {
          allowable: {
            pos1: "WR",
            pos2: "FL",
            pos3: "BE"
          },
          currentPosition: props.curr.slice(0, -1)
        }
      } else {
        this.state = {
          allowable: {
            pos1: "WR",
            pos2: "BE"
          },
          currentPosition: props.curr.slice(0, -1)
        }
      }
    } else if (props.position === "TE") {
      if (props.expectedPositions.includes('FL')) {
        this.state = {
          allowable: {
            pos1: "TE",
            pos2: "FL",
            pos3: "BE"
          },
          currentPosition: props.curr.slice(0, -1)
        }
      } else {
        this.state = {
          allowable: {
            pos1: "TE",
            pos2: "BE"
          },
          currentPosition: props.curr.slice(0, -1)
        }
      }
    }
    this.generateOptions = this.generateOptions.bind(this)
  }

  componentDidMount() {
    this.generateOptions()
  }

  render() {
    return (
      <div>
        <form>
          <div className="form-group row">
            <div className="col s12" id="container">
              <select className="browser-default" id="positionSelect">
              </select>
            </div>
          </div>
        </form>
      </div>
    );
  }

  generateOptions() {
    var select = document.getElementById("positionSelect");
    var index;
    for (index in this.state.allowable) {
      if (this.state.allowable[index] === this.state.currentPosition) {
        console.log(this.state.currentPosition)
        select.options[select.options.length] = new Option(this.state.allowable[index], index, true, true);
      } else {
        select.options[select.options.length] = new Option(this.state.allowable[index], index);
      }
    }
    select.id = Math.random().toString(36).substring(2, 15)
  }
}
