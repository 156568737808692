import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "../../App.css"


const TopPortionCard = props => (
  <div className="container" style={{
    width: "100%",
    padding: "16px",
    paddingLeft: "10%",
    paddingRight: "10%"
  }}>
    <div className="topContainer" style={{
      display: "flex"
    }}>
      <div className="topTable" style={{
        width: "40%",
        textAlign: "right",
        backgroundColor: props.team1.finalScore > props.team2.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h5>{props.team1.teamName}</h5>
      </div>
      <div className="topTable" style={{
        width: "10%",
        textAlign: "center",
        backgroundColor: props.team1.finalScore > props.team2.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h5></h5>
      </div>
      <div className="topTable" style={{
        width: "10%",
        textAlign: "center",
        backgroundColor: props.team2.finalScore > props.team1.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h5></h5>
      </div>
      <div className="topTable" style={{
        width: "40%",
        textAlign: "left",
        backgroundColor: props.team2.finalScore > props.team1.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h5>{props.team2.teamName}</h5>
      </div>
    </div>
    <div className="topContainer" style={{
      display: "flex",
    }}>
      <div className="topTable" style={{
        width: "40%",
        textAlign: "right",
        paddingBottom: "8px",
        backgroundColor: props.team1.finalScore > props.team2.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h6><b>{props.team1.finalScore.toFixed(2)}</b></h6>
      </div>
      <div className="topTable" style={{
        width: "10%",
        textAlign: "center",
        backgroundColor: props.team1.finalScore > props.team2.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h6></h6>
      </div>
      <div className="topTable" style={{
        width: "10%",
        textAlign: "center",
        paddingBottom: "8px",
        backgroundColor: props.team2.finalScore > props.team1.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h6></h6>
      </div>
      <div className="topTable" style={{
        width: "40%",
        textAlign: "left",
        backgroundColor: props.team2.finalScore > props.team1.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h6><b>{props.team2.finalScore.toFixed(2)}</b></h6>
      </div>
    </div>
  </div>
)

const PlayerCard = props => (
  <div className="card" style={{
    margin: "0rem 0 1rem 0",
    padding: "6px",
    verticalAlign: "middle",
    display: "inline-flex",
    width: "100%",
    minHeight: "56px"
  }}>
    <div style={{
      width: "40%",
      justifyContent: "space-between"
    }}>
      <div className="topTable">
        <span style={{
          textSize:"50%",
          float: "right"
        }}> {props.player1.Score.toFixed(2)}</span>
        <span style={{
          textSize:"50%",
          float: "left",
          paddingLeft: "4px"
        }}><b>{props.player1.Name}</b></span>
      </div>
      <div className="card-content" style={{
          width: "80%",
          paddingTop: "2px",
          paddingBottom: "2px",
          fontSize: "80%",
          float: "left",
          textAlign: "left",
          paddingLeft: "4px"
        }}>
        {props.player1.scoringSummary}
      </div>
    </div>
    <div style={{
      width: "10%",
      display: "table-cell",
      verticalAlign: "middle",
      paddingTop: "8px",
      textAlign: "center",
      backgroundColor: "#0E1C36",
      color: "white",
      borderRadius: "3px",
      marginLeft: "5%",
      marginRight: "5%"
    }}>
      <b>{props.position}</b>
    </div>
    <div style={{
      width: "40%",
      justifyContent: "space-between"
    }}>
      <div className="topTable">
        <span style={{
          textSize:"50%",
          float: "left"
        }}> {props.player2.Score.toFixed(2)}</span>
        <span style={{
          textSize:"50%",
          float: "right",
          paddingRight: "4px"
        }}><b>{props.player2.Name}</b></span>
      </div>
      <div className="card-content" style={{
          width: "80%",
          paddingTop: "2px",
          paddingBottom: "2px",
          fontSize: "80%",
          float: "right",
          textAlign: "right",
          paddingRight: "4px"
        }}>
        {props.player2.scoringSummary}
      </div>
    </div>
  </div>
)

const BenchPlayerCard = props => (
  <div div className="benchCell" style={{
    display: "none",
    width: "100%",
    minHeight: "56px"
  }}>
    <div className="card" style={{
      backgroundColor: "lightgrey",
      padding: "6px",
      margin: "0rem 0 1rem 0",
      verticalAlign: "middle",
      display: "inline-flex",
      width: "100%"
    }}>
      <div style={{
        width: "40%",
        justifyContent: "space-between"
      }}>
        <div className="topTable">
          <span style={{
            textSize:"50%",
            float: "right"
          }}> {props.player1.Score.toFixed(2)}</span>
          <span style={{
            textSize:"50%",
            float: "left",
            paddingLeft: "4px"
          }}><b>{props.player1.Name}</b></span>
        </div>
        <div className="card-content" style={{
            width: "80%",
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "80%",
            float: "left",
            textAlign: "left",
            paddingLeft: "4px"
          }}>
          {props.player1.scoringSummary}
        </div>
      </div>
      <div style={{
        width: "10%",
        display: "table-cell",
        verticalAlign: "middle",
        paddingTop: "8px",
        textAlign: "center",
        backgroundColor: "#0E1C36",
        color: "white",
        borderRadius: "3px",
        marginLeft: "5%",
        marginRight: "5%"
      }}>
        <b>{props.position}</b>
      </div>
      <div style={{
        width: "40%",
        justifyContent: "space-between"
      }}>
        <div className="topTable">
          <span style={{
            textSize:"50%",
            float: "left"
          }}> {props.player2.Score.toFixed(2)}</span>
          <span style={{
            textSize:"50%",
            float: "right",
            paddingRight: "4px"
          }}><b>{props.player2.Name}</b></span>
        </div>
        <div className="card-content" style={{
            width: "80%",
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "80%",
            float: "right",
            textAlign: "right",
            paddingRight: "4px"
          }}>
          {props.player2.scoringSummary}
        </div>
      </div>
    </div>
  </div>
)

const MobileTopPortionCard = props => (
  <div className="container" style={{
    width: "100%",
    padding: "16px",
  }}>
    <div className="topContainer" style={{
      display: "flex"
    }}>
      <div className="topTable" style={{
        width: "40%",
        textAlign: "right",
        backgroundColor: props.team1.finalScore > props.team2.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h5>{props.team1.teamName}</h5>
      </div>
      <div className="topTable" style={{
        width: "10%",
        textAlign: "center",
        backgroundColor: props.team1.finalScore > props.team2.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h5></h5>
      </div>
      <div className="topTable" style={{
        width: "10%",
        textAlign: "center",
        backgroundColor: props.team2.finalScore > props.team1.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h5></h5>
      </div>
      <div className="topTable" style={{
        width: "40%",
        textAlign: "left",
        backgroundColor: props.team2.finalScore > props.team1.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h5>{props.team2.teamName}</h5>
      </div>
    </div>
    <div className="topContainer" style={{
      display: "flex",
    }}>
      <div className="topTable" style={{
        width: "40%",
        textAlign: "right",
        paddingBottom: "8px",
        backgroundColor: props.team1.finalScore > props.team2.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h6><b>{props.team1.finalScore.toFixed(2)}</b></h6>
      </div>
      <div className="topTable" style={{
        width: "10%",
        textAlign: "center",
        backgroundColor: props.team1.finalScore > props.team2.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h6></h6>
      </div>
      <div className="topTable" style={{
        width: "10%",
        textAlign: "center",
        paddingBottom: "8px",
        backgroundColor: props.team2.finalScore > props.team1.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h6></h6>
      </div>
      <div className="topTable" style={{
        width: "40%",
        textAlign: "left",
        backgroundColor: props.team2.finalScore > props.team1.finalScore ? "rgba(162, 0, 33, 0.3)" : "white"
      }}>
        <h6><b>{props.team2.finalScore.toFixed(2)}</b></h6>
      </div>
    </div>
  </div>
)

const MobilePlayerCard = props => (
  <div>
    <div className="card" style={{
      margin: "0rem 0 1rem 0",
      height: "40px",
      verticalAlign: "middle",
      display: "inline-flex",
      width: "100%"
    }}>
      <div style={{
        width: "40%",
        justifyContent: "space-between"
      }}>
        <div className="topTable">
          <span style={{
            textSize:"50%",
            float: "right"
          }}> {props.player1.Score.toFixed(2)}</span>
          <span style={{
            textSize:"50%",
            float: "left",
            paddingLeft: "4px"
          }}><b>{props.player1.Name.includes(' ') ? props.player1.Name.split(' ')[0][0] + '. ' + props.player1.Name.split(' ')[1] : props.player1.Name}</b></span>
        </div>
      </div>
      <div style={{
        width: "10%",
        display: "table-cell",
        verticalAlign: "middle",
        paddingTop: "8px",
        textAlign: "center",
        backgroundColor: "#0E1C36",
        color: "white",
        borderRadius: "3px",
        marginLeft: "5%",
        marginRight: "5%"
      }}>
        <b>{props.position}</b>
      </div>
      <div style={{
        width: "40%",
        justifyContent: "space-between"
      }}>
        <div className="topTable">
          <span style={{
            textSize:"50%",
            float: "left"
          }}> {props.player2.Score.toFixed(2)}</span>
          <span style={{
            textSize:"50%",
            float: "right",
            paddingRight: "4px"
          }}><b>{props.player2.Name.includes(' ') ? props.player2.Name.split(' ')[0][0] + '. ' + props.player2.Name.split(' ')[1]: props.player2.Name}</b></span>
        </div>
      </div>
    </div>
  </div>
)

const MobileBenchPlayerCard = props => (
  <div div className="benchCell" style={{
    display: "none",
    width: "100%"
  }}>
    <div className="card" style={{
      backgroundColor: "lightgrey",
      margin: "0rem 0 1rem 0",
      height: "40px",
      verticalAlign: "middle",
      display: "inline-flex",
      width: "100%"
    }}>
      <div style={{
        width: "40%",
        justifyContent: "space-between"
      }}>
        <div className="topTable">
          <span style={{
            textSize:"50%",
            float: "right"
          }}> {props.player1.Score.toFixed(2)}</span>
          <span style={{
            textSize:"50%",
            float: "left",
            paddingLeft: "4px"
          }}><b>{props.player1.Name.includes(' ') ? props.player1.Name.split(' ')[0][0] + '. ' + props.player1.Name.split(' ')[1] : props.player1.Name}</b></span>
        </div>
      </div>
      <div style={{
        width: "10%",
        display: "table-cell",
        verticalAlign: "middle",
        paddingTop: "8px",
        textAlign: "center",
        backgroundColor: "#0E1C36",
        color: "white",
        borderRadius: "3px",
        marginLeft: "5%",
        marginRight: "5%"
      }}>
        <b>{props.position}</b>
      </div>
      <div style={{
        width: "40%",
        justifyContent: "space-between"
      }}>
        <div className="topTable">
          <span style={{
            textSize:"50%",
            float: "left"
          }}> {props.player2.Score.toFixed(2)}</span>
          <span style={{
            textSize:"50%",
            float: "right",
            paddingRight: "4px"
          }}><b>{props.player2.Name.includes(' ') ? props.player2.Name.split(' ')[0][0] + '. ' + props.player2.Name.split(' ')[1] : props.player2.Name}</b></span>
        </div>
      </div>
    </div>
  </div>
)

const ExpandButton = props => (
  <div style={{
    paddingLeft: "10%",
    paddingRight: "10%"
  }}>
    <button
      className="expandRetract"
      id="expandRetract"
      onClick={() => props.action()}
      style={{
        borderRadius: "3px",
        letterSpacing: "1.5px",
        marginTop: "1rem",
        marginBottom: "1rem",
        backgroundColor: "#0E1C36",
        color: "white",
        float: "center",
        width: "100%",
        padding: "16px"
    }}>
      EXPAND
    </button>
  </div>
)

export default class MatchupPage extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      leagueID: "",
      teamID: window.location.href.split('/').slice(-2)[0],
      currentWeek: 0,
      selectedWeek: window.location.href.split('/').slice(-1)[0],
      schedule: [],
      matchups: null,
      expectedPositions: [],
      benchPresent: false
    };
    this.expandRetractButton = this.expandRetractButton.bind(this)
  }

  componentDidMount() {
    const obj = {
      teamID: this.state.teamID
    };
    this.fetchUserTeams(obj)
  }

  async fetchUserTeams(obj) {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchSingleTeam/${obj.teamID}`)
      .then((response) => {this.setState({ leagueID: response.data.leagueID })})
      .then((response) => this.fetchMatchupResults(this.state.leagueID))
      .catch((error) => {console.log(error)})
  }


  fetchMatchupResults(leagueID) {
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagues/${leagueID}`)
      .then((response) => {this.setState({
        currentWeek: response.data.currentWeek,
        schedule: response.data.schedule,
        matchups: response.data.matchups,
        expectedPositions: response.data.roster
      })})
      .catch((error) => console.log(error))
  }

  displayTeamHeader(teamID) {
    const opponentID = this.state.schedule[this.state.selectedWeek][teamID]
    if (!this.state.mobile) {
      return <TopPortionCard team1={this.state.matchups[this.state.selectedWeek][teamID]} team2={this.state.matchups[this.state.selectedWeek][opponentID]} />;
    } else {
      return <MobileTopPortionCard team1={this.state.matchups[this.state.selectedWeek][teamID]} team2={this.state.matchups[this.state.selectedWeek][opponentID]} />;
    }
  }

  displayPlayerCards(teamID) {
    if (!this.state.mobile) {
      var benchPresent = false
      const opponentID = this.state.schedule[this.state.selectedWeek][teamID]
      var labelOrder = this.state.expectedPositions
      const bench = labelOrder.filter(ele => ele.includes('BE'))
      const nonBench = labelOrder.length - bench.length
      for (var q = 0; q < nonBench; q++) {
        labelOrder.push('BE' + String(bench.length + q + 1))
      }
      var playerCardList = []
      for (var i=0; i < labelOrder.length; i++) {
        var label = labelOrder[i]
        var player1 = this.state.matchups[this.state.selectedWeek][teamID][label]
        var player2 = this.state.matchups[this.state.selectedWeek][opponentID][label]
        if (player1 && player2) {
          if (!label.includes("BE")) {
            playerCardList.push(<PlayerCard player1={player1} player2={player2} position={label} key={label}/>)
          } else {
            playerCardList.push(<BenchPlayerCard player1={player1} player2={player2} position={label} key={label}/>)
            benchPresent = true
          }
        } else if (player1 && !player2) {
          player2 = {
            Name: "EMPTY",
            scoringSummary: " ",
            Score: 0.0
          }
          if (!label.includes("BE")) {
            playerCardList.push(<PlayerCard player1={player1} player2={player2} position={label} key={label}/>)
          } else {
            playerCardList.push(<BenchPlayerCard player1={player1} player2={player2} position={label} key={label}/>)
            benchPresent = true
          }
        } else if (!player1 && player2) {
          player1 = {
            Name: "EMPTY",
            scoringSummary: " ",
            Score: 0.0
          }
          if (!label.includes("BE")) {
            playerCardList.push(<PlayerCard player1={player1} player2={player2} position={label} key={label}/>)
          } else {
            playerCardList.push(<BenchPlayerCard player1={player1} player2={player2} position={label} key={label}/>)
            benchPresent = true
          }
        } else {
          player1 = {
            Name: "EMPTY",
            scoringSummary: " ",
            Score: 0.0
          }
          player2 = {
            Name: "EMPTY",
            scoringSummary: " ",
            Score: 0.0
          }
          if (!label.includes("BE")) {
            playerCardList.push(<PlayerCard player1={player1} player2={player2} position={label} key={label}/>)
          }
        }
      }
    } else {
      const opponentID = this.state.schedule[this.state.selectedWeek][teamID]
      var labelOrder = this.state.expectedPositions
      const bench = labelOrder.filter(ele => ele.includes('BE'))
      const nonBench = labelOrder.length - bench.length
      for (var q = 0; q < nonBench; q++) {
        labelOrder.push('BE' + String(bench.length + q + 1))
      }
      var playerCardList = []
      for (var i=0; i < labelOrder.length; i++) {
        var label = labelOrder[i]
        var player1 = this.state.matchups[this.state.selectedWeek][teamID][label]
        var player2 = this.state.matchups[this.state.selectedWeek][opponentID][label]
        if (player1 && player2) {
          if (!label.includes("BE")) {
            playerCardList.push(<MobilePlayerCard player1={player1} player2={player2} position={label} key={label}/>)
          } else {
            playerCardList.push(<MobileBenchPlayerCard player1={player1} player2={player2} position={label} key={label}/>)
            benchPresent = true
          }
        } else if (player1 && !player2) {
          player2 = {
            Name: "EMPTY",
            scoringSummary: " ",
            Score: 0.0
          }
          if (!label.includes("BE")) {
            playerCardList.push(<MobilePlayerCard player1={player1} player2={player2} position={label} key={label}/>)
          } else {
            playerCardList.push(<MobileBenchPlayerCard player1={player1} player2={player2} position={label} key={label}/>)
            benchPresent = true
          }
        } else if (!player1 && player2) {
          player1 = {
            Name: "EMPTY",
            scoringSummary: " ",
            Score: 0.0
          }
          if (!label.includes("BE")) {
            playerCardList.push(<MobilePlayerCard player1={player1} player2={player2} position={label} key={label}/>)
          } else {
            playerCardList.push(<MobileBenchPlayerCard player1={player1} player2={player2} position={label} key={label}/>)
            benchPresent = true
          }
        } else {
          player1 = {
            Name: "EMPTY",
            scoringSummary: " ",
            Score: 0.0
          }
          player2 = {
            Name: "EMPTY",
            scoringSummary: " ",
            Score: 0.0
          }
          if (!label.includes("BE")) {
            playerCardList.push(<MobilePlayerCard player1={player1} player2={player2} position={label} key={label}/>)
          }
        }
      }
    }
    if (benchPresent) {
      playerCardList.push(<ExpandButton action={() => this.expandRetractButton()} />)
    }
    return playerCardList
  }

  expandRetractButton() {
    var buttonStatus = document.getElementById('expandRetract')
    if (buttonStatus.innerHTML === "EXPAND") {
      var ele = document.getElementsByClassName('benchCell');
      for (var i = 0; i < ele.length; i++ ) {
          ele[i].style.display = "block";
      }
      document.getElementById('expandRetract').innerHTML = "RETRACT"
    } else if (buttonStatus.innerHTML === "RETRACT") {
      var elem = document.getElementsByClassName('benchCell');
      for (var j = 0; j < elem.length; j++ ) {
          elem[j].style.display = "none";
      }
      document.getElementById('expandRetract').innerHTML = "EXPAND"
    } else {
      var ele = document.getElementsByClassName('benchCell');
      for (var i = 0; i < ele.length; i++ ) {
          ele[i].style.display = "block";
      }
      document.getElementById('expandRetract').innerHTML = "RETRACT"
    }
  }

  render() {
    if (!this.state.matchups) {
      return <div />
    }
    if (!this.state.mobile) {
      return (
        <div className="container col s12" style={{
          width: "100%"
        }}>
          <Button onClick={() => window.open(`/viewLeague/${this.state.leagueID}`, '_self')} style={{
            marginLeft: "12px",
            marginTop: "12px",
            marginBottom: "12px",
            backgroundColor: "white",
            color: "black",
            borderRadius: "3px",
            fontSize: "16px"
          }}>
            <i><FontAwesomeIcon icon={faChevronLeft}/></i> LEAGUE
          </Button>
          <h3 style={{
            textAlign: "center"
          }}>Week {Number(this.state.selectedWeek) + 1} Recap</h3>
          {this.displayTeamHeader(window.location.href.split('/').slice(-2)[0])}
          <div style={{
            width: "100%",
            display: "inline-flex",
          }}>
            <div style={{
              paddingLeft: "10%",
              paddingRight: "10%"
            }}>
              {this.displayPlayerCards(window.location.href.split('/').slice(-2)[0])}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container col s12" style={{
          width: "100%"
        }}>
          <Button onClick={() => window.open(`/viewLeague/${this.state.leagueID}`, '_self')} style={{
            marginLeft: "12px",
            marginTop: "12px",
            marginBottom: "12px",
            backgroundColor: "white",
            color: "black",
            borderRadius: "3px",
            fontSize: "16px"
          }}>
            <i><FontAwesomeIcon icon={faChevronLeft}/></i> LEAGUE
          </Button>
          <h4 style={{
            textAlign: "center"
          }}>Week {Number(this.state.selectedWeek) + 1} Recap</h4>
          {this.displayTeamHeader(window.location.href.split('/').slice(-2)[0])}
          <div style={{
            width: "100%",
            display: "inline-flex",
          }}>
            <div style={{
              width: "100%",
              paddingLeft: "8px",
              paddingRight: "8px"
            }}>
              {this.displayPlayerCards(window.location.href.split('/').slice(-2)[0])}
            </div>
          </div>
        </div>
      );
    }
  }

}
