import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../App.css"
import axios from 'axios';
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

const Team = props => (
  <tr onClick = {() => (props.team.playerList === undefined) ? alert("This team has not been drafted yet") : window.open('/viewTeam/'+props.team._id, '_self')}>
    <td>{props.team.teamName}</td>
    <td>{props.team.wins}-{props.team.losses}</td>
    <td>{props.team.pointsFor.toFixed(2)}</td>
    <td>{props.team.pointsAgainst.toFixed(2)}</td>
    <td>{props.team.place}</td>
  </tr>
)

const MobileTeam = props => (
  <tr onClick = {() => (props.team.playerList === undefined) ? alert("This team has not been drafted yet") : window.open('/viewTeam/'+props.team._id, '_self')}>
    <td>{props.team.teamName}</td>
    <td>{props.team.wins}-{props.team.losses}</td>
    <td>{props.team.pointsFor.toFixed(2)}</td>
    <td>{props.team.place}</td>
  </tr>
)

const League = props => (
  <tr onClick = {() => window.open('/viewLeague/'+props.league._id, '_self')}>
    <td>{props.league.leagueName}</td>
    <td>{props.team.teamName}</td>
    <td>{props.league.currentWeek >= 16 ? window.innerWidth < 700 ? "X" : "Completed": props.league.currentWeek + 1}</td>
  </tr>
)

class Dashboard extends Component {

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      userID: localStorage.userID,
      leagues: [],
      teams: []
    };
  };

  componentDidMount() {
    const obj = {
        userID: this.state.userID
    };
    this.fetchUserTeams(obj)
  }

  async fetchUserTeams(obj) {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/fetchTeams/${obj.userID}`)
      .then((response) => {this.setState({ teams: response.data })})
      .then((response) => this.fetchUserLeagues(this.state.teams))
      .catch((error) => {console.log(error)})
  }

  fetchUserLeagues(teamArray) {
    console.log("Fetching user leagues")
    var leagueArray = [];
    for (var i=0; i < teamArray.length; i++) {
      axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagues/${teamArray[i].leagueID}`)
        .then((res) => {
          if (res.data.active || res.data.completed) {
            leagueArray.push(res.data)
          }
        })
        .then(() => this.setState({leagues: leagueArray}))
    }
  }

  displayLeagues() {
    if (this.state.leagues.length === 0) {
      return (
        <tr key={0}>
          <td> </td>
          <td style={{textAlign: "center"}}><b>You haven't joined<br />or created any leagues yet!<br />Give it a try <a href="/createLeague">here!</a></b></td>
          <td> </td>
        </tr>
      );
    } else {
      var leagueArray = []
      for (var i=0; i < this.state.leagues.length; i++) {
        var currentLeague = this.state.leagues[i]
        for (var j=0; j < this.state.teams.length; j++) {
          if (this.state.teams[j].leagueID === currentLeague._id) {
            leagueArray.push(<League league={currentLeague} team={this.state.teams[j]} key={i} />)
          }
        }
      }
      return leagueArray
    }
  }

  render() {
    const { user } = this.props.auth;
    localStorage.setItem("userName", user.name.split(" ")[0])

    if (!this.state.mobile) {
      return (
        <div className="container">
          <div className="container valign-wrapper">
            <div className="row">
              <div className="col s12 center-align">
                <h4>
                  <b>Welcome back,</b> {user.name.split(" ")[0]}
                  <p className="flow-text grey-text text-darken-1">
                    Explore your Fantaseasons Leagues and Teams
                  </p>
                </h4>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <h3>Leagues List</h3>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>League Name</th>
                    <th>Team Name</th>
                    <th>Current Week</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayLeagues(this.state.teams)}
                </tbody>
              </table>
            </div>
          </div>

          <div className="center-align">
            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem",
                backgroundColor: "#0E1C36"
              }}
              onClick={this.onLogoutClick}
              className="btn btn-large waves-effect hoverable accent-3"
            >
              Logout
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="container valign-wrapper" style={{
            margin: "12px"
          }}>
            <div className="row">
              <div className="col s12 center-align">
                <h5>
                  <b>Welcome back,</b> {user.name.split(" ")[0]}
                  <p className="flow-text grey-text text-darken-1">
                    Explore your Fantaseasons Leagues and Teams
                  </p>
                </h5>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <h5><b>Leagues List</b></h5>
              <table className="table highlight" style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>League Name</th>
                    <th>Team Name</th>
                    <th>Week</th>
                  </tr>
                </thead>
                <tbody>
                  {this.displayLeagues()}
                </tbody>
              </table>
              <p style={{
                marginTop: "2rem",
                fontSize: "10px",
                textAlign: "center"
              }}>X in the Week column indicates a completed season</p>
            </div>
          </div>

          <div className="center-align">
            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem",
                backgroundColor: "#0E1C36"
              }}
              onClick={this.onLogoutClick}
              className="btn btn-large waves-effect hoverable accent-3"
            >
              Logout
            </button>
          </div>
        </div>
      );
    }
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Dashboard);
