import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import "../../App.css"

const TeamStats = props => (
  <div className="container col s10">
    <div className="card" style={{
      align: "center"
    }}>
      <div className="card-content" style={{
        verticalAlign: "middle"
      }}>
        <div className="topContainer" style={{
          width: "100%",
          display: "table",
          align: "center",
          verticalAlign: "middle"
        }}>
          <div className="topTable" style={{
            width: "100%",
            display: "table-row",
            verticalAlign: "middle"
          }}>
            <div className="teamNameCell" style={{
              width: "50%",
              display: "table-cell"
            }}>
              <div className="input-field" style={{
                display: "inline-flex",
                width: "100%"
              }}>
                <input readOnly="readonly" type="text" id="teamNameField" defaultValue={props.team.teamName} style={{fontSize: "150%"}} />
              </div>
            </div>
            <div className="editButtonCell" id="editButtonCell" style={{
              display: "table-cell flex",
              width: "100%",
              float: "right",
              paddingLeft: "16px"
            }}>
              <div style={{
                display: "flex",
                justifyContent: "space-evenly"
              }}>
                <button
                  id="tradeButton"
                  style={{
                    width: "250px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    backgroundColor: "#0E1C36",
                    display: (localStorage.userID === props.team.userID) ? "none" : "inline-block"
                  }}
                  onClick={() => window.open('/tradePlayers/' + props.team.teamID, '_self')}
                  className="btn btn-large waves-effect hoverable accent-3"
                >
                  PROPOSE TRADE
                </button>
                <button
                  id="editButton"
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    backgroundColor: "#0E1C36",
                    display: (localStorage.userID !== props.team.userID) ? "none" : "block"
                  }}
                  onClick={() => window.open('/editTeam/' + window.location.href.split('/').slice(-1)[0], '_self')}
                  className="btn btn-large waves-effect hoverable accent-3"
                >
                  EDIT
                </button>
                <button
                  id="addButton"
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1px",
                    backgroundColor: "#0E1C36",
                    display: (localStorage.userID !== props.team.userID) ? "none" : "block"
                  }}
                  onClick={() => window.open(`/addPlayer/${props.team.leagueID}:${props.team.teamID}`, '_self')}
                  className="btn btn-large waves-effect hoverable accent-3"
                >
                  ADD/DROP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="teamContainer" style={{
          display: "table",
          width: "100%",
          align: "center"
        }}>
          <div className="teamRow" style={{
            display: "table-row"
          }}>
            <div className="recordCell" style={{
              display: "table-cell",
              width: "50%",
              padding: "8px",
              align: "left"
            }}>
              <div className="card">
                <div className="card-content">
                  <span style={{
                    float: "left",
                    textSize: "150%"
                  }}><b>Record: </b>{props.team.wins}-{props.team.losses}</span>
                  <span style={{
                    float: "right",
                    textSize: "150%"
                  }}><b>Place: </b>{props.team.place}</span>
                  <br />
                </div>
              </div>
            </div>
            <div className="pointsCell" style={{
              display: "table-cell",
              width: "50%",
              padding: "8px",
              align: "right"
            }}>
              <div className="card">
                <div className="card-content">
                  <span style={{
                    float: "left",
                    textSize: "150%"
                  }}><b>Points For: </b>{props.team.pointsFor.toFixed(2)}</span>
                  <span style={{
                    float: "right",
                    textSize: "150%"
                  }}><b>Points Against: </b>{props.team.pointsAgainst.toFixed(2)}</span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const MobileTeamStats = props => (
  <div className="container col s10">
    <div className="card" style={{
      align: "center"
    }}>
      <div className="card-content" style={{
        verticalAlign: "middle"
      }}>
        <input readOnly="readonly" type="text" id="teamNameField" defaultValue={props.team.teamName} style={{fontSize: "150%"}} />
      </div>
      <div className="editButtonCell" id="editButtonCell" style={{
        display: "table-cell flex",
        width: "100%",
        float: "right",
        padding: "16px"
      }}>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
          <button
            id="tradeButton"
            style={{
              width: "250px",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              backgroundColor: "#0E1C36",
              display: (localStorage.userID === props.team.userID) ? "none" : "block"
            }}
            onClick={() => window.open('/tradePlayers/' + props.team.teamID, '_self')}
            className="btn btn-large waves-effect hoverable accent-3"
          >
            PROPOSE TRADE
          </button>
          <button
            id="editButton"
            style={{
              width: "150px",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              backgroundColor: "#0E1C36",
              display: (localStorage.userID !== props.team.userID) ? "none" : "block"
            }}
            onClick={() => window.open('/editTeam/' + window.location.href.split('/').slice(-1)[0], '_self')}
            className="btn btn-large waves-effect hoverable accent-3"
          >
            EDIT
          </button>
          <button
            id="addButton"
            style={{
              width: "150px",
              borderRadius: "3px",
              letterSpacing: "1px",
              backgroundColor: "#0E1C36",
              display: (localStorage.userID !== props.team.userID) ? "none" : "block"
            }}
            onClick={() => window.open(`/addPlayer/${props.team.leagueID}:${props.team.teamID}`, '_self')}
            className="btn btn-large waves-effect hoverable accent-3"
          >
            ADD/DROP
          </button>
        </div>
      </div>
      <div style={{
        padding: "16px"
      }}>
        <div className="teamContainer" style={{
          display: "table",
          width: "100%",
          align: "center"
        }}>
          <div className="card">
            <div className="card-content" style={{
              padding: "16px"
            }}>
              <span style={{
                float: "left",
                textSize: "150%"
              }}><b>Record: </b>{props.team.wins}-{props.team.losses}</span>
              <span style={{
                float: "right",
                textSize: "150%"
              }}><b>Place: </b>{props.team.place}</span>
              <br />
            </div>
          </div>
          <div className="card">
            <div className="card-content" style={{
              padding: "16px"
            }}>
              <span style={{
                float: "left",
                textSize: "150%"
              }}><b>Pts For: </b>{props.team.pointsFor.toFixed(2)}</span>
              <span style={{
                float: "right",
                textSize: "150%"
              }}><b>Pts Against: </b>{props.team.pointsAgainst.toFixed(2)}</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const PlayerTableRow = props => (
  <tr onClick={() => window.open('/player/' + props.player.Stub + ':1970:2020', '_blank')} style={{
    backgroundColor: props.player.currentPosition.includes("BE") ? "lightgrey" : "white"
  }}>
    <td>{props.player.currentPosition}</td>
    <td><b>{props.player.Name}</b></td>
    <td>{props.player.Num_Games}</td>
    <td>{props.player.Total_Pts.toFixed(2)}</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>{props.player.Under_10}</td>
    <td>{props.player.Over_20}</td>
  </tr>
)

const MobilePlayerTableRow = props => (
  <tr onClick={() => window.open('/player/' + props.player.Stub + ':1970:2020', '_blank')} style={{
    backgroundColor: props.player.currentPosition.includes("BE") ? "lightgrey" : "white"
  }}>
    <td>{props.player.currentPosition}</td>
    <td><b>{props.player.Name.split(' ')[0][0] + '. ' + props.player.Name.split(' ')[1]}</b></td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
  </tr>
)

const BlankRow = props => (
  <tr style={{
    backgroundColor: "lightgrey"
  }}>
    <td>{props.currentPosition}</td>
    <td><b>EMPTY</b></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
)

const MobileBlankRow = props => (
  <tr style={{
    backgroundColor: "lightgrey"
  }}>
    <td>{props.currentPosition}</td>
    <td><b>EMPTY</b></td>
    <td></td>
  </tr>
)

const PlayerPerformanceRow = props => (
  <tr style={{
    backgroundColor: props.game.currentPosition.includes("BE") ? "lightgrey" : "white"
  }}>
    <td><b>{props.game.Name}</b> ({props.game.Position})</td>
    <td>{props.game.Year}</td>
    <td>{props.game.Week}</td>
    <td>{props.game.Passing_Yds}</td>
    <td>{props.game.Passing_TD}</td>
    <td>{props.game.Passing_Int}</td>
    <td>{props.game.Receiving_Rec}</td>
    <td>{props.game.Receiving_Yds}</td>
    <td>{props.game.Receiving_TD}</td>
    <td>{props.game.Rushing_Yds}</td>
    <td>{props.game.Rushing_TD}</td>
    <td>{props.game.Fumbles_FL}</td>
    <td><b>{props.game.Fantasy_Pts}</b></td>
  </tr>
)

const MobilePlayerPerformanceRow = props => (
  <tr style={{
    backgroundColor: props.game.currentPosition.includes("BE") ? "lightgrey" : "white"
  }}>
    <td><b>{props.game.Name.split(' ')[0][0] + '. ' + props.game.Name.split(' ')[1]}</b> ({props.game.Position})</td>
    <td>{props.game.Year}</td>
    <td>{props.game.Week}</td>
    <td><b>{props.game.Fantasy_Pts}</b></td>
  </tr>
)

const TradeProposalHeader = props => (
  <div style={{
    padding: "6px"
  }}>
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: window.innerWidth < 700 ? "4px" : "24px",
      paddingRight: window.innerWidth < 700 ? "4px" : "24px",
      verticalAlign: "middle"
    }}>
      <p style={{
        width:"60%",
      }}><b>{props.proposal.proposedBy === props.userID ? "Proposed to " + props.proposal.partnerName : "Proposed by " + props.proposal.partnerName}</b></p>
      <Button
        style={{
          width: window.innerWidth < 700 ? "100px" : "120px",
          padding: "0px",
          textAlign: "center",
          borderRadius: "3px",
          marginTop: "0rem",
          backgroundColor: "#0E1C36"
        }}
        onClick={ () => {window.open(`/tradeReview/${props.proposal.teamID}:${props.proposal.tradeID}`, '_self')} }
        className="btn btn-large waves-effect hoverable accent-3">
        DETAILS
      </Button>
    </div>
  </div>
)

export default class ViewTeamPage extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      teamID: "",
      leagueID: "",
      userID: "",
      teamName: "",
      playerList: [],
      wins: 0,
      losses: 0,
      pointsFor: 0,
      pointsAgainst: 0,
      place: 0,
      expectedPositions: [],
      weeks: [],
      selectedWeek: 0,
      currentWeek: 0,
      tradeProposals: []
    };

    this.revealTradeProposals = this.revealTradeProposals.bind(this)

  }

  componentDidMount() {
    const obj = {
      teamID: window.location.href.split('/').slice(-1)[0]
    };
    this.fetchTeam(obj)
  }

  async fetchTeam(obj) {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/editTeam/${obj.teamID}`)
      .then((response) => this.setState({
        teamID: response.data._id,
        leagueID: response.data.leagueID,
        userID: response.data.userID,
        teamName: response.data.teamName,
        playerList: response.data.playerList,
        wins: response.data.wins,
        losses: response.data.losses,
        pointsFor: response.data.pointsFor,
        pointsAgainst: response.data.pointsAgainst,
        place: response.data.place,
        expectedPositions: response.data.expectedPositions,
        tradeProposals: response.data.tradeProposals ? response.data.tradeProposals : []
      }))
      .then((response) => this.fetchWeekPerformance())
      .catch(err => console.log(err))
  }

  fetchWeekPerformance() {
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/weeks/fetchWeeks/${window.location.href.split('/').slice(-1)[0]}`)
      .then((response) => this.setState({
        weeks: response.data[0].weeks,
        currentWeek: response.data[0].currentWeek
      }))
      .catch(err => console.log(err))
  }

  displayTeamStats() {
    if (!this.state.mobile) {
      return <TeamStats team={this.state} />;
    } else {
      return <MobileTeamStats team={this.state} />;
    }
  }

  displayPlayerTableRows() {
    if (this.state.playerList) {
      var newPlayerList = [...this.state.playerList]

      if (this.state.playerList) {
        var occupiedPositions = []
        var roster = this.state.playerList
        roster.map(function(player, k) {
          occupiedPositions.push(player.currentPosition)
        })
        const bench = occupiedPositions.filter(ele => ele.includes('BE'))
        var fullPositionArray = [...this.state.expectedPositions]
        fullPositionArray = fullPositionArray.filter(ele => !ele.includes('BE'))
        if (bench.length !== 0) {
          for (var z = 0; z < bench.length; z++) {
            fullPositionArray.push('BE' + String(z + 1))
          }
        }
        if (!this.state.mobile) {
          return fullPositionArray.map(function(currPos, i) {
            if (occupiedPositions.includes(currPos)) {
              var idx = occupiedPositions.indexOf(currPos)
              return <PlayerTableRow player={roster[idx]} key={i} />;
            } else {
              return <BlankRow currentPosition={currPos} key={i} />;
            }
          })
        } else {
          return fullPositionArray.map(function(currPos, i) {
            if (occupiedPositions.includes(currPos)) {
              var idx = occupiedPositions.indexOf(currPos)
              return <MobilePlayerTableRow player={roster[idx]} key={i} />;
            } else {
              return <MobileBlankRow currentPosition={currPos} key={i} />;
            }
          })
        }
      } else {
        if (!this.state.mobile) {
          return this.state.expectedPositions.map(function(currPos, i) {
            return <BlankRow currentPosition={currPos} key={i} />;
          })
        } else {
          return this.state.expectedPositions.map(function(currPos, i) {
            return <MobileBlankRow currentPosition={currPos} key={i} />;
          })
        }
      }
    }
  }

  displayProposalCards() {
    var proposals = []
    for (var i = 0; i < this.state.tradeProposals.length; i++) {
      var proposal = this.state.tradeProposals[i]
      proposals.push(<TradeProposalHeader proposal={proposal} userID={this.state.userID} key={i} />)
    }
    return proposals
  }

  revealTradeProposals(event) {
    event.preventDefault();
    document.getElementById('trades').style.display = "block"
  }

  displayPlayerPerformance(weekID) {
    var gameEntries = this.state.weeks[(weekID - 1)]
    var labelOrder = this.state.expectedPositions
    const bench = labelOrder.filter(ele => ele.includes('BE'))
    const nonBench = labelOrder.length - bench.length
    for (var q = 0; q < nonBench; q++) {
      labelOrder.push('BE' + String(bench.length + q + 1))
    }
    if (!this.state.mobile) {
      return Object.values(gameEntries).sort(function(a, b) {
        return labelOrder.indexOf(a.currentPosition) - labelOrder.indexOf(b.currentPosition)
      }).map(function(currentGame, j) {
        if (currentGame instanceof Object) {
          return <PlayerPerformanceRow game={currentGame} key={j} />;
        }
      });
    } else {
      return Object.values(gameEntries).sort(function(a, b) {
        return labelOrder.indexOf(a.currentPosition) - labelOrder.indexOf(b.currentPosition)
      }).map(function(currentGame, j) {
        if (currentGame instanceof Object) {
          return <MobilePlayerPerformanceRow game={currentGame} key={j} />;
        }
      });
    }
  }

  getTableForWeekCode(isOverall, playerListObject, weekID) {
    if (!this.state.mobile) {
      if (this.state.expectedPositions.length === 0) {
        return (
          <div />
        )
      } else {
        if (isOverall === 0) {
          return (
            <table>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Name</th>
                  <th>Num Games</th>
                  <th>Total Pts</th>
                  <th>Avg Pts</th>
                  <th>StDev Pts</th>
                  <th>Under 10</th>
                  <th>Over 20</th>
                </tr>
              </thead>
              <tbody>
                {this.displayPlayerTableRows(playerListObject)}
              </tbody>
            </table>
          );
        }
        return (
          <table>
            <thead>
              <tr>
                <th>Name (Pos)</th>
                <th>Year</th>
                <th>Week</th>
                <th>Pass Yds</th>
                <th>Pass TD</th>
                <th>Pass Int</th>
                <th>Rec Rec</th>
                <th>Rec Yds</th>
                <th>Rec TD</th>
                <th>Rush Yds</th>
                <th>Rush TD</th>
                <th>FL</th>
                <th>Total Pts</th>
              </tr>
            </thead>
            <tbody>
              {this.displayPlayerPerformance(weekID)}
            </tbody>
          </table>
        );
      }
    } else {
      if (this.state.expectedPositions.length === 0) {
        return (
          <div />
        )
      } else {
        if (isOverall === 0) {
          return (
            <table>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Name</th>
                  <th>Avg Pts</th>
                </tr>
              </thead>
              <tbody>
                {this.displayPlayerTableRows(playerListObject)}
              </tbody>
            </table>
          );
        }
        return (
          <table>
            <thead>
              <tr>
                <th>Name (Pos)</th>
                <th>Year</th>
                <th>Week</th>
                <th>Total Pts</th>
              </tr>
            </thead>
            <tbody>
              {this.displayPlayerPerformance(weekID)}
            </tbody>
          </table>
        );
      }
    }
  }

  previousPage(week) {
    if ((week - 1) >= 0) {
      if ((week - 1) === 0) {
        document.getElementById("weekSelectLabel").innerHTML = "Overall"
        document.getElementById("weekSelectLabel").style.fontWeight = "bold"
        this.getTableForWeekCode(this.state.selectedWeek - 1, this.state.playerList, (week - 1))
        document.getElementById("scoreLabel").innerHTML = ""
      } else {
        document.getElementById("weekSelectLabel").innerHTML = "Week " + (week - 1)
        document.getElementById("weekSelectLabel").style.fontWeight = "bold"
        this.getTableForWeekCode(this.state.selectedWeek - 1, this.state.playerList, (week - 1))
        document.getElementById("scoreLabel").innerHTML = "Score: " + this.state.weeks[this.state.selectedWeek - 2].totalPts
        document.getElementById("scoreLabel").style.fontWeight = "bold"
      }
      this.setState({
        selectedWeek: week - 1
      })
    }
  }

  nextPage(week) {
    console.log(this.state.weeks)
    console.log(this.state.selectedWeek)
    if ((week + 1) <= this.state.currentWeek) {
      if ((week + 1) === 0) {
        document.getElementById("weekSelectLabel").innerHTML = "Overall"
        document.getElementById("weekSelectLabel").style.fontWeight = "bold"
        this.getTableForWeekCode(this.state.selectedWeek + 1, this.state.playerList, (week + 1))
        document.getElementById("scoreLabel").innerHTML = ""
      } else {
        document.getElementById("weekSelectLabel").innerHTML = "Week " + (week + 1)
        document.getElementById("weekSelectLabel").style.fontWeight = "bold"
        this.getTableForWeekCode(this.state.selectedWeek + 1, this.state.playerList, (week + 1))
        document.getElementById("scoreLabel").innerHTML = "Score: " + this.state.weeks[this.state.selectedWeek].totalPts
        document.getElementById("scoreLabel").style.fontWeight = "bold"
      }
      this.setState({
        selectedWeek: week + 1
      })
    } else {
      alert("Week " + (week + 1) + " has not yet been played")
    }
  }

  render() {
    return (
      <div>
        <div className="container col s10">
          <Button onClick={() => window.open(`/viewLeague/${this.state.leagueID}`, '_self')} style={{
            marginTop: "12px",
            marginBottom: "12px",
            backgroundColor: "white",
            color: "black",
            borderRadius: "3px",
            fontSize: "16px"
          }}>
            <i><FontAwesomeIcon icon={faChevronLeft}/></i> League
          </Button>
        </div>
        {this.displayTeamStats()}
        {this.state.tradeProposals.length !== 0 ? (
          <div className="container col s10">
            <ul className="collapsible">
              <li>
                <div className="collapsible-header" onClick={(event) => this.revealTradeProposals(event)}>
                  <Button
                    style={{
                      backgroundColor: "#A20021",
                      borderRadius: "12px"
                    }}>
                    <b>{this.state.tradeProposals.length}</b>
                  </Button>
                  <div style={{paddingLeft: "24px"}}>
                    <h6><b>Active Trade Proposals</b></h6>
                  </div>
                </div>
                <div className="collapsible-body" id="trades" style={{
                  display: "none",
                  padding: "12px"
                }}>
                  {this.displayProposalCards()}
                </div>
              </li>
            </ul>
          </div>
          ) : (
            <div />
          )
        }
        <div className="container">
          <div className="playerContainer" style={{
            display: "table",
            width: "100%",
            align: "center"
          }}>
            <div className="playerRow" style={{
              display: "table-row"
            }}>
              <div style={{
                display: "table-cell",
                textAlign: "center",
                padding: "4px",
              }}>
                <div className="card">
                  <div className="card-content">
                    <div style={{
                      display: "flex",
                      width: "100%"
                    }}>
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "200px"
                      }}>
                        <input type="button" id="previous" onClick={() => this.previousPage(this.state.selectedWeek)} value="<" />
                        <label id="weekSelectLabel" style={{fontSize: "large"}}><b> Overall </b></label>
                        <input type="button" id="next" onClick={() => this.nextPage(this.state.selectedWeek)} value=">" />
                      </div>
                      <div style={{
                        alignItems: "right",
                        width: "100%"
                      }}>
                        <label id="scoreLabel" style={{
                          fontSize: "large",
                          float: "right"
                        }}></label>
                      </div>
                    </div>
                    <br />
                    <div>
                      {this.getTableForWeekCode(this.state.selectedWeek, this.state.playerList, this.state.selectedWeek)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }



}
