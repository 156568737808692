import React, { Component } from 'react';
import Landing from './Landing';

export default class HomePage extends Component {

	constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700
    };
  }

	render() {
		if (!this.state.mobile) {
			return (
				<div>
					<div className="container">
						<br />
						<div className="row">
							<div className="col m6 grey-text text-darken-1">
								<h1>Fantaseasons</h1>
								<p>
									Fantaseasons offers an opportunity to relive the glory days of fantasy football seasons past.
								</p>
								<h3>The Premise</h3>
								<p>
									Select a range of seasons and draft a team from the players active during that time. During the fantasy season, a random week from each player's games during the selected range is chosen and the associated stats are used to calculate the team's score. The rest is just as you've always known!
								</p>
							</div>
							<div className="col m6">
								<div className="card">
									<Landing />
								</div>
							</div>
						</div>

						<hr />
						<div className="row">
							<div className="col m3 grey-text text-darken-1">
								<h3>League Options</h3>
								<br />
							</div>
							<div className="col m9 grey-text text-darken-1">
								<h4>Available Players</h4>
								<p>
									Your league can select any range of years between 1970 and 2020. Want to replicate a real season featuring your favorite players? Feel free to select a single year. Want the option to pit Fran Tarkenton and Antonio Brown against Tom Brady and Jerry Rice? Use the full range!
								</p>
								<h4>Number of Teams</h4>
								<p>
									Start a league with as few as 6 teams or as many as 12 (even numbers only). There are plenty of players to select from no matter the year range and number of teams.
								</p>
								<h4>Scoring Format</h4>
								<p>
									We support several popular scoring formats, including Standard, PPR, Draftkings, and FanDuel. Select your favorite and your stats will be calculated accordingly each week.
								</p>
							</div>
							<div className="col m3 grey-text text-darken-1">
								<h3>Advanced Options</h3>
								<br />
							</div>
							<div className="col m9 grey-text text-darken-1">
								<h4>Playoff Structure</h4>
								<p>
									Given the historical nature of these leagues, some may decide to forego end-of-season playoffs and simply use season record to determine the winner (with total points as the tie-breaker). Otherwise, the final two or three weeks of the season can be treated as a playoff tournament to decide to season's victor!
								</p>
								<h4>Custom Scoring</h4>
								<p>
									Want to add a little extra spice to the season with custom scoring? This feature enables fractional points per reception, various achievement bonuses, or alternative values for touchdowns or yardage.
								</p>
								<h4>Roster Composition</h4>
								<p>
									The default roster contains 1 QB, 2 RB, 2 WR, 1 TE, and 1 Flex with 2 bench slots. This options enables alternative roster compositions like 2 QB leagues, more than one flex, or no bench slots.
								</p>
								<h4>Speedy Seasons</h4>
								<p>
									Want to cram a 16 game season into a shorter time span? You can choose to play a matchup every other day (month long season) or even once a day (two week long season)! 
								</p>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="container">
						<br />
						<div className="row">
							<div className="col m6 grey-text text-darken-1">
								<h2>Fantaseasons</h2>
								<p>
									With the 2020 NFL season in doubt, Fantaseasons offers an opportunity to relive the glory days of fantasy football seasons past.
								</p>
								<h4>The Premise</h4>
								<p>
									Select a range of seasons and draft a team from the players active during that time. During the fantasy season, a random week from each player's games during the selected range is chosen and the associated stats are used to calculate the team's score. The rest is just as you've always known!
								</p>
							</div>
							<div className="col m6">
								<div className="card">
									<Landing />
								</div>
							</div>
						</div>

						<hr />
						<div className="row">
							<div className="col m3 grey-text text-darken-1">
								<h4>League Options</h4>
								<br />
							</div>
							<div className="col m9 grey-text text-darken-1">
								<h5>Available Players</h5>
								<p>
									Your league can select any range of years between 1970 and 2019. Want to replicate a real season featuring your favorite players? Feel free to select a single year. Want the option to pit Fran Tarkenton and Antonio Brown against Tom Brady and Jerry Rice? Use the full range!
								</p>
								<h5>Number of Teams</h5>
								<p>
									Start a league with as few as 6 teams or as many as 16 (even numbers only). There are plenty of players to select from no matter the year range and number of teams.
								</p>
								<h5>Scoring Format</h5>
								<p>
									We support several popular scoring formats, including Standard, PPR, Draftkings, and FanDuel. Select your favorite and your stats will be calculated accordingly each week.
								</p>
							</div>
							<div className="col m3 grey-text text-darken-1">
								<h4>Advanced Options<br />(Coming Soon)</h4>
								<br />
							</div>
							<div className="col m9 grey-text text-darken-1">
								<h5>Playoff Structure</h5>
								<p>
									Given the historical nature of these leagues, some may decide to forego end-of-season playoffs and simply use season record to determine the winner (with total points as the tie-breaker). Otherwise, the final two or three weeks of the season can be treated as a playoff tournament to decide to season's victor!
								</p>
								<h5>Custom Scoring</h5>
								<p>
									Want to add a little extra spice to the season with custom scoring? This feature enables fractional points per reception, various achievement bonuses, or alternative values for touchdowns or yardage.
								</p>
								<h5>Roster Composition</h5>
								<p>
									The default roster contains 1 QB, 2 RB, 3 WR, 1 TE, and 1 Flex with 5 bench slots. This options enables alternative roster compositions like 2 QB leagues, more than one flex, or no bench slots.
								</p>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
