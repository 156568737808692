import React, { Component } from 'react';
import PositionalDropdown from '../form-elements/PositionalDropdown'
import "../../App.css"
import axios from 'axios';

const TeamStats = props => (
  <div className="container col s10">
    <div className="card" style={{
      align: "center"
    }}>
      <div className="card-content" style={{
        verticalAlign: "middle"
      }}>
        <div className="topContainer" style={{
          width: "100%",
          display: "table",
          align: "center",
          verticalAlign: "middle"
        }}>
          <div className="topTable" style={{
            width: "100%",
            display: "table-row",
            verticalAlign: "middle"
          }}>
            <div className="teamNameCell" style={{
              width: "50%",
              display: "table-cell"
            }}>
              <div className="input-field" style={{
                display: "inline-flex",
                width: "100%"
              }}>
                <input readOnly="readonly" type="text" id="teamNameField" defaultValue={props.team.teamName} style={{fontSize: "150%"}} />
                <button id="teamNameButton" onClick={() => props.clickEdit()} style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  backgroundColor: "white",
                  border: "none"
                }}>
                  <i className="material-icons">edit</i>
                </button>
              </div>
            </div>
            <div className="saveButtonCell" style={{
              display: "table-cell flex",
              width: "100%",
              float: "right",
              paddingLeft: "16px"
            }}>
              <div style={{
                display: "flex",
                justifyContent: "space-evenly"
              }}>
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    backgroundColor: "#0E1C36",
                  }}
                  onClick={() => window.open('/viewTeam/' + props.team.teamID, '_self')}
                  className="btn btn-large waves-effect hoverable accent-3"
                >
                  BACK
                </button>
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    backgroundColor: "#0E1C36",
                  }}
                  onClick={() => props.clickSave()}
                  className="btn btn-large waves-effect hoverable accent-3"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="teamContainer" style={{
          display: "table",
          width: "100%",
          align: "center"
        }}>
          <div className="teamRow" style={{
            display: "table-row"
          }}>
            <div className="recordCell" style={{
              display: "table-cell",
              width: "50%",
              padding: "8px",
              align: "left"
            }}>
              <div className="card">
                <div className="card-content">
                  <span style={{
                    float: "left",
                    textSize: "150%"
                  }}><b>Record: </b>{props.team.wins}-{props.team.losses}</span>
                  <span style={{
                    float: "right",
                    textSize: "150%"
                  }}><b>Place: </b>{props.team.place}</span>
                  <br />
                </div>
              </div>
            </div>
            <div className="pointsCell" style={{
              display: "table-cell",
              width: "50%",
              padding: "8px",
              align: "right"
            }}>
              <div className="card">
                <div className="card-content">
                  <span style={{
                    float: "left",
                    textSize: "150%"
                  }}><b>Points For: </b>{props.team.pointsFor.toFixed(2)}</span>
                  <span style={{
                    float: "right",
                    textSize: "150%"
                  }}><b>Points Against: </b>{props.team.pointsAgainst.toFixed(2)}</span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const MobileTeamStats = props => (
  <div className="container col s10">
    <div className="card" style={{
      align: "center"
    }}>
      <div className="card-content" style={{
        verticalAlign: "middle"
      }}>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
          <input readOnly="readonly" type="text" id="teamNameField" defaultValue={props.team.teamName} style={{fontSize: "150%"}} />
          <button id="teamNameButton" onClick={() => props.clickEdit()} style={{
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundColor: "white",
            border: "none"
          }}>
            <i className="material-icons">edit</i>
          </button>
        </div>
      </div>
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "16px"
      }}>
        <button
          style={{
            width: "150px",
            borderRadius: "3px",
            letterSpacing: "1.5px",
            backgroundColor: "#0E1C36",
          }}
          onClick={() => window.open('/viewTeam/' + props.team.teamID, '_self')}
          className="btn btn-large waves-effect hoverable accent-3"
        >
          BACK
        </button>
        <button
          style={{
            width: "150px",
            borderRadius: "3px",
            letterSpacing: "1.5px",
            backgroundColor: "#0E1C36",
          }}
          onClick={() => props.clickSave()}
          className="btn btn-large waves-effect hoverable accent-3"
        >
          SAVE
        </button>
      </div>

      <div style={{
        padding: "16px"
      }}>
        <div className="teamContainer" style={{
          display: "table",
          width: "100%",
          align: "center"
        }}>
          <div className="card">
            <div className="card-content" style={{
              padding: "16px"
            }}>
              <span style={{
                float: "left",
                textSize: "150%",
              }}><b>Record: </b>{props.team.wins}-{props.team.losses}</span>
              <span style={{
                float: "right",
                textSize: "150%",
              }}><b>Place: </b>{props.team.place}</span>
              <br />
            </div>
          </div>
          <div className="card">
            <div className="card-content" style={{
              padding: "16px"
            }}>
              <span style={{
                float: "left",
                textSize: "150%",
              }}><b>Pts For: </b>{props.team.pointsFor.toFixed(2)}</span>
              <span style={{
                float: "right",
                textSize: "150%",
              }}><b>Pts Against: </b>{props.team.pointsAgainst.toFixed(2)}</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)


const PlayerTableRow = props => (
  <tr style={{
    backgroundColor: props.player.currentPosition.includes("BE") ? "lightgrey" : "white"
  }}>
    <td><PositionalDropdown expectedPositions={props.strippedPos} position={props.player.Position} curr={props.player.currentPosition} /></td>
    <td><b>{props.player.Name}</b> ({props.player.Position})</td>
    <td>{props.player.Num_Games}</td>
    <td>{props.player.Total_Pts.toFixed(2)}</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.Under_10}</td>
    <td>{props.player.Over_20}</td>
  </tr>
)

const MobilePlayerTableRow = props => (
  <tr style={{
    backgroundColor: props.player.currentPosition.includes("BE") ? "lightgrey" : "white"
  }}>
    <td><PositionalDropdown expectedPositions={props.strippedPos} position={props.player.Position} curr={props.player.currentPosition} /></td>
    <td><b>{props.player.Name.split(' ')[0][0] + '. ' + props.player.Name.split(' ')[1]}</b> ({props.player.Position})</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
  </tr>
)

const EmptyTableRow = props => (
  <tr style={{
    backgroundColor: "grey"
  }}>
    <td><b>{props.missing}</b></td>
    <td>EMPTY</td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
    <td> </td>
  </tr>
)

const MobileEmptyTableRow = props => (
  <tr style={{
    backgroundColor: "grey"
  }}>
    <td><b>{props.missing}</b></td>
    <td>EMPTY</td>
    <td> </td>
  </tr>
)

export default class EditTeamPage extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      teamID: "",
      leagueID: "",
      userID: "",
      teamName: "",
      playerList: [],
      wins: 0,
      losses: 0,
      pointsFor: 0,
      pointsAgainst: 0,
      place: 0,
      expectedPositions: []
    };

    this.onTeamSave = this.onTeamSave.bind(this)
    this.onNameEdit = this.onNameEdit.bind(this)
  }

  componentDidMount() {
    const obj = {
      teamID: window.location.href.split('/').slice(-1)[0]
    };
    this.fetchTeam(obj)
      .then(() => this.reorderPlayerList())
      .then(() => console.log(this.state.playerList))
  }

  onTeamSave() {
    console.log("Saving Team")
    var currPosArray = []
    const QBmax = this.state.expectedPositions.filter(ele => ele.includes('QB')).length
    const RBmax = this.state.expectedPositions.filter(ele => ele.includes('RB')).length
    const WRmax = this.state.expectedPositions.filter(ele => ele.includes('WR')).length
    const TEmax = this.state.expectedPositions.filter(ele => ele.includes('TE')).length
    const FLmax = this.state.expectedPositions.filter(ele => ele.includes('FL')).length
    const BEmax = this.state.expectedPositions.filter(ele => ele.includes('BE')).length
    var QBcounter = 0;
    var RBcounter = 0;
    var WRcounter = 0;
    var TEcounter = 0;
    var FLcounter = 0;
    var BEcounter = 0;
    const positions = document.getElementsByClassName("browser-default")
    Array.from(positions).forEach(function (element) {
      const newPos = element[element.options.selectedIndex].text
      var currPos;
      console.log(newPos)
      if (newPos === "QB" && QBcounter < QBmax) {
        QBcounter += 1
        currPos = "QB" + String(QBcounter)
      } else if (newPos === "QB" && QBcounter >= QBmax) {
        QBcounter += 1
        BEcounter += 1
        currPos = "BE" + String(BEcounter)
      } else if (newPos === "RB" && RBcounter < RBmax) {
        RBcounter += 1
        currPos = "RB" + String(RBcounter)
      } else if (newPos === "RB" && RBcounter >= RBmax && FLcounter < FLmax) {
        RBcounter += 1
        FLcounter += 1
        currPos = "FL" + String(FLcounter)
      } else if (newPos === "RB" && RBcounter >= RBmax && FLcounter >= FLmax) {
        RBcounter += 1
        FLcounter += 1
        BEcounter +=1
        currPos = "BE" + String(BEcounter)
      } else if (newPos === "WR" && WRcounter < WRmax) {
        WRcounter += 1
        currPos = "WR" + String(WRcounter)
      } else if (newPos === "WR" && WRcounter >= WRmax && FLcounter < FLmax) {
        WRcounter += 1
        FLcounter += 1
        currPos = "FL" + String(FLcounter)
      } else if (newPos === "WR" && WRcounter >= WRmax && FLcounter >= FLmax) {
        WRcounter += 1
        FLcounter += 1
        BEcounter += 1
        currPos = "BE" + String(BEcounter)
      } else if (newPos === "TE" && TEcounter < TEmax) {
        TEcounter += 1
        currPos = "TE" + String(TEcounter)
      } else if (newPos === "TE" && TEcounter >= TEmax && FLcounter < FLmax) {
        TEcounter += 1
        FLcounter += 1
        currPos = "FL" + String(FLcounter)
      } else if (newPos === "TE" && TEcounter >= TEmax && FLcounter >= FLmax) {
        TEcounter += 1
        FLcounter += 1
        BEcounter += 1
        currPos = "BE" + String(BEcounter)
      } else if (newPos === "FL" && FLcounter < FLmax) {
        FLcounter += 1
        currPos = "FL" + String(FLcounter)
      } else if (newPos === "FL" && FLcounter >= FLmax) {
        FLcounter += 1
        BEcounter += 1
        currPos = "BE" + String(BEcounter)
      } else {
        BEcounter += 1
        currPos = "BE" + String(BEcounter)
      }
      currPosArray.push(currPos)
    });
    console.log(currPosArray)

    var labelOrder = [...this.state.expectedPositions]
    // const bench = currPosArray.filter(ele => ele.includes('BE')).length
    // for (var i = 0; i < bench; i++) {
    //   labelOrder.push('BE' + String(i + 1))
    // }
    const bench = labelOrder.filter(ele => ele.includes('BE'))
    const nonBench = labelOrder.length - bench.length
    for (var q = 0; q < nonBench; q++) {
      labelOrder.push('BE' + String(bench.length + q + 1))
    }
    var newPlayerList = this.state.playerList.sort(function(a, b) {
      return labelOrder.indexOf(a.currentPosition) - labelOrder.indexOf(b.currentPosition)
    })
    var k;
    for (k=0; k < newPlayerList.length; k++) {
      newPlayerList[k].currentPosition = currPosArray[k]
    }
    console.log(newPlayerList)

    const updatedName = document.getElementById('teamNameField').value
    const obj = {
      teamName: updatedName,
      playerList: newPlayerList,
    }
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/overwriteTeam/${this.state.teamID}`, obj)
      .catch(err => console.log(err))
    setTimeout(() => {
      if (!alert('Roster updated successfully!')) {
        window.location.reload();
      }
    }, 1000)
  }

  onNameEdit() {
    document.getElementById('teamNameField').removeAttribute('readonly')
    document.getElementById('teamNameButton').style.backgroundColor = "lightgrey"
  }

  displayTeamStats() {
    if (!this.state.mobile) {
      return <TeamStats team={this.state} clickEdit={() => this.onNameEdit()} clickSave={() => this.onTeamSave()} />;
    } else {
      return <MobileTeamStats team={this.state} clickEdit={() => this.onNameEdit()} clickSave={() => this.onTeamSave()} />;
    }
  }

  displayPlayerTableRows() {
    if (this.state.playerList) {
      var occupiedPositions = []
      var roster = this.state.playerList
      roster.map(function(player, k) {
        occupiedPositions.push(player.currentPosition)
      })
      const bench = occupiedPositions.filter(ele => ele.includes('BE'))
      var strippedPos = []
      for (var q = 0; q < this.state.expectedPositions.length; q++) {
        strippedPos.push(this.state.expectedPositions[q].slice(0, -1))
      }
      var fullPositionArray = [...this.state.expectedPositions]
      fullPositionArray = fullPositionArray.filter(ele => !ele.includes('BE'))
      if (bench.length !== 0) {
        for (var z = 0; z < bench.length; z++) {
          fullPositionArray.push('BE' + String(z + 1))
        }
      }
      if (!this.state.mobile) {
        return fullPositionArray.map(function(currPos, i) {
          if (occupiedPositions.includes(currPos)) {
            var idx = occupiedPositions.indexOf(currPos)
            return <PlayerTableRow strippedPos={strippedPos} player={roster[idx]} key={i} />;
          } else {
            return <EmptyTableRow missing={currPos} key={i} />;
          }
        })
      } else {
        return fullPositionArray.map(function(currPos, i) {
          if (occupiedPositions.includes(currPos)) {
            var idx = occupiedPositions.indexOf(currPos)
            return <MobilePlayerTableRow strippedPos={strippedPos} player={roster[idx]} key={i} />;
          } else {
            return <MobileEmptyTableRow missing={currPos} key={i} />;
          }
        })
      }
    } else {
      if (!this.state.mobile) {
        return this.state.expectedPositions.map(function(currPos, i) {
          return <EmptyTableRow missing={currPos} key={i} />;
        })
      } else {
        return this.state.expectedPositions.map(function(currPos, i) {
          return <MobileEmptyTableRow missing={currPos} key={i} />;
        })
      }
    }
  }

  async fetchTeam(obj) {
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/teams/editTeam/${obj.teamID}`)
      .then((response) => this.setState({
        teamID: response.data._id,
        leagueID: response.data.leagueID,
        userID: response.data.userID,
        teamName: response.data.teamName,
        playerList: response.data.playerList,
        expectedPositions: response.data.expectedPositions,
        wins: response.data.wins,
        losses: response.data.losses,
        pointsFor: response.data.pointsFor,
        pointsAgainst: response.data.pointsAgainst,
        place: response.data.place
      }))
      .catch(err => console.log(err))
  }

  reorderPlayerList() {
    var labelOrder = [...this.state.expectedPositions]
    const bench = labelOrder.filter(ele => ele.includes('BE'))
    const nonBench = labelOrder.length - bench.length
    for (var q = 0; q < nonBench; q++) {
      labelOrder.push('BE' + String(bench.length + q + 1))
    }
    var newPlayerList = this.state.playerList.sort(function(a, b) {
      return labelOrder.indexOf(a.currentPosition) - labelOrder.indexOf(b.currentPosition)
    })
    this.setState({playerList: newPlayerList})
  }

  render() {
    if (!this.state.mobile) {
      return (
        <div>
          {this.displayTeamStats()}
          <div className="container">
            <div className="playerContainer" style={{
              display: "table",
              width: "100%",
              align: "center"
            }}>
              <div className="playerRow" style={{
                display: "table-row"
              }}>
                <div style={{
                  display: "table-cell",
                  textAlign: "center",
                  padding: "4px",
                }}>
                  <div className="card">
                    <div className="card-content">
                      <table>
                        <thead>
                          <tr>
                            <th> </th>
                            <th>Name (Pos)</th>
                            <th>Num Games</th>
                            <th>Total Pts</th>
                            <th>Avg PPG</th>
                            <th>Under 10</th>
                            <th>Over 20</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayPlayerTableRows()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.displayTeamStats()}
          <div className="container">
            <div className="card">
              <div style={{
                marginLeft: "6px",
                marginRight: "6px"
              }}>
                <table>
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Name (Pos)</th>
                      <th>Avg PPG</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.displayPlayerTableRows()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

}
