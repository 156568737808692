import React, { Component } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";

import logo from "../../images/fantaseasons_white.png";
import "../../styles/Navbar.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUsers, faTrophy, faUser, faDoorOpen } from "@fortawesome/free-solid-svg-icons";


class Navbar extends Component {

  componentDidMount() {
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.sidenav');
      var instances = M.Sidenav.init(elems, {edge: "right"});
    });
  }

  render() {
    return (
      <div>
        <div className="navbar-fixed">
          <nav className="navbar-dark">
            <div className="nav-wrapper">
              <a className="navbar-brand" href="https://qwaggadev.com">
                <img style={{
                  paddingLeft: "8px",
                  paddingTop: "8px",
                  width: "60px",
                  height: "48px"}} src={logo} alt=""/>
              </a>
              <Link style={{
                verticalAlign: "top",
                fontSize: "150%"
              }} to="/" className="navbar-brand"><b>  Fantaseasons</b></Link>
              <ul className="right hide-on-med-and-down">
                <li className="navbar-item">
                <Link to="/dashboard" className="nav-link"><FontAwesomeIcon icon={faHome}/> Home</Link>
                </li>
                <li className="navbar-item">
                <Link to="/createLeague" className="nav-link"><FontAwesomeIcon icon={faUsers}/> Create League</Link>
                </li>
                <li className="navbar-item">
                <Link to="/enterLeagueCreds" className="nav-link"><FontAwesomeIcon icon={faDoorOpen}/> Join League</Link>
                </li>
                <li className="navbar-item">
                <Link to="/userProfile" className="nav-link"><FontAwesomeIcon icon={faUser}/> User Profile</Link>
                </li>
              </ul>
              <a href='/' data-target="mobileOptions" className="sidenav-trigger right"><i className="material-icons">menu</i></a>
            </div>
          </nav>
        </div>

        <div>
          <ul className="sidenav" id="mobileOptions">
            <li><a href="/dashboard"><FontAwesomeIcon icon={faHome}/> Home</a></li>
            <li><a href="/createLeague"><FontAwesomeIcon icon={faUsers}/> Create League</a></li>
            <li><a href="/enterLeagueCreds"><FontAwesomeIcon icon={faDoorOpen}/> Join League</a></li>
            <li><a href="/userProfile"><FontAwesomeIcon icon={faUser}/> User Profile</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Navbar;
