import React, { Component } from 'react';
import axios from 'axios';
import M from "materialize-css";

export default class InviteTeams extends Component {

  constructor() {
    super();

    this.state = {
      mobile: window.innerWidth < 700,
      leagueID: window.location.href.split('/').slice(-1)[0],
      league: [],
      selectedDate: "",
      selectedTime: "",
      selectedDateTime: Date.now()
    };

    this.fetchLeagueData = this.fetchLeagueData.bind(this)
    this.addEmailRows = this.addEmailRows.bind(this)
  }

  componentDidMount() {
    M.AutoInit();
    this.fetchLeagueData()
  }

  fetchLeagueData() {
    console.log("Fetching league data")
    console.log(this.state.leagueID)
    axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/fetchLeagues/${this.state.leagueID}`)
      .then((response) => this.setState({ league: response.data }))
      .then(() => this.addEmailRows(this.state.league.numberOfTeams))
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

  addEmailRows() {
    var i;
    var numTeams = Number(this.state.league.numberOfTeams);
    for (i=1; i < numTeams; i++) {
      var outerDiv = document.createElement('div')
      var inputField = document.createElement('input')
      inputField.id = "email_" + String(i)
      inputField.placeholder = "Team " + String(i) + " Email"
      outerDiv.appendChild(inputField)
      document.getElementById('emailForm').appendChild(outerDiv)
    }
    var submitButton = document.createElement('button')
    submitButton.style.width = "150px"
    submitButton.style.height = "50px"
    submitButton.style.borderRadius = "3px"
    submitButton.style.letterSpacing = "1.5px"
    submitButton.style.backgroundColor = "#0E1C36"
    submitButton.style.color = "white"
    submitButton.innerHTML = "SUBMIT"
    submitButton.addEventListener('click', function(event){
      if (
        !document.getElementById('datepicker').value ||
        !document.getElementById('timepicker').value
      ) {
        alert("Did not select date and time of draft")
      } else {
        var emailArray = []
        var emailDict = {}
        for (i=1; i < numTeams; i++) {
          emailArray.push(document.getElementById('email_'+String(i)).value)
          var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
          });
          emailDict[uuid] = document.getElementById('email_'+String(i)).value
        }
        const dateStr = document.getElementById('datepicker').value
        var timeStr = document.getElementById('timepicker').value

        const dateDict = {'Jan': '01',
          'Feb': '02',
          'Mar': '03',
          'Apr': '04',
          'May': '05',
          'Jun': '06',
          'Jul': '07',
          'Aug': '08',
          'Sep': '09',
          'Oct': '10',
          'Nov': '11',
          'Dec': '12'}
        var month = dateStr.split(' ')[0]
        var day = dateStr.split(' ')[1].replace(',', '')
        var year = dateStr.split(' ')[2]
        month = dateDict[month]
        const finalDate = `${year}-${month}-${day}`

        var tempHour = 0
        if (timeStr.includes('PM')) {
          timeStr = timeStr.split(' ')[0]
          var hour = Number(timeStr.split(':')[0])
          var min = Number(timeStr.split(':')[1])
          if (min < 10) {
            min = '0' + String(min)
          }
          if (hour !== 12) {
            hour = hour + 12
            tempHour = hour
          } else {
            tempHour = hour
          }
          timeStr = `${hour}:${timeStr.split(':')[1]}:00`
        } else {
          timeStr = timeStr.split(' ')[0]
          var hour = Number(timeStr.split(':')[0])
          var min = Number(timeStr.split(':')[1])
          if (min < 10) {
            min = '0' + String(min)
          }
          if (hour === 12) {
            hour = 0
            tempHour = hour
          }
          if (hour < 10) {
            tempHour = hour
            hour = '0' + String(hour)
          } else {
            tempHour = hour
          }
        }
        var tempDate = new Date()
        var a = tempDate.toLocaleString("ja", {timeZone: "America/New_York"}).split(/[/\s:]/);
        a[1]--;
        const t1 = Date.UTC.apply(null, a);
        const t2 = new Date(tempDate).setMilliseconds(0);
        var nyOffset = (t2 - t1) / 60 / 1000;
        const offsetCurr = tempDate.getTimezoneOffset()
        const tzOffset = (offsetCurr - nyOffset) / 60
        console.log(tzOffset)
        tempHour = Number(tempHour) + Number(tzOffset)
        const finalTime = `${tempHour}:${min}:00`
        console.log(finalTime)

        if (tempHour > 24) {
          tempHour = tempHour - 24
        }
        if (tempHour > 0 && tempHour < 4) {
          alert("Hours between 12:00 AM and 4:00 AM are reserved for maintenance")
        } else {
          var obj = {
            draftDate: dateStr,
            draftTime: finalTime,
            memberEmails: emailDict,
            active: true
          }
          axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/leagues/updateLeagueSettings/${window.location.href.split('/').slice(-1)[0]}`, obj)
            .then((response) => console.log(response))
          setTimeout(() => {
            window.open('/dashboard', '_self')
          }, 1000)
        }
      }
    });
    document.getElementById('cardContainer').appendChild(submitButton)
  }

  render() {
    if (!this.state.mobile) {
      return (
        <div>
          <div className="container col s10">
            <div className="card">
              <div className="card-content">
                <div className="topContainer" style={{
                  width: "100%",
                  display: "table",
                  align: "center",
                  verticalAlign: "middle"
                }}>
                  <div className="topTable" style={{
                    width: "100%",
                    display: "table-row",
                    verticalAlign: "middle"
                  }}>
                    <div className="emailCell" style={{
                      width: "50%",
                      display: "table-cell",
                      padding: "16px"
                    }}>
                      <p>Enter the emails of your leaguemates below</p>
                      <br />
                      <form id="emailForm">
                      </form>
                    </div>
                    <div className="datePickerCell" style={{
                      width: "50%",
                      display: "table-cell",
                      padding: "16px",
                      float: "center"
                    }}>
                      <p>Select a date and time for your league's draft</p>
                      <br />
                      <div style={{
                        width: "50%"
                      }}>
                        <input
                          type="text"
                          className="datepicker"
                          id="datepicker"
                          placeholder="Click to Select Date"
                        >
                        </input>
                      </div>
                      <div style={{
                        width: "50%"
                      }}>
                        <input
                          type="text"
                          className="timepicker"
                          id="timepicker"
                          placeholder="Click to Select Time"
                        >
                        </input>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div id="cardContainer" style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      );
    } else {
      return (
        <div>
          <div className="container col s10">
            <div className="card">
              <div className="card-content">
                <div>
                  <p>Enter the emails of your leaguemates below</p>
                  <br />
                  <form id="emailForm">
                  </form>
                </div>
                <br />
                <div className="datePickerCell" style={{
                  display: "table-cell",
                  padding: "16px",
                  float: "center"
                }}>
                  <p>Select a date and time for your league's draft</p>
                  <p>(Note: All times are in EST)</p>
                  <br />
                  <div style={{
                    width: "100%"
                  }}>
                    <input
                      type="text"
                      className="datepicker"
                      id="datepicker"
                      placeholder="Click to Select Date"
                    >
                    </input>
                  </div>
                  <div style={{
                    width: "100%"
                  }}>
                    <input
                      type="text"
                      className="timepicker"
                      id="timepicker"
                      placeholder="Click to Select Time"
                    >
                    </input>
                  </div>
                </div>
              </div>
              <br />
              <div id="cardContainer" style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}>
              </div>
              <br />
            </div>
          </div>
        </div>
      );
    }
  }

}
