import React, { Component } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';

const PlayerRow = props => (
  <tr>
    <td onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name}</b> ({props.player.Position})</td>
    <td>{props.player.Num_Games}</td>
    <td>{props.player.Total_Pts.toFixed(2)}</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>{props.player.Under_10}</td>
    <td>{props.player.Over_20}</td>
    <td>
      <Button
        disabled={!props.draftCompleted}
        className="add-player-button" style={{
          marginRight: 10,
          backgroundColor: "#0E1C36"
        }}
        onClick={() => props.addFunc(props)}
      >
        ADD
      </Button>
    </td>
  </tr>
)

const MobilePlayerRow = props => (
  <tr>
    <td onClick={() => window.open(`/player/${props.player.Stub}:${props.startYear}:${props.endYear}`, '_blank')}><b>{props.player.Name.split(' ')[0][0] + '. ' + props.player.Name.split(' ')[1]}</b> ({props.player.Position})</td>
    <td>{props.player.Avg_Pts.toFixed(2)}</td>
    <td>{props.player.StDev_Pts.toFixed(2)}</td>
    <td>
      <Button
        disabled={!props.draftCompleted}
        className="add-player-button" style={{
          marginRight: 10,
          backgroundColor: "#0E1C36"
        }}
        onClick={() => props.addFunc(props)}
      >
        ADD
      </Button>
    </td>
  </tr>
)


export default class AddPlayer extends Component {

  constructor() {
    super();
    this.state = {
      mobile: window.innerWidth < 700,
      userID: localStorage.userID,
      leagueID: window.location.href.split('/').slice(-1)[0].split(':')[0],
      startYear: 1970,
      endYear: 2020,
      teamID: window.location.href.split('/').slice(-1)[0].split(':')[1],
      boardID: "",
      draftBoard: [],
      nameFilter: '',
      positionsToShow: ['?'],
      draftCompleted: false
    }

  }

  componentDidMount() {
    this.loadDraftBoard()
  }

  async loadDraftBoard() {
    console.log("Loading Draft Board")
    await axios.post(`${process.env.REACT_APP_SERVER_LOCATION}/api/drafts/fetchDraftBoard/${this.state.leagueID}`)
      .then((response) => this.setState({
        boardID: response.data._id,
        startYear: response.data.startYear,
        endYear: response.data.endYear,
        draftBoard: response.data.draftBoard,
        draftCompleted: response.data.draftCompleted
      }))
      .catch((err) => console.log(err))
  }

  displayDraftBoardRows() {
    if (!this.state.mobile) {
      const filteredPlayers = this.filterRemainingPlayers(this.state.positionsToShow)
      var rowArray = []
      for (const [k, val] of Object.entries(filteredPlayers)) {
        rowArray.push(<PlayerRow player={val}
          addFunc={(val) => this.addPlayer(val)}
          startYear={this.state.startYear}
          endYear={this.state.endYear}
          draftCompleted={this.state.draftCompleted}
          key={k} delKey={k}/>);
      }
      return rowArray
    } else {
      const filteredPlayers = this.filterRemainingPlayers(this.state.positionsToShow)
      var rowArray = []
      for (const [k, val] of Object.entries(filteredPlayers)) {
        rowArray.push(<MobilePlayerRow player={val}
          addFunc={(val) => this.addPlayer(val)}
          startYear={this.state.startYear}
          endYear={this.state.endYear}
          draftCompleted={this.state.draftCompleted}
          key={k} delKey={k}/>);
      }
      return rowArray
    }
  }

  addPlayer(props) {
    window.open(`/dropPlayer/${this.state.leagueID}:${this.state.teamID}:${props.delKey}`, '_self')
  }

  render() {
    const { positionsToShow } = this.state;
    const filterPositions = ['?', 'QB', 'RB', 'WR', 'TE']

    if (!this.state.mobile) {
      if (!this.state.draftBoard) {
        return <div />
      } else {
        return (
          <div>
            <div className="draftBoardCell" style={{
              marginLeft: "10%",
              marginRight: "10%",
              padding: "4px"
            }}>
              <div className="card">
                <div className="card-content">
                  <div style={{
                    width: "100%",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}>
                    <h5><b>Waiver Wire</b></h5>
                    <InputGroup>
                      <FormControl
                        placeholder="Search by Player Name"
                        id="nameSearch"
                        value={this.state.nameFilter}
                        onChange={(event) => this.handleTextChange(event)}
                      />
                    </InputGroup>

                    <div style={{
                      display: "inline-flex",
                      padding: "16px"
                    }}>
                      {filterPositions.map(p => (
                        <button
                          key={p}
                          className={positionsToShow.indexOf(p) > -1 ? 'Active' : ''}
                          onClick={() => this.setPositionFilter(p)}>
                          {p === '?' ? 'All' : p}
                        </button>
                      ))}
                    </div>
                  </div>
                  <table className="table highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Name (Position)</th>
                        <th>Num Games</th>
                        <th>Total Pts</th>
                        <th>Average PPG</th>
                        <th>StDev PPG</th>
                        <th>Under 10 Pts</th>
                        <th>Over 20 Pts</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.displayDraftBoardRows()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (!this.state.draftBoard) {
        return <div />
      } else {
        return (
          <div>
            <div className="draftBoardCell" style={{
              marginLeft: "5%",
              marginRight: "5%",
              padding: "4px"
            }}>
              <div className="card">
                <div className="card-content" style={{
                  padding: "8px"
                }}>
                  <header>
                    <h5><b>Waiver Wire</b></h5>
                    <InputGroup>
                      <FormControl
                        placeholder="Search by Player Name"
                        id="nameSearch"
                        value={this.state.nameFilter}
                        onChange={(event) => this.handleTextChange(event)}
                      />
                    </InputGroup>

                    <div style={{
                      display: "inline-flex",
                      padding: "16px"
                    }}>
                      {filterPositions.map(p => (
                        <button
                          key={p}
                          className={positionsToShow.indexOf(p) > -1 ? 'Active' : ''}
                          onClick={() => this.setPositionFilter(p)}>
                          {p === '?' ? 'All' : p}
                        </button>
                      ))}
                    </div>
                  </header>
                  <table className="table highlight" style={{ marginTop: 20 }}>
                    <thead>
                      <tr>
                        <th>Name (Position)</th>
                        <th>Avg PPG</th>
                        <th>StDev PPG</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.displayDraftBoardRows()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  handleTextChange(event) {
    this.setState({
      nameFilter: event.target.value
    })
  }

  setPositionFilter(position) {
    if (position === '?') {
      this.setState({ positionsToShow: ['?'] });
    } else {
      this.setState({
        positionsToShow: [String(position)]
      });
    }
  };

  filterRemainingPlayers(positionsToShow, nameFilter) {
    var filteredPlayers = {}
    var finalFilteredPlayers = {}
    const nameFilterLower = this.state.nameFilter.toLowerCase();

    if (positionsToShow.length === 1 && positionsToShow[0] === '?') {
      filteredPlayers = this.state.draftBoard
    } else {
      for (const [key, val] of Object.entries(this.state.draftBoard)) {
        if (val.Position === positionsToShow[0]) {
          filteredPlayers[key] = val
        }
      }
    };

    if (nameFilterLower) {
      for (const [key, val] of Object.entries(filteredPlayers)) {
        const firstName = val.Name.split(' ')[0].toLowerCase()
        const lastName = val.Name.split(' ')[1].toLowerCase()
        if (firstName.startsWith(nameFilterLower)) {
          finalFilteredPlayers[key] = val
        } else if (lastName.startsWith(nameFilterLower)) {
          finalFilteredPlayers[key] = val
        }
      }
    } else {
      finalFilteredPlayers = filteredPlayers
    }

    return finalFilteredPlayers
  }

}
